/* eslint-disable @typescript-eslint/ban-ts-comment */
import { App } from "antd";
import { useEffect, useState } from "react";
import { Card, Spin, Row, Col, Input, Button, List, Table } from "antd";
import { filterXSS } from "xss";
import {
  getAllDocuments,
  getMyOrgInfo,
  UpdateWorkflow,
} from "../../../services/api";
import type { Workflow } from "../../../types";
import { useParams } from "react-router-dom";
import { getWorkflows } from "services/projects";

const icons = [
  "https://skc-mobileicons.s3.amazonaws.com/001-engineering.png",
  "https://skc-mobileicons.s3.amazonaws.com/002-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/003-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/004-conveyor.png",
  "https://skc-mobileicons.s3.amazonaws.com/005-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/006-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/007-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/008-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/009-fire extinguisher.png",
  "https://skc-mobileicons.s3.amazonaws.com/010-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/011-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/012-conveyor.png",
  "https://skc-mobileicons.s3.amazonaws.com/013-conveyor.png",
  "https://skc-mobileicons.s3.amazonaws.com/014-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/015-conveyor.png",
  "https://skc-mobileicons.s3.amazonaws.com/016-crane.png",
  "https://skc-mobileicons.s3.amazonaws.com/017-forklift.png",
  "https://skc-mobileicons.s3.amazonaws.com/018-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/019-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/020-conveyor.png",
  "https://skc-mobileicons.s3.amazonaws.com/021-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/022-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/023-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/024-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/025-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/026-conveyor.png",
  "https://skc-mobileicons.s3.amazonaws.com/027-crane.png",
  "https://skc-mobileicons.s3.amazonaws.com/028-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/029-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/030-humanpictos.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_base_171954.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_blue_folder_171943.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_calculator_171951.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_calendar_171968.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_canary_171980.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_desktop_171961.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_documents_171983.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_favorites_171988.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_illustrator_171959.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_messenger_171937.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_messenger2_171976.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_messenger3_171975.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_msn_171953.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_music_171984.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_network_171974.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_notepad_171977.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_opera_171989.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_paint_171936.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_photoshop_171981.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_pictures_171956.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_tasks_171993.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_videos_171992.png",
  "https://skc-mobileicons.s3.amazonaws.com/iconfinder_yellow_folder_171944.png",
  "https://skc-mobileicons.s3.amazonaws.com/arrow-connection.png",
  "https://skc-mobileicons.s3.amazonaws.com/checked-box.png",
  "https://skc-mobileicons.s3.amazonaws.com/circles-arrow.png",
  "https://skc-mobileicons.s3.amazonaws.com/circular-arrows.png",
  "https://skc-mobileicons.s3.amazonaws.com/cogwheel-arrow.png",
  "https://skc-mobileicons.s3.amazonaws.com/connection.png",
  "https://skc-mobileicons.s3.amazonaws.com/file.png",
  "https://skc-mobileicons.s3.amazonaws.com/geometrical-arrows.png",
  "https://skc-mobileicons.s3.amazonaws.com/idea.png",
  "https://skc-mobileicons.s3.amazonaws.com/network-1.png",
  "https://skc-mobileicons.s3.amazonaws.com/network.png",
  "https://skc-mobileicons.s3.amazonaws.com/settings-checked.png",
  "https://skc-mobileicons.s3.amazonaws.com/settings.png",
  "https://skc-mobileicons.s3.amazonaws.com/squares.png",
  "https://skc-mobileicons.s3.amazonaws.com/statistics.png",
  "https://skc-mobileicons.s3.amazonaws.com/stopper.png",
  "https://skc-mobileicons.s3.amazonaws.com/warning-flag.png",
  "https://skc-mobileicons.s3.amazonaws.com/work-cycle.png",
  "https://skc-mobileicons.s3.amazonaws.com/worker-checked.png",
  "https://skc-mobileicons.s3.amazonaws.com/worker-triangle-square.png",
];

const DisplayTab = (): JSX.Element => {
  const [isLoading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedRBAC, setSelectedRBAC] = useState<any[]>([]);
  const [rolesData, setRolesData] = useState<any[]>([]);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [workflow, setWorkflow] = useState<Workflow>();
  const [valueeee, setValue] = useState("");
  const [workflowValues, setWorkflowValues] = useState<any>();
  const { workflowId, id } = useParams<{ id: string; workflowId: string }>();
  const { message } = App.useApp();

  useEffect(() => {
    const getData = async () => {
      try {
        let apiResponse = await getWorkflows({ projectID: id as string });
        const resWorkFlow = apiResponse?.result.docs;
        setWorkflows(
          (resWorkFlow || []).filter(
            (p: { projectId: string }) => p.projectId === id
          )
        );

        const currentWorkflow =
          (resWorkFlow || []).find(
            (p: { id: string }) => p.id === workflowId
          ) || {};
        const currentWorkflowValue = JSON.parse(currentWorkflow?.value);
        currentWorkflow.iconSrc = currentWorkflowValue.iconSrc || icons[0];
        const workflowValue = JSON.parse(currentWorkflow.value);
        setValue(workflowValue.name);
        setWorkflow(currentWorkflow);
        setWorkflowValues(JSON.parse(currentWorkflow.value));
        apiResponse = await getAllDocuments({ collName: "roles" });
        const resRoles = apiResponse?.docs;
        const dataOrg = await getMyOrgInfo();
        let sOrg: string[] = [];
        let sRole: string[] = [];
        let resRolesData = (resRoles || []).map((role: any) => {
          if (dataOrg.legalName && !sOrg.includes(dataOrg.legalName)) {
            sOrg.push(dataOrg.legalName);
          }
          if (role.roleName && !sRole.includes(role.roleName)) {
            sRole.push(role.roleName);
          }
          return `${dataOrg.legalName}:${role.roleName}`;
        });

        sOrg = sOrg.map((s) => `${s}:*`);
        sRole = sRole.map((s) => `*:${s}`);
        resRolesData = ["*:*"].concat(sOrg).concat(sRole).concat(resRolesData);
        setRolesData(resRolesData);
        const rules =
          (currentWorkflowValue && currentWorkflowValue?.rbac) || [];
        if (rules && rules.length) {
          setSelectedRBAC(rules[0].roles || []);
        }
        setLoading(false);
      } catch (er) {
        message.error("error in fetch workflow");
        setLoading(false);
      }
    };

    getData();
  }, [id, workflowId]);

  const onSelectMulti = (data: any[]) => {
    // @ts-ignore
    setSelectedRBAC(
      data.map((s: string | number | any) => rolesData[s]).filter((s: any) => s)
    );
  };

  const isNameExisted = (name: any, id: any) => {
    if (id) {
      return (
        workflows &&
        workflows.find((p: Workflow) => {
          const valueObj = JSON.parse(p.value);
          return valueObj.name === name && p.id !== id;
        })
      );
    }
    return (
      workflows &&
      workflows.find((p: Workflow) => {
        const valueObj = JSON.parse(p.value);
        return valueObj.name === name;
      })
    );
  };

  const updateWorkflow = async () => {
    try {
      setButtonLoading(true);
      if (workflow) {
        const valueObj = JSON.parse(workflow.value);
        valueObj.name = valueeee;
        if (!valueObj?.name) {
          return message.error("Please enter workflow name!");
        }

        if (isNameExisted(workflow?.name, valueObj?.workflowId)) {
          return message.error(`${valueObj?.name} already exists.`);
        }
        const payload = {
          ...valueObj,
        };
        payload.rbac = [
          {
            resource: "workflows",
            action: "view",
            roles: selectedRBAC.filter((s: any) => s),
          },
        ];
        payload.iconSrc = workflow?.iconSrc;
        await UpdateWorkflow({
          id: workflowId as string,
          value: JSON.stringify(payload),
          projectID: id as string,
        });
        setButtonLoading(false);
        setTimeout(() => {
          window.location.reload();
          message.success("workflow updated successfully!");
        }, 1000);
      }
    } catch (modalError) {
      message.error("Error in saving workflow!");
      setButtonLoading(false);
    }
    return true;
  };

  const onSelectIcon = (iconSrc: string) => {
    setWorkflow({
      ...(workflow || ({} as Workflow)),
      iconSrc,
    });
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
    setWorkflow({
      ...(workflow || ({} as Workflow)),
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedRBAC.map((s: any) =>
      rolesData.findIndex((p: any) => p === s)
    ),
    onChange: onSelectMulti,
  };

  const columns = [
    {
      key: "key",
      width: "30%",
      render: (text: any, record: { name: string }) => {
        const items = record.name.split(":");
        return items[0] === "*" ? "All" : filterXSS(items[0]);
      },
    },
    {
      key: "name",
      render: (text: any, record: { name: string }) => {
        const items = record.name.split(":");
        return items[1] === "*" ? "All" : filterXSS(items[1]);
      },
    },
  ];
  return (
    <Card
      bordered={false}
      className="author"
      style={{ marginTop: 0 }}
      activeTabKey="about"
    >
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row>
          <Col md={24}>
            <Row gutter={24}>
              <Col md={10} xs={24} className="track_sml_row">
                <Row style={{ marginBottom: 10 }}>
                  <Col md={5} style={{ textAlign: "center" }}>
                    Icon
                  </Col>
                  <Col md={12}>
                    {
                      <img
                        alt=""
                        src={(workflow && workflow?.iconSrc) || ""}
                        width={70}
                        height={70}
                      />
                    }
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col md={8} style={{ textAlign: "center" }}>
                    Workflow Name
                  </Col>
                  <Col md={12}>
                    <Input
                      placeholder="Workflow Name"
                      name="name"
                      defaultValue={
                        (workflowValues && workflowValues.name) || ""
                      }
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12} xs={24} className="track_large_row">
                <Card>
                  <List
                    style={{
                      maxHeight: 350,
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 4,
                      lg: 4,
                      xl: 6,
                      xxl: 4,
                    }}
                    dataSource={icons}
                    renderItem={(item) => (
                      <List.Item
                        style={{ cursor: "pointer" }}
                        onClick={() => onSelectIcon(item)}
                      >
                        <img
                          src={item}
                          width={60}
                          height={60}
                          alt="render item"
                        />
                      </List.Item>
                    )}
                  />
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 15 }}>
              <Col md={24} sm={24} xs={24}>
                <Table
                  size="small"
                  bordered
                  scroll={{ x: 360 }}
                  showHeader={false}
                  rowSelection={rowSelection}
                  dataSource={(rolesData || []).map((r: any, i: any) => ({
                    name: r,
                    key: i,
                  }))}
                  columns={columns}
                  pagination={false}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col md={22}>
                <Button loading={buttonLoading} onClick={updateWorkflow}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default (): JSX.Element => (
  <App>
    <DisplayTab />
  </App>
);
