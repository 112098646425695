import Workflows from "pages/track/workflows";
import { RouteProps } from "react-router-dom";
import GeneratePopcode from "./pages/track/popcodes/generate";
import Projects from "./pages/track/projects";
import ProjectDetail from "pages/track/projects/project-detail";
import WorkflowDetail from "pages/track/workflows/workflow-detail";
import Popcodes from "pages/track/popcodes/index";
import PopcodeDetail from "pages/track/popcodes/popcode-detail";
import RecentActivity from "pages/dashboard/recent-activity";
import Login from "pages/login";
import Forgot from "pages/forgot";
import ResetPassword from "pages/reset-password";
import UserVerify from "pages/verify";
import ListRoles from "pages/account/roles/list-role";
import ListUsers from "pages/account/users/ListUser";
import WalletHome from "pages/account/wallet";
import New from "pages/account/wallet/new";
import AccessWallet from "pages/account/wallet/access";
import Done from "pages/account/wallet/done";
import Dashboard from "pages/dashboard";

type authRouteConfig = RouteProps & {
  breadcrumbName: string[];
  name: string;
  description?: string;
};

export const authRoutes: authRouteConfig[] = [
  {
    path: "/",
    name: "",
    description: "This is home page",
    breadcrumbName: [],
    id: "home-bradcrumb-page",
    element: <Dashboard />,
  },
  {
    path: "/projects",
    name: "Projects",
    description: "This is Projects List page.",
    breadcrumbName: ["Track", "Projects"],
    id: "project-bradcrumb-page",
    element: <Projects />,
  },
  {
    path: "/popcodes",
    name: "Popcodes",
    id: "Popcodes-breadcrumb-page",
    breadcrumbName: ["Track", "Popcodes"],
    element: <Popcodes />,
  },
  {
    path: "/recent-activity",
    element: <RecentActivity />,
    id: "home-breadcrumb-page",
    breadcrumbName: ["Track", "Home"],
    name: "Home",
  },
  {
    path: "/account/wallet",
    element: <WalletHome />,
    breadcrumbName: ["Wallet"],
    id: "wallet--breadcrumb-page",
    name: "Wallet",
  },
  {
    path: "/account/wallet/new",
    element: <New />,
    breadcrumbName: ["New-Wallet"],
    id: "newWallet-breadcrumb-page",
    name: "Wallet",
  },
  {
    path: "/account/wallet/access",
    breadcrumbName: ["Access"],
    element: <AccessWallet />,
    id: "accessWallet-breadcrumb-page",
    name: "Access Wallet",
  },
  {
    path: "/account/wallet/done",
    breadcrumbName: ["Done"],
    element: <Done />,
    id: "DoneWallet-breadcrumb-page",
    name: "Done Wallet",
  },
  {
    path: "/account/roles",
    breadcrumbName: ["Roles"],
    element: <ListRoles />,
    id: "roles-breadcrumb-page",
    name: "Roles",
  },
  {
    path: "/account/users",
    breadcrumbName: ["Users"],
    element: <ListUsers />,
    id: "users-breadcrumb-page",
    name: "Users",
  },
  {
    path: "/print-code",
    name: "Generate Popcodes",
    id: "GeneratePopcodes-breadcrumb-page",
    breadcrumbName: ["Track", "Generate Pre-Printed Popcodes"],
    description: "This is Generate Popcodes page.",
    element: <GeneratePopcode />,
  },
  {
    path: "/projects/:id/workflows",
    name: "About",
    id: "WorkflowAbout-breadcrumb-page",
    breadcrumbName: ["Track", "Workflow", "About"],
    description: "This is Workflow Detail page.",
    element: <Workflows />,
  },
  {
    path: "/projects/:id/workflows/:workflowId",
    name: "About",
    id: "WorkflowId-breadcrumb-page",
    breadcrumbName: ["Track", "Workflows", "About"],
    description: "This is Workflow Detail page.",
    element: <WorkflowDetail />,
  },
  {
    path: "/projects/:id/about",
    name: "About",
    id: "About-breadcrumb-page",
    breadcrumbName: ["Track", "Projects", "About"],
    description: "This is Project Detail page.",
    element: <ProjectDetail />,
  },
  {
    path: "/popcodes/popcode/:address",
    id: "PopcodeDetail-breadcrumb-page",
    name: "Popcode Detail",
    breadcrumbName: ["Track", "Popcodes", "Popcode"],
    description: "This is Popcode Detail page.",
    element: <PopcodeDetail />,
  },
];
type RouteConfig = RouteProps & {
  path: string;
};

export const unAuthRoutes: RouteConfig[] = [
  {
    path: "/verify/:id",
    element: <UserVerify />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgot",
    element: <Forgot />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
];
