/* eslint-disable @typescript-eslint/ban-ts-comment */
import RcTooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

import "./tool-tip.css";
// eslint-disable-next-line
const Tooltip = (props: any): JSX.Element => {
  return (
    <RcTooltip
      overlay={props.contents}
      placement={props.placement}
      trigger={props.triggerOn}
      overlayClassName="skc-tooltip"
    >
      {/* @ts-ignore */}
      {this.props.children}
    </RcTooltip>
  );
};

const TooltipOptions = {
  placement: {
    RIGHT_TOP: "rightTop",
  },

  triggerOn: {
    CLICK: "click",
    HOVER: "hover",
  },
};

export { Tooltip, TooltipOptions };
