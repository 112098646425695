// import { message, notification } from "antd";
import AxiosInstance from "./axiosinstance";
import { getAuthorizationHeader } from "../utils/utils";

type InitChainAccountArgs = {
  address: string;
  publicKey: string;
  extendedPublicKey: string;
  save: boolean;
  device_type: string;
};

export const initChainAccount = async ({
  address,
  publicKey,
  extendedPublicKey,
  save = true,
}: InitChainAccountArgs): Promise<void> => {
  await AxiosInstance.post(
    "/chain/account/init",
    {
      address,
      publicKey,
      extendedPublicKey,
      save,
      device_type: "track-desktop",
    },
    {
      headers: getAuthorizationHeader(),
    }
  );
};
