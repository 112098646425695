import React, { useEffect, useState } from "react";
import { fetchRecentActivity } from "../../services/popcodes";
import { truncateAddress } from "../../utils/utils";
import moment from "moment";
import { PageContainer } from "@ant-design/pro-layout";
import { Card, Spin } from "antd";
import { Link, useLocation } from "react-router-dom";
import useDocumentTitle from "../../hooks/use-document-title";
import "./recent-activity.css";

const RecentActivity: React.FC = () => {
  const location = useLocation();
  if (location.pathname === "/portal") {
    //redirect to org portal
    const host = window.location.protocol + "//" + window.location.host + "/";
    window.location.href = host;
  }
  const [events, setEvents] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  useDocumentTitle("Home");

  const fetchEvents = async () => {
    setIsFetching(true);
    const results = await fetchRecentActivity();
    if (results) {
      setEvents((prevState) => [...prevState, ...results]);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (!isFetching) return;
    fetchEvents();
    // eslint-disable-next-line
  }, [isFetching]);

  if (isFetching) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin />
      </div>
    );
  }

  const renderTitle = (e: any) => {
    if (e?.status === "minted" && e) {
      return (
        <p style={{ margin: 0, fontWeight: "lighter" }}>
          Popcode{" "}
          <Link to={`/popcodes/popcode/${e?.address}`}>
            {truncateAddress(e.address)}
          </Link>{" "}
          minted
        </p>
      );
    }
    if (e?.status === "tagged" && e) {
      return (
        <p style={{ margin: 0, fontWeight: "lighter" }}>
          Popcode{" "}
          <Link to={`/popcodes/popcode/${e?.address}`}>
            {truncateAddress(e.address)}
          </Link>{" "}
          tagged
        </p>
      );
    }
    if (e?.status === "transferred" && e) {
      return (
        <p style={{ margin: 0, fontWeight: "lighter" }}>
          Transferred {`${e?.amountTransferred}`} From Popcode{" "}
          <Link to={`/popcodes/popcode/${e?.transferFrom}`}>
            {truncateAddress(e?.transferFrom)}
          </Link>{" "}
          to Popcode{" "}
          <Link to={`/popcodes/popcode/${e?.transferTo}`}>
            {truncateAddress(e?.transferTo)}
          </Link>
        </p>
      );
    }
    return "";
  };

  const renderAsset = (e: any) => {
    if (e?.status === "minted" && e?.asset) {
      return (
        <>
          <span>{`Asset: ${e?.asset?.Name}`}</span>{" "}
          <span style={{ marginLeft: "40px" }}>{`Amount: ${Number(
            e?.asset?.Amount
          )} ${e?.asset?.UnitOfMeasure}`}</span>
        </>
      );
    }
    if (e?.status === "transferred" && e?.asset) {
      return (
        <>
          <span>{`Asset: ${e?.asset?.Name}`}</span>{" "}
          <span style={{ marginLeft: "40px" }}>{`Amount: ${Number(
            e?.asset.Amount
          )} ${e?.asset?.UnitOfMeasure}`}</span>
        </>
      );
    }
  };

  const renderWorkflow = (e: any) => {
    return `Workflow: ${e?.workflowName || "--"}`;
  };

  const renderCurrentStatus = (e: any) => {
    return `Current Staus: ${e?.currentStatus || "--"}`;
  };

  const getDiff = (e: any) => {
    const now = moment();
    const days = now.diff(e.timestamp, "days");
    if (days > 1) return `${days} days ago`;
    const hours = now.diff(e?.timestamp, "hours");
    if (hours > 1) return `${hours} hours ago`;
    const minutes = now.diff(e?.timestamp, "minutes");
    return `${minutes} minutes ago`;
  };

  return (
    <PageContainer header={{ title: "Recent Activities" }}>
      {events.map((el: any) => (
        <Card
          key={el.id}
          title={renderTitle(el)}
          extra={<span>{getDiff(el)}</span>}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          <p>{renderAsset(el)}</p>
          {el?.status === "transferred" && <p>{renderCurrentStatus(el)}</p>}
          {el?.status !== "transferred" && <p>{renderWorkflow(el)}</p>}
        </Card>
      ))}
    </PageContainer>
  );
};
export default RecentActivity;
