import React from "react";
import { Form, Input, Modal } from "antd";
// import type { SelectProps } from "antd";

interface DataType {
  key: string;
  id: string;
  roleName: string;
  // accessControl: string[];
}

interface RoleCreateFormProps {
  open: boolean;
  onCreate: (values: DataType) => void;
  onCancel: () => void;
}

// const accessControls: string[] = ["create", "update", "read", "delete"];

// const options: SelectProps["options"] = [];

// for (let i = 0; i < accessControls.length; i++) {
//   options.push({
//     label: accessControls[i],
//     value: accessControls[i],
//   });
// }

const RoleCreateForm: React.FC<RoleCreateFormProps> = ({
  open,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      open={open}
      title="Create Role"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      maskClosable={false}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // console.log(values.roleName)
            if (values.roleName.toLowerCase() == "dlpc signatory") {
              values.roleName = "DLPC Signatory";
            } else {
              values.roleName = values.roleName
                .split(" ")
                .map(
                  (l: string) => l[0].toUpperCase() + l.substr(1).toLowerCase()
                )
                .join(" ");
            }
            onCreate(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
        className="user-form"
      >
        <Form.Item
          name="roleName"
          label="Role Name"
          rules={[{ required: true, message: "Please Enter role name!" }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="accessControl"
          label="Access Control"
          rules={[
            { required: true, message: "Please select the access control!" },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={options}
          />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default RoleCreateForm;
