/* eslint-disable @typescript-eslint/ban-ts-comment */
import { App } from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Spin,
  Button,
  Input,
  Card,
  Select,
  List,
  Table,
  Tabs,
  Checkbox,
  Modal,
  Popconfirm,
  AutoComplete,
} from "antd";
import {
  UpdateTracksteps,
  getAllDocuments,
  getMyOrgInfo,
} from "../../../services/api";
import uniqueId from "../../../utils/unique-id";
import { filterXSS } from "xss";
import "./track-screens.css";
import {
  EditOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  DragOutlined,
} from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddParamModal from "./components/add-param-modal";
import { useParams } from "react-router-dom";
import { getTrackSteps } from "services/projects";

const { TextArea } = Input;
const Option = Select.Option;

const typeItems = [
  "string",
  "boolean",
  "dropdown",
  "picture",
  "number",
  "autocomplete",
  "attachment",
];
const actionType = [
  "navigate",
  "ec3:mintPopcode",
  "ec3:updatePopcode",
  "ec3:transfer",
  "executeSmartClause",
  "runWorkflowStep",
];

const defaultTemplate = {
  selectedFormFieldIndex: "",
  defaultProperties: ["name", "label", "type", "required", "editable", "value"],
  formFieldText: "",
  excludeField: ["name", "isEdit", "tempName"],
  excludeAction: ["action", "isEdit", "tempAction"],
  fields: [],
  actions: [],
  selectedFormActionFieldIndex: "",
  defaultActionProperties: ["action", "title", "button"],
};

const paramsSort = [
  "step",
  "screen",
  "sourceStep",
  "destStep",
  "popcodeType",
  "ruleName",
];
const ordering = {};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const sortParams = (items: any[]) => {
  // @ts-ignore
  for (let i = 0; i < paramsSort.length; i++) ordering[paramsSort[i]] = i;
  // @ts-ignore
  items.sort((a: any, b: any) => ordering[a] - ordering[b]);
  return items;
};
const getNodeTypeFromScreenAction = (screenData: any, workflowId: any) => {
  const nodeTypeData: any[] = [];
  Object.keys(screenData).forEach((p) => {
    const actions = screenData[p].actions || [];
    actions.forEach((as: any) => {
      (as.operations || []).forEach((s: { name: any; params: any }) => {
        const actionName = s.name;
        const params = s.params;
        if (actionName === "ec3:mintPopcode") {
          const popcodeTypeName = params.popcodeType;
          if (popcodeTypeName) {
            nodeTypeData.push({ popcodeTypeName, workflowId });
          }
        } else if (actionName === "ec3:transfer") {
          const popcodeTypeName = params.popcodeType;
          if (popcodeTypeName) {
            nodeTypeData.push({ popcodeTypeName, workflowId });
          }
        }
      });
    });
  });
  return _.uniqBy(
    nodeTypeData,
    (p: { popcodeTypeName: any }) => p.popcodeTypeName
  );
};

const TrackScreens = (): JSX.Element => {
  const [isLoading, setLoading] = useState(true);
  const { message } = App.useApp();
  const [state, setState] = useState<any>({
    stepsData: {},
    buttonLoading: false,
    screenList: [],
    popcodeTypes: [],
    type: "FormScreen",
    screenRow: null,
    formScreenTemplate: defaultTemplate,
    actionParams: {},
    defaultScreen: "",
  });

  const [isVisibleFormActionFieldModal, setIsVisibleFormActionFieldModal] =
    useState(false);
  const [formActionEditIndex, setFormActionEditIndex] = useState<any>();
  const [isNewOperation, setIsNewOperation] = useState(false);
  const [formAction, setFormAction] = useState<Record<string, any>>({});
  const [isVisibleFormFieldModal, setIsVisibleFormFieldModal] = useState(false);
  const [formFieldEditIndex, setFormFieldEditIndex] = useState<any>(-1);
  const [formFieldEditItem, setFormFieldEditItem] = useState("");

  const { id, workflowId } = useParams<{
    id: string;
    workflowId: string;
  }>();

  const onSelectedScreenRow = (row: any) => {
    const { stepsData, formScreenTemplate = {} } = state;

    const screens =
      (stepsData && stepsData.track && stepsData.track.screens) || {};
    const currentScreen = (screens && screens[row]) || {};
    const {
      fields = [],
      actions = [],
      text,
      isMultiple = false,
    } = currentScreen || {};
    const type = (currentScreen && currentScreen.type) || "FormScreen";
    const selectedRules = (currentScreen && currentScreen.rbac) || [];
    const stateConfig: any = {
      type,
      existingSelectedRowType: type,
      screenRow: row,
      selectedRBAC: [],
    };

    if (selectedRules && selectedRules.length) {
      stateConfig.selectedRBAC = selectedRules[0].roles || [];
    }

    if (type === "FormScreen") {
      stateConfig.formScreenTemplate = {
        ...formScreenTemplate,
        fields,
        actions,
        selectedFormActionFieldIndex: actions.length ? 0 : "",
        selectedFormFieldIndex: fields.length ? 0 : "",
      };
    } else if (type === "DescScreen") {
      stateConfig.description = text;
      stateConfig.formScreenTemplate = {
        ...formScreenTemplate,
        fields,
        actions,
        selectedFormActionFieldIndex: actions.length ? 0 : "",
        selectedFormFieldIndex: fields.length ? 0 : "",
      };
    } else if (type === "ScanScreen") {
      stateConfig.isMultiple = isMultiple;
      stateConfig.formScreenTemplate = {
        ...formScreenTemplate,
        fields,
        actions,
        selectedFormActionFieldIndex: actions.length ? 0 : "",
        selectedFormFieldIndex: fields.length ? 0 : "",
      };
    } else if (type === "TransferScreen") {
      stateConfig.formScreenTemplate = {
        ...formScreenTemplate,
        fields,
        actions,
        selectedFormActionFieldIndex: actions.length ? 0 : "",
        selectedFormFieldIndex: fields.length ? 0 : "",
      };
    } else if (type === "TransferScreenV2") {
      stateConfig.formScreenTemplate = {
        ...formScreenTemplate,
        fields,
        actions,
        selectedFormActionFieldIndex: actions.length ? 0 : "",
        selectedFormFieldIndex: fields.length ? 0 : "",
      };
    } else if (type === "TransferBOMScreen") {
      stateConfig.formScreenTemplate = {
        ...formScreenTemplate,
        fields,
        actions,
        selectedFormActionFieldIndex: actions.length ? 0 : "",
        selectedFormFieldIndex: fields.length ? 0 : "",
      };
    }
    stateConfig.existingTemplate = _.cloneDeep(stateConfig.formScreenTemplate);
    setState({
      ...state,
      ...stateConfig,
    });
  };

  useEffect(() => {
    const getData = async () => {
      try {
        let config: any = {};
        const [trackStep] = await Promise.all([
          getTrackSteps({ workflowID: workflowId as string }),
        ]);
        const trackStepResult = trackStep?.docs.find(
          (s: any) => s.workflowID.id === workflowId
        );
        const stepsData = JSON.parse(trackStepResult.value);
        const steps = stepsData.track.steps;
        const dataOrg = await getMyOrgInfo();
        config = {
          ...stepsData,
          ...dataOrg,
          // ec3: systemData.ec3,
        };
        const resRoles = await getAllDocuments({ collName: "roles" });
        // config.popcodeTypes = popcodeTypes;
        config.stepsData = stepsData;
        let sOrg: any[] = [];
        let sRole: any[] = [];
        let rolesData = (resRoles.docs || []).map((role: any) => {
          if (dataOrg.legalName && !sOrg.includes(dataOrg.legalName)) {
            sOrg.push(dataOrg.legalName);
          }
          if (role.roleName && !sRole.includes(role.roleName)) {
            sRole.push(role.roleName);
          }
          return `${dataOrg.legalName}:${role.roleName}`;
        });
        sOrg = sOrg.map((s) => `${s}:*`);
        sRole = sRole.map((s) => `*:${s}`);
        rolesData = ["*:*"].concat(sOrg).concat(sRole).concat(rolesData);

        let screensList: any[] = [];
        (steps || []).forEach((p: { screen: any }) => {
          const screenItems = p.screen && { screen: p.screen };
          screensList = screensList.concat(screenItems);
        });
        screensList = _.uniqBy(
          screensList.filter((a) => a),
          "screen"
        );
        const screensListsWithKey = screensList.map(
          (val: any, index: number) => ({
            ...val,
            key: index,
          })
        );
        config.screenList = screensListsWithKey;
        let spotList: any[] = [];
        (steps || []).forEach((p: { spot: any }) => {
          const spotItems = p.spot && { spot: p.spot };
          spotList = spotList.concat(spotItems);
        });
        spotList = _.uniqBy(
          spotList.filter((a) => a),
          "spot"
        );
        const spotsListsWithKey = spotList.map((val: any, index: number) => ({
          ...val,
          key: index,
        }));
        config.spotList = spotsListsWithKey;
        if (screensList.length) {
          config.screenRow = screensList[0].screen;
        }

        config.rolesData = rolesData;
        const firstScr = config.track.steps?.[0];
        let fields: any[] = [];
        let actions: any[] = [];
        let selectedFormActionFieldIndex: any = "";
        let isMultiple = false;
        let type = "";
        let selectedRBAC: any[] = [];
        if (firstScr) {
          actions = config.track.screens?.[firstScr.screen]?.actions || [];
          fields = config.track.screens?.[firstScr.screen]?.fields || [];
          //selectedRBAC.map((s: string | number) => rolesData[s]).filter((s) => s);
          selectedRBAC =
            (config.track.screens?.[firstScr.screen]?.rbac || [])[0]?.roles ||
            [];
          selectedFormActionFieldIndex = 0;
          isMultiple = config.track.screens?.[firstScr.screen]?.isMultiple;
          type = config.track.screens?.[firstScr.screen]?.type;
        }
        setState({
          ...state,
          formScreenTemplate: {
            ...state.formScreenTemplate,
            fields,
            actions,
            selectedFormActionFieldIndex,
          },
          selectedRBAC,
          isMultiple,
          type,
          ...config,
        });
      } catch (errors) {
        setState({
          ...state,
        });
        setLoading(false);
      }
    };

    getData()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [workflowId]);

  const onChangeType = (type: any) => {
    const { existingSelectedRowType, existingTemplate } = state;
    const screenData: any = {};

    if (existingSelectedRowType === "FormScreen") {
      screenData.description = "";
      screenData.formScreenTemplate = _.cloneDeep(defaultTemplate);
      screenData.isMultiple = false;
    } else if (existingSelectedRowType === "DescScreen") {
      screenData.formScreenTemplate = _.cloneDeep(defaultTemplate);
      screenData.isMultiple = false;
    } else if (existingSelectedRowType === "ScanScreen") {
      screenData.description = "";
      screenData.formScreenTemplate = _.cloneDeep(defaultTemplate);
    } else if (existingSelectedRowType === "TransferScreen") {
      screenData.isMultiple = false;
      screenData.description = "";
      screenData.formScreenTemplate = _.cloneDeep(defaultTemplate);
    } else if (existingSelectedRowType === "TransferScreenV2") {
      screenData.isMultiple = false;
      screenData.description = "";
      screenData.formScreenTemplate = _.cloneDeep(defaultTemplate);
    } else if (existingSelectedRowType === "TransferBOMScreen") {
      screenData.isMultiple = false;
      screenData.description = "";
      screenData.formScreenTemplate = _.cloneDeep(defaultTemplate);
    }

    if (type === existingSelectedRowType) {
      screenData.formScreenTemplate = _.cloneDeep(existingTemplate);
    }

    setState({
      ...state,
      type,
      ...screenData,
    });
  };

  const handleCheckBoxEditPropertyChange = (e: any) => {
    const { name, checked } = e.target;
    setState({
      ...state,
      [name]: checked ? "y" : "n",
    });
  };

  const handleChange = (e: any) => {
    const { name, checked } = e.target;
    let { value } = e.target;
    if (name === "isMultiple") {
      value = checked;
    }

    setState({
      ...state,
      [name]: value,
    });
  };

  const formActionHandleChange = (e: any, isAction: any, fIndex: any) => {
    const { name, value } = e.target;
    if (isAction) {
      const { operations } = _.cloneDeep(formAction);

      if (operations.some((p: { name: any }) => p.name === value)) {
        return message.error("This Items Already Exists.");
      }

      operations[fIndex][name] = value;
      setFormAction({
        ...formAction,
        operations,
      });
    } else {
      setFormAction({
        ...formAction,
        [name]: value,
      });
    }
  };

  const formActionParamHandleChange = (e: any) => {
    const { actionParam } = state;
    const { name, value } = e.target;

    setState({
      ...state,
      actionParam: {
        ...actionParam,
        [name]: value,
      },
    });
  };

  const onSelectMulti = (selectedRBAC: any) => {
    const { rolesData = [] } = state;

    setState({
      ...state,
      selectedRBAC: selectedRBAC
        .map((s: string | number) => rolesData[s])
        .filter((s: any) => s),
    });
  };

  const onAddScreen = async () => {
    try {
      const {
        stepsData,
        screenRow,
        type,
        description,
        isMultiple,
        selectedRBAC = [],
        popcodeTypes,
        formScreenTemplate = {},
      } = state;
      let fields = formScreenTemplate.fields;
      if (screenRow && fields.length < 1) {
        const screens =
          (stepsData && stepsData.track && stepsData.track.screens) || {};

        const currentScreen = (screens && screens[screenRow]) || {};
        fields = currentScreen.fields || [];
      }
      const actions = formScreenTemplate.actions || [];

      const screenData: any = {
        name: screenRow,
        type,
      };
      const stateConfig = {};
      if (type === "FormScreen") {
        screenData.fields = _.cloneDeep(fields || []);
        screenData.actions = _.cloneDeep(actions || []);
      } else if (type === "DescScreen") {
        const desc = description;
        screenData.text = filterXSS(desc);
        screenData.actions = _.cloneDeep(actions || []);
      } else if (type === "ScanScreen") {
        screenData.isMultiple = isMultiple;
        screenData.actions = _.cloneDeep(actions || []);
      } else if (type === "TransferScreen") {
        screenData.fields = _.cloneDeep(fields || []);
        screenData.actions = _.cloneDeep(actions || []);
      } else if (type === "TransferScreenV2") {
        screenData.fields = _.cloneDeep(fields || []);
        screenData.actions = _.cloneDeep(actions || []);
      } else if (type === "TransferBOMScreen") {
        screenData.fields = _.cloneDeep(fields || []);
        screenData.actions = _.cloneDeep(actions || []);
      }
      setState({ ...state, buttonLoading: true, ...stateConfig });
      const trackStep = await getTrackSteps({
        workflowID: workflowId as string,
      });
      const trackStepResult = trackStep?.docs.find(
        (s: any) => s.workflowID.id === workflowId
      );
      const track_value = JSON.parse(trackStepResult.value) || {};
      const steps =
        (track_value && track_value.track && track_value.track.steps) || [];
      const screens =
        (track_value && track_value.track && track_value.track.screens) || [];
      screenData.rbac = [
        {
          resource: screenRow,
          action: "view",
          roles: selectedRBAC.filter((s: any) => s),
        },
      ];

      const trackData = {
        screens: {
          ...(screens || {}),
          [screenRow]: screenData,
        },
        steps,
      };

      const allScreenNodeTypes = getNodeTypeFromScreenAction(
        trackData.screens,
        workflowId
      );
      const popcodeNodeTypeNames = allScreenNodeTypes.map(
        (p: { popcodeTypeName: any }) => p.popcodeTypeName
      );
      const needToSaveNodeDoc: any[] = [];
      popcodeNodeTypeNames.forEach((p: any) => {
        const findDoc = popcodeTypes.find(
          (pt: any) => pt.popcodeTypeName === p
        );
        if (findDoc) {
          if (!findDoc.workflowIds.includes(workflowId)) {
            findDoc.workflowIds.push(workflowId);
            needToSaveNodeDoc.push(findDoc);
          }
        } else {
          needToSaveNodeDoc.push({
            id: uniqueId(),
            popcodeTypeName: p,
            workflowIds: [workflowId],
          });
        }
      });
      const filterPocodeWithWorkflowIds = popcodeTypes.filter(
        (s: { workflowIds: string | any[] }) =>
          s.workflowIds.includes(workflowId || "")
      );
      filterPocodeWithWorkflowIds.forEach(
        (p: { popcodeTypeName: string; workflowIds: any[] }) => {
          if (!popcodeNodeTypeNames.includes(p.popcodeTypeName)) {
            const latestWorkFlowIds = p.workflowIds.filter(
              (ss: any) => ss !== workflowId
            );
            p.workflowIds = latestWorkFlowIds;
            p.popcodeTypeName = filterXSS(p.popcodeTypeName);
            needToSaveNodeDoc.push(p);
          }
        }
      );
      stepsData.track = trackData;

      const payload = {
        version: "5.0",
        Id: trackStepResult && trackStepResult?.id,
        projectId: id,
        workflowId,
        track: trackData,
      };
      console.log("payload =>", payload);
      await UpdateTracksteps({
        id: payload?.Id,
        value: JSON.stringify(payload),
        workflowID: workflowId as string,
      });
      // await saveDocument({ collName, payload });
      setState({
        ...state,
        existingSelectedRowType: type,
        buttonLoading: false,
        stepsData,
      });
      message.success("update successfully");
    } catch (errors) {
      message.error("Error in save screens!");
      setState({
        ...state,
        buttonLoading: false,
      });
    }
  };

  const onAddNewFormField = () => {
    const { formScreenTemplate = {}, screenRow } = state;
    const { fields, formFieldText, defaultProperties } = formScreenTemplate;
    const isExisted = (fName: any) => fields.find((s: any) => s.name === fName);
    if (!formFieldText) {
      return message.error("Please enter field Name");
    }
    if (isExisted(filterXSS(formFieldText))) {
      return message.error("This Item already exists!");
    }
    const fieldConfig: any = {};
    defaultProperties.forEach((s: any) => {
      fieldConfig[s] = "";
    });
    fieldConfig.name = filterXSS(formFieldText);
    fields.push(fieldConfig);
    setState({
      ...state,
      formScreenTemplate: {
        ...formScreenTemplate,
        fields,
        formFieldText: "",
        isNewField: false,
      },
      stepsData: {
        ...state.stepsData,
        track: {
          ...state.stepsData.track,
          screens: {
            ...state.stepsData.track.screens,
            [screenRow]: {
              ...(state.stepsData.track.screens?.[screenRow] || {}),
              fields,
            },
          },
        },
      },
    });
  };

  const onDeleteFormField = (fIndex: any) => {
    const { formScreenTemplate = {} } = state;
    const { fields } = formScreenTemplate;
    fields.splice(fIndex, 1);
    setState({
      ...state,
      isVisibleFormFieldModal: false,
      formScreenTemplate: {
        ...formScreenTemplate,
        fields,
      },
    });
  };

  const onEditFormField = (formFieldEditItm: any, formFieldEditIdx: any) => {
    setFormFieldEditItem(formFieldEditItm);
    setFormFieldEditIndex(formFieldEditIdx);
    setIsVisibleFormFieldModal(true);
  };

  const onEditSaveFormField = () => {
    const { formScreenTemplate = {}, screenRow } = state;
    const { fields } = formScreenTemplate;
    fields[formFieldEditIndex].name = filterXSS(formFieldEditItem);

    setIsVisibleFormFieldModal(false);
    setFormFieldEditItem("");
    setFormFieldEditIndex(-1);
    setState({
      ...state,
      formScreenTemplate: {
        ...formScreenTemplate,
        fields,
      },
      stepsData: {
        ...state.stepsData,
        track: {
          ...state.stepsData.track,
          screens: {
            ...state.stepsData.track.screens,
            [screenRow]: {
              ...(state.stepsData.track.screens?.[screenRow] || {}),
              fields,
            },
          },
        },
      },
    });
  };

  const onEditSaveFormProperty = () => {
    const {
      formScreenTemplate = {},
      formEditPropertyKey,
      formEditPropertyValue,
    } = state;
    const { fields, selectedFormFieldIndex } = formScreenTemplate;
    fields[selectedFormFieldIndex][formEditPropertyKey] = filterXSS(
      formEditPropertyValue
    );
    setState({
      ...state,
      isVisibleFormPropertyModal: false,
      formScreenTemplate: {
        ...formScreenTemplate,
        fields,
      },
    });
  };

  const onFormFieldChange = (e: any, isEditIndex: any) => {
    const { formScreenTemplate = {} } = state;
    const { fields = [] } = formScreenTemplate;
    const { name, value } = e.target;
    const config = { [name]: value };
    if (isEditIndex > -1) {
      fields[isEditIndex].name = value;
      setState({
        ...state,
        formScreenTemplate: {
          ...formScreenTemplate,
          ...fields,
        },
      });
    } else {
      setState({
        ...state,
        formScreenTemplate: {
          ...formScreenTemplate,
          ...config,
        },
      });
    }
  };

  const onFormPropertyChange = (e: any, propertyKey: any) => {
    const { formScreenTemplate = {} } = state;
    const { fields, selectedFormFieldIndex } = formScreenTemplate;
    const { name, value } = e.target;
    const config = { [name]: value };
    if (propertyKey) {
      fields[selectedFormFieldIndex][propertyKey] = value;
      setState({
        ...state,
        formScreenTemplate: {
          ...formScreenTemplate,
          ...fields,
        },
      });
    } else {
      setState({
        ...state,
        formScreenTemplate: {
          ...formScreenTemplate,
          ...config,
        },
      });
    }
  };

  const onSubmitFormProperty = () => {
    const { formScreenTemplate = {} } = state;
    const {
      selectedFormFieldIndex,
      fields,
      formPropertyName,
      formPropertyValue,
    } = formScreenTemplate;
    if (!formPropertyName) {
      return message.error("Please enter propertyName.");
    }

    if (
      Object.keys(fields[selectedFormFieldIndex]).includes(formPropertyName)
    ) {
      return message.error("This property already exists.");
    }
    fields[selectedFormFieldIndex][formPropertyName] = formPropertyValue;

    setState({
      ...state,
      formScreenTemplate: {
        ...formScreenTemplate,
        ...fields,
        formPropertyName: "",
        formPropertyValue: "",
        isNewFieldForProperty: false,
      },
    });
  };

  const onCancelFormProperty = () => {
    const { formScreenTemplate = {} } = state;
    formScreenTemplate.isNewFieldForProperty = false;
    setState({
      ...state,
      formScreenTemplate,
    });
  };

  const onFormFieldSelected = (fName: any, fIndex: any) => {
    const { formScreenTemplate = {} } = state;
    formScreenTemplate.selectedFormFieldIndex = fIndex;
    setState({
      ...state,
      formScreenTemplate: {
        ...formScreenTemplate,
      },
    });
  };

  const onDeleteFormProperty = (pName: any) => {
    const { formScreenTemplate = {} } = state;
    const { fields, selectedFormFieldIndex } = formScreenTemplate;
    delete fields[selectedFormFieldIndex][pName];
    setState({
      ...state,
      isVisibleFormPropertyModal: false,
      formScreenTemplate: {
        ...formScreenTemplate,
        fields,
      },
    });
  };

  const onFormPlusAction = () => {
    const { formScreenTemplate = {} } = state;
    formScreenTemplate.isNewField = true;
    setState({
      ...state,
      formScreenTemplate,
    });
  };

  const onCancelFormField = () => {
    const { formScreenTemplate = {} } = state;
    formScreenTemplate.isNewField = false;
    setState({
      ...state,
      formScreenTemplate,
    });
  };

  const onFormPlusForProperty = () => {
    const { formScreenTemplate = {} } = state;
    formScreenTemplate.isNewFieldForProperty = true;
    setState({
      ...state,
      formScreenTemplate,
    });
  };

  const hideShowModal = (modalType: any, action: any) => {
    setState({
      ...state,
      [modalType]: action,
    });
  };

  const onEditFormProperty = (
    formEditPropertyKey: any,
    formEditPropertyValue: any
  ) => {
    setState({
      ...state,
      formEditPropertyKey: filterXSS(formEditPropertyKey),
      formEditPropertyValue: filterXSS(formEditPropertyValue),
      isVisibleFormPropertyModal: true,
    });
  };

  const onDragEnd = (docs: any) => {
    // dropped outside the list
    if (!docs.destination) {
      return;
    }

    const { formScreenTemplate } = state;

    const { fields } = state.formScreenTemplate || {};

    const items = reorder(fields, docs.source.index, docs.destination.index);

    setState({
      ...state,
      formScreenTemplate: {
        ...formScreenTemplate,
        fields: items,
      },
    });
  };

  const renderFormFields = () => {
    const {
      isVisibleFormPropertyModal,
      screenRow,

      formEditPropertyKey,
      formEditPropertyValue,
    } = state;
    const {
      selectedFormFieldIndex,
      formFieldText,
      formPropertyName,
      formPropertyValue,
      excludeField,
      isNewField,
      isNewFieldForProperty,
    } = state.formScreenTemplate || {};
    const rows: any[] = [];
    let fields = state.formScreenTemplate.fields;

    if (screenRow && fields.length < 1) {
      const stepsData = state.stepsData;
      const screens =
        (stepsData && stepsData.track && stepsData.track.screens) || {};
      const currentScreen = (screens && screens[screenRow]) || {};
      fields = currentScreen.fields || [];
    }

    if (fields && fields.length) {
      const selectedItem = fields[selectedFormFieldIndex];
      Object.keys(selectedItem || {})
        .map((p, i) => {
          if (!excludeField.includes(p))
            return rows.push({ name: p, value: selectedItem[p], key: i });
          return [];
        })
        .filter((p) => p);
    }
    const columns = [
      {
        title: "Property Name",
        dataIndex: "name",
        key: "key-property-name",
        width: "30%",
        render: (text: any) => filterXSS(text),
      },
      {
        title: "Property Value",
        dataIndex: "value",
        key: "key-property-value",
        width: "65%",
        render: (text: any) => filterXSS(text),
      },
      {
        title: "Action",
        key: "key-property-Action",
        render: (text: any, record: any) => (
          <EditOutlined
            style={{
              cursor: "pointer",
              fontSize: 18,
              color: "#1890ff",
              marginRight: 10,
            }}
            onClick={() => onEditFormProperty(record.name, record.value)}
            type="edit"
          />
        ),
      },
    ];
    return (
      <Row gutter={24} className="track_screen_sub">
        <Col md={10} style={{ marginBottom: 24 }}>
          <Row>
            <Col md={24}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided: any) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <List size="small" bordered>
                        {fields.map((item: any, fieldIndex: any) => {
                          const styles: any = { cursor: "pointer" };
                          if (fieldIndex === selectedFormFieldIndex) {
                            styles.border = "1px solid #1890ff";
                          }
                          const { name } = item;
                          return (
                            <Draggable
                              key={item.name}
                              draggableId={item.name}
                              index={fieldIndex}
                            >
                              {(providedD: any) => (
                                <div
                                  ref={providedD.innerRef}
                                  {...providedD.draggableProps}
                                  {...providedD.dragHandleProps}
                                >
                                  <List.Item
                                    key={name}
                                    style={{
                                      ...styles,
                                      padding: "4px 4px 4px 10px",
                                    }}
                                    onClick={() =>
                                      onFormFieldSelected(name, fieldIndex)
                                    }
                                  >
                                    {/* <List.Item.Meta title={<span>{filterXSS(name)}</span>} /> */}
                                    {filterXSS(name)}
                                    <DragOutlined
                                      style={{
                                        cursor: "pointer",
                                        fontSize: 18,
                                        color: "#1890ff",
                                        marginRight: 5,
                                        float: "right",
                                      }}
                                    />
                                    <EditOutlined
                                      style={{
                                        cursor: "pointer",
                                        fontSize: 18,
                                        color: "#1890ff",
                                        marginRight: 10,
                                        float: "right",
                                      }}
                                      onClick={() =>
                                        onEditFormField(name, fieldIndex)
                                      }
                                      type="edit"
                                    />
                                  </List.Item>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      </List>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>
          </Row>
          {!isNewField && (
            <Row style={{ marginTop: 10 }}>
              <Col md={22} />
              <Col md={2}>
                <PlusCircleOutlined
                  style={{
                    cursor: "pointer",
                    fontSize: 24,
                    color: "#1890ff",
                    marginRight: 10,
                  }}
                  type="plus-circle"
                  onClick={onFormPlusAction}
                />
              </Col>
            </Row>
          )}
          {isNewField && (
            <Row style={{ marginTop: 10 }} className="plus_field">
              <Col md={14}>
                <Input
                  placeholder="Field Name"
                  size="small"
                  name="formFieldText"
                  style={{ width: "98%" }}
                  value={formFieldText || ""}
                  // @ts-ignore
                  onChange={onFormFieldChange}
                />
              </Col>
              <Col md={4}>
                <Button
                  size="small"
                  style={{ marginBottom: 5 }}
                  onClick={onAddNewFormField}
                >
                  Submit
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  size="small"
                  style={{ marginBottom: 5 }}
                  color="primary"
                  onClick={onCancelFormField}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
        </Col>
        <Col md={14} style={{ marginBottom: 24 }}>
          <Row>
            <Col md={24}>
              <Table
                key={"Table-elements-fields"}
                size="small"
                bordered
                dataSource={rows || []}
                columns={columns}
                pagination={false}
              />
            </Col>
          </Row>
          {!isNewFieldForProperty && (
            <Row style={{ marginTop: 10 }}>
              <Col md={22} />
              <Col md={2}>
                <PlusCircleOutlined
                  style={{
                    cursor: "pointer",
                    fontSize: 24,
                    color: "#1890ff",
                    marginRight: 10,
                  }}
                  type="plus-circle"
                  onClick={onFormPlusForProperty}
                />
              </Col>
            </Row>
          )}
          {isNewFieldForProperty && (
            <Row style={{ marginTop: 10 }} className="plus_field2">
              <Col md={8} style={{ marginRight: 5 }}>
                <Input
                  placeholder="Property Name"
                  value={formPropertyName}
                  // @ts-ignore
                  onChange={onFormPropertyChange}
                  name="formPropertyName"
                />
              </Col>
              <Col md={8} style={{ marginRight: 5 }}>
                <Input
                  value={formPropertyValue}
                  placeholder="Property Value"
                  // @ts-ignore
                  onChange={onFormPropertyChange}
                  name="formPropertyValue"
                />
              </Col>
              <Col md={3} style={{ marginRight: 5 }}>
                <Button
                  style={{ marginBottom: 5 }}
                  disabled={!(selectedFormFieldIndex > -1)}
                  color="primary"
                  onClick={onSubmitFormProperty}
                >
                  Submit
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  style={{ marginBottom: 5 }}
                  disabled={!(selectedFormFieldIndex > -1)}
                  color="primary"
                  onClick={onCancelFormProperty}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
        </Col>
        {isVisibleFormFieldModal && (
          <Modal
            title="Update Field"
            open={isVisibleFormFieldModal}
            onOk={onEditSaveFormField}
            onCancel={() => setIsVisibleFormFieldModal(false)}
            footer={[
              <Button
                key="back"
                onClick={() => setIsVisibleFormFieldModal(false)}
              >
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={onEditSaveFormField}>
                Submit
              </Button>,
              <Popconfirm
                key="confirm-submit"
                placement="topLeft"
                title="Are you sure delete this Field?"
                onConfirm={() => onDeleteFormField(formFieldEditIndex)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ float: "left" }} color="danger">
                  Delete
                </Button>
              </Popconfirm>,
            ]}
          >
            <Row className="update_modal">
              <Col xs={5} style={{ marginBottom: 10 }}>
                Name
              </Col>
              <Col xs={12} style={{ marginBottom: 10 }}>
                <Input
                  name="formFieldEditItem"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormFieldEditItem(e.target.value)
                  }
                  value={formFieldEditItem}
                  placeholder="Field Name"
                />
              </Col>
            </Row>
          </Modal>
        )}
        {isVisibleFormPropertyModal && (
          <Modal
            title="Update Property"
            className="screen_update_p"
            open={isVisibleFormPropertyModal}
            onOk={onEditSaveFormProperty}
            onCancel={() => hideShowModal("isVisibleFormPropertyModal", false)}
            footer={[
              <Popconfirm
                key="delete-confirm"
                placement="topLeft"
                title="Are you sure delete this Property?"
                onConfirm={() => onDeleteFormProperty(formEditPropertyKey)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ float: "left" }} color="danger">
                  Delete
                </Button>
              </Popconfirm>,
              <Button
                key="back"
                onClick={() =>
                  hideShowModal("isVisibleFormPropertyModal", false)
                }
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={onEditSaveFormProperty}
              >
                Submit
              </Button>,
            ]}
          >
            <Row>
              <Col md={5} style={{ marginBottom: 10 }}>
                {!["required", "editable"].includes(formEditPropertyKey)
                  ? formEditPropertyKey
                  : formEditPropertyKey === "editable"
                  ? "Editable?"
                  : "Required?"}
              </Col>
              <Col md={12} style={{ marginBottom: 10 }}>
                {formEditPropertyKey === "type" && (
                  <Select
                    defaultValue={formEditPropertyValue}
                    value={formEditPropertyValue}
                    style={{ width: 200 }}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "formEditPropertyValue", value: e },
                      })
                    }
                  >
                    {typeItems.map((s) => (
                      <Option key={s} value={s}>
                        {s}
                      </Option>
                    ))}
                  </Select>
                )}
                {(formEditPropertyKey === "editable" ||
                  formEditPropertyKey === "required") && (
                  <Checkbox
                    checked={formEditPropertyValue === "y"}
                    name="formEditPropertyValue"
                    onChange={handleCheckBoxEditPropertyChange}
                  />
                )}
                {!["type", "required", "editable"].includes(
                  formEditPropertyKey
                ) && (
                  <Input
                    name="formEditPropertyValue"
                    onChange={handleChange}
                    value={formEditPropertyValue}
                    placeholder="Property Value"
                  />
                )}
              </Col>
            </Row>
          </Modal>
        )}
      </Row>
    );
  };

  const onAddNewFormActionField = () => {
    const { formScreenTemplate = {} } = state;
    const { actions, formActionFieldText } = formScreenTemplate;
    const isExisted = (fName: any) =>
      actions.find((s: { buttonTitle: any }) => s.buttonTitle === fName);
    if (!formActionFieldText) {
      return message.error("Please enter Button Name");
    }
    if (isExisted(filterXSS(formActionFieldText))) {
      return message.error("This Item already exists!");
    }
    const fieldConfig = {
      buttonTitle: filterXSS(formActionFieldText),
      operations: [],
    };
    actions.push(fieldConfig);
    setState({
      ...state,
      formScreenTemplate: {
        ...formScreenTemplate,
        actions,
        formActionFieldText: "",
        isNewFieldAction: false,
      },
    });
  };

  const onDeleteFormActionField = (fIndex: any) => {
    const { formScreenTemplate = {} } = state;
    const { actions } = formScreenTemplate;
    actions.splice(fIndex, 1);
    setState({
      ...state,
      isVisibleFormActionFieldModal: false,
      formScreenTemplate: {
        ...formScreenTemplate,
        actions,
      },
    });
  };

  const onEditFormActionField = (item: any, formActionEditIdx: any) => {
    setFormActionEditIndex(formActionEditIdx);
    setFormAction({ ...item });
    setIsVisibleFormActionFieldModal(true);
  };

  const onEditFormActionParam = (actionName: any, params: any = {}) => {
    const config: any = {
      actionName,
      params: {},
    };
    if (actionName === "ec3:mintPopcode") {
      config.stepName = params.step;
      config.popcodeType = filterXSS(params.popcodeType);
    } else if (actionName === "navigate") {
      config.screenName = params.screen;
    } else if (actionName === "ec3:updatePopcode") {
      config.stepName = params.step;
    } else if (actionName === "ec3:transfer") {
      config.sourceStep = params.sourceStep;
      config.destStep = params.destStep;
      config.popcodeType = filterXSS(params.popcodeType);
    } else if (actionName === "executeSmartClause") {
      config.ruleName = filterXSS(params.ruleName);
    }
    setState({
      ...state,
      actionParam: config,
      isVisibleFormActionParamModal: true,
    });
  };

  const onEditSaveFormActionField = () => {
    const { formScreenTemplate = {} } = state;

    if (formAction && formAction.operations && formAction.operations.length) {
      if (formAction.operations.some((p: any) => !p.name)) {
        return message.error("Please select action type.");
      }
    }

    const uniqAction = _.uniqBy(formAction.operations, "name");
    if (uniqAction.length !== formAction.operations.length) {
      return message.error("Please select action type.");
    }
    const { actions } = formScreenTemplate;

    formAction.buttonTitle = filterXSS(formAction.buttonTitle);
    actions[formActionEditIndex] = formAction;
    setState({
      ...state,
      formScreenTemplate: {
        ...formScreenTemplate,
        actions,
      },
    });
    setIsVisibleFormActionFieldModal(false);
  };

  const onEditSaveFormActionParam = (actionParam: Record<string, any>) => {
    const { formScreenTemplate = {} } = state;
    const { actions, selectedFormActionFieldIndex } = formScreenTemplate;
    const editActionItem = actions[selectedFormActionFieldIndex];

    const cloneOperations = [...editActionItem.operations];

    const operation: Record<string, any> = {};

    if (actionParam.actionName === "ec3:mintPopcode") {
      const step = actionParam.stepName;
      const popcodeType = filterXSS(actionParam.popcodeType);
      operation.params = {
        step,
        popcodeType,
      };
    } else if (actionParam.actionName === "navigate") {
      const screen = actionParam.screenName;
      operation.params = {
        screen,
      };
    } else if (actionParam.actionName === "ec3:updatePopcode") {
      const step = actionParam.stepName;
      operation.params = {
        step,
      };
    } else if (actionParam.actionName === "ec3:transfer") {
      const sourceStep = actionParam.sourceStep;
      const destStep = actionParam.destStep;
      const popcodeType = filterXSS(actionParam.popcodeType);
      operation.params = {
        sourceStep,
        destStep,
        popcodeType,
      };
    } else if (actionParam.actionName === "executeSmartClause") {
      const ruleName = filterXSS(actionParam.ruleName);
      operation.params = {
        ruleName,
      };
    }

    (editActionItem.operations || []).forEach((s: any, index: number) => {
      if (s.name === actionParam.actionName) {
        cloneOperations[index].params = operation.params;
      }
    });

    if (
      !(editActionItem.operations || []).find(
        (s: any) => s.name === actionParam.actionName
      )
    ) {
      cloneOperations.push({
        name: actionParam.actionName,
        params: operation.params,
      });
    }

    actions[selectedFormActionFieldIndex] = {
      ...editActionItem,
      operations: cloneOperations,
    };
    setState({
      ...state,
      isVisibleFormActionParamModal: false,
      formScreenTemplate: {
        ...formScreenTemplate,
        actions,
        // actions: [
        //   ...actions,
        //   [selectedFormActionFieldIndex]: {
        //     ...editActionItem,
        //     operations: cloneOperations,
        //   }
        // ],
      },
    });
  };

  const onFormActionFieldChange = (e: any, isEditIndex: any) => {
    const { formScreenTemplate = {} } = state;
    const { actions = [] } = formScreenTemplate;
    const { name, value } = e.target;
    const config = { [name]: value };
    if (isEditIndex > -1) {
      actions[isEditIndex].action = value;
      setState({
        ...state,
        formScreenTemplate: {
          ...formScreenTemplate,
          ...actions,
        },
      });
    } else {
      setState({
        ...state,
        formScreenTemplate: {
          ...formScreenTemplate,
          ...config,
        },
      });
    }
  };

  const onFormActionFieldSelected = (fName: any, fIndex: any) => {
    const { formScreenTemplate = {} } = state;
    formScreenTemplate.selectedFormActionFieldIndex = fIndex;
    setState({
      ...state,
      formScreenTemplate: {
        ...formScreenTemplate,
      },
    });
  };

  const onFormPlusActionForAction = () => {
    const { formScreenTemplate = {} } = state;
    formScreenTemplate.isNewFieldAction = true;
    setState({
      ...state,
      formActionFieldText: "",
      formScreenTemplate,
    });
  };

  const onCancelFormFieldForProperty = () => {
    const { formScreenTemplate = {} } = state;
    formScreenTemplate.isNewFieldAction = false;
    setState({
      ...state,
      formActionFieldText: "",
      formScreenTemplate,
    });
  };

  const onFormPlusActionFromModal = () => {
    const { operations = [] } = _.cloneDeep(formAction);
    operations.push({});
    setFormAction({
      ...formAction,
      operations,
    });
  };

  const onFormDeleteActionFromModal = (dIndex: any) => {
    const { operations = [] } = formAction;
    operations.splice(dIndex, 1);
    if (operations.length === 0) {
      operations.push({});
    }

    setFormAction({
      ...formAction,
      operations,
    });
  };

  const onAddNewOperation = () => {
    setIsNewOperation(true);
  };

  const renderFormActions = () => {
    const {
      isVisibleFormActionParamModal,
      actionParam = {},
      screenList,
      popcodeTypes = [],
      spotList = [],
    } = state;
    const {
      selectedFormActionFieldIndex,
      formActionFieldText,
      isNewFieldAction,
      actions = [],
    } = state.formScreenTemplate || {};

    let selectedItems: any = {};
    let sItems: any = [];
    if (actions && actions.length) {
      selectedItems = _.cloneDeep(actions[selectedFormActionFieldIndex] || {});
      if (selectedItems) {
        sItems = selectedItems?.operations?.map((val: any, index: any) => ({
          ...val,
          key: `index-${index}`,
        }));
        selectedItems.operations = sItems;
      }
    }

    const columns = [
      {
        title: "Operation Name",
        dataIndex: "name",
        key: "key-operation-name",
        width: "30%",
        render: (text: any, record: any) => {
          if (text === "runWorkflowStep") {
            return filterXSS(text);
          }
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => onEditFormActionParam(text, record.params)}
            >
              {filterXSS(text)}
            </span>
          );
        },
      },
      {
        title: "Params",
        dataIndex: "params",
        key: "propertyValueParams",
        className: "param-color",
        width: "65%",
        render: (text: any) => {
          if (text) {
            const paramsArray = sortParams(Object.keys(text))
              .map((p) => {
                return text[p];
              })
              .filter((p) => p);
            return filterXSS(paramsArray.join(","));
          }
          return "-";
        },
      },
      {
        key: "pActionName",
        width: "5%",
        render: (text: any, record: any) => {
          if (record.name === "runWorkflowStep") {
            return "";
          }
          return (
            <EditOutlined
              style={{
                cursor: "pointer",
                fontSize: 18,
                color: "#1890ff",
                marginRight: 10,
              }}
              onClick={() => onEditFormActionParam(record.name, record.params)}
              type="edit"
            />
          );
        },
      },
    ];

    const renderParamsField = ({ actionName }: any) => {
      const popcodeTypeNames = (popcodeTypes || []).map((p: any) =>
        filterXSS(p.popcodeTypeName)
      );
      switch (actionName) {
        case "navigate":
          return (
            <Row>
              <Col md={8} style={{ marginBottom: 10 }}>
                Screen Name
              </Col>
              <Col md={10} style={{ marginBottom: 10 }}>
                <Select
                  value={actionParam.screenName || ""}
                  onChange={(e) =>
                    formActionParamHandleChange({
                      target: { name: "screenName", value: e },
                    })
                  }
                  style={{ width: "100%" }}
                >
                  <Option value="">Select Screen Name</Option>
                  {screenList.map((s: any, index: number) => (
                    <Option key={index} value={s.screen}>
                      {filterXSS(s.screen)}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          );
        case "ec3:mintPopcode":
          return (
            <React.Fragment>
              <Row>
                <Col md={8} style={{ marginBottom: 10 }}>
                  Step
                </Col>
                <Col md={10} style={{ marginBottom: 10 }}>
                  <Select
                    value={actionParam.stepName || ""}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      formActionParamHandleChange({
                        target: { name: "stepName", value: e },
                      })
                    }
                  >
                    <Option value="">Select Spot Name</Option>
                    {spotList.map((s: any) => (
                      <Option key={s.spot} value={s.spot}>
                        {filterXSS(s.spot)}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col md={8} style={{ marginBottom: 10 }}>
                  Popcode Type
                </Col>
                <Col md={10} style={{ marginBottom: 10 }}>
                  <AutoComplete
                    value={actionParam.popcodeType || ""}
                    onChange={(e) =>
                      formActionParamHandleChange({
                        target: { name: "popcodeType", value: e },
                      })
                    }
                    options={popcodeTypeNames}
                    placeholder="input here"
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        case "ec3:updatePopcode":
          return (
            <Row>
              <Col md={8} style={{ marginBottom: 10 }}>
                Step
              </Col>
              <Col md={10} style={{ marginBottom: 10 }}>
                <Select
                  value={actionParam.stepName || ""}
                  onChange={(e) =>
                    formActionParamHandleChange({
                      target: { name: "stepName", value: e },
                    })
                  }
                  style={{ width: "100%" }}
                >
                  <Option value="">Select Spot Name</Option>
                  {spotList.map((s: any) => (
                    <Option key={s.spot} value={s.spot}>
                      {filterXSS(s.spot)}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          );
        case "ec3:transfer":
          return (
            <React.Fragment>
              <Row>
                <Col md={8} style={{ marginBottom: 10 }}>
                  Source Popcode Step
                </Col>
                <Col md={10} style={{ marginBottom: 10 }}>
                  <Select
                    value={actionParam.sourceStep || ""}
                    onChange={(e) =>
                      formActionParamHandleChange({
                        target: { name: "sourceStep", value: e },
                      })
                    }
                    style={{ width: "100%" }}
                  >
                    <Option value="">Select Spot Name</Option>
                    {spotList.map((s: any) => (
                      <Option key={s.spot} value={s.spot}>
                        {filterXSS(s.spot)}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col md={8} style={{ marginBottom: 10 }}>
                  Destination Popcode Step
                </Col>
                <Col md={10} style={{ marginBottom: 10 }}>
                  <Select
                    value={actionParam.destStep || ""}
                    onChange={(e) =>
                      formActionParamHandleChange({
                        target: { name: "destStep", value: e },
                      })
                    }
                    style={{ width: "100%" }}
                  >
                    <Option value="">Select Spot Name</Option>
                    {spotList.map((s: any) => (
                      <Option key={s.spot} value={s.spot}>
                        {filterXSS(s.spot)}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col md={8} style={{ marginBottom: 10 }}>
                  Destinaton Popcode Type
                </Col>
                <Col md={10} style={{ marginBottom: 10 }}>
                  <AutoComplete
                    options={popcodeTypeNames}
                    value={actionParam.popcodeType || ""}
                    onChange={(e) =>
                      formActionParamHandleChange({
                        target: { name: "popcodeType", value: e },
                      })
                    }
                    placeholder="input here"
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        case "executeSmartClause":
          return (
            <Row>
              <Col md={8} style={{ marginBottom: 10 }}>
                Rule Name
              </Col>
              <Col md={10} style={{ marginBottom: 10 }}>
                <Input
                  onChange={formActionParamHandleChange}
                  name="ruleName"
                  value={actionParam.ruleName}
                  placeholder="Rule Name"
                />
              </Col>
            </Row>
          );
      }
    };

    return (
      <Row gutter={24}>
        <Col md={10} style={{ marginBottom: 24 }}>
          <Row>
            <Col md={24}>
              <List
                key="Action-leftside-actionParam"
                size="small"
                bordered
                dataSource={actions || []}
                renderItem={(item: any, fieldIndex: any) => {
                  const styles: any = { cursor: "pointer" };
                  if (fieldIndex === selectedFormActionFieldIndex) {
                    styles.border = "1px solid #1890ff";
                  }
                  const { operations, buttonTitle } = item;
                  return (
                    <List.Item
                      key={fieldIndex}
                      style={{ ...styles, padding: "4px 4px 4px 10px" }}
                      onClick={() =>
                        onFormActionFieldSelected(operations, fieldIndex)
                      }
                    >
                      {filterXSS(buttonTitle)}
                      <EditOutlined
                        style={{
                          cursor: "pointer",
                          fontSize: 18,
                          color: "#1890ff",
                          marginRight: 10,
                          float: "right",
                        }}
                        onClick={() => onEditFormActionField(item, fieldIndex)}
                        type="edit"
                      />
                    </List.Item>
                  );
                }}
              />
            </Col>
          </Row>
          {!isNewFieldAction && (
            <Row style={{ marginTop: 10 }}>
              <Col md={22} />
              <Col md={2}>
                <PlusCircleOutlined
                  style={{
                    cursor: "pointer",
                    fontSize: 24,
                    color: "#1890ff",
                    marginRight: 10,
                  }}
                  type="plus-circle"
                  onClick={onFormPlusActionForAction}
                />
              </Col>
            </Row>
          )}
          {isNewFieldAction && (
            <Row style={{ marginTop: 10 }} className="plus_action">
              <Col md={14}>
                <Input
                  placeholder="Button Name"
                  size="small"
                  name="formActionFieldText"
                  style={{ width: "98%" }}
                  value={formActionFieldText || ""}
                  // @ts-ignore
                  onChange={onFormActionFieldChange}
                />
              </Col>
              <Col md={4}>
                <Button
                  size="small"
                  style={{ marginBottom: 5 }}
                  color="primary"
                  onClick={onAddNewFormActionField}
                >
                  Submit
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  size="small"
                  style={{ marginBottom: 5 }}
                  color="primary"
                  onClick={onCancelFormFieldForProperty}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          )}
        </Col>
        <Col md={14} style={{ marginBottom: 24 }}>
          <Row style={{ padding: 15 }}>
            <Col>ButtonTitle:</Col>
            <Col>
              <b>{filterXSS(selectedItems.buttonTitle)}</b>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Table
                size="small"
                className="screenTable"
                bordered
                dataSource={selectedItems.operations || []}
                columns={columns}
                pagination={false}
              />
            </Col>
            <Col md={22} />
            <Col md={2}>
              <PlusCircleOutlined
                key="plus-circle-rightAction-field"
                style={{
                  cursor: "pointer",
                  fontSize: 24,
                  color: "#1890ff",
                  marginRight: 10,
                }}
                type="plus-circle"
                onClick={onAddNewOperation}
              />
            </Col>
          </Row>
        </Col>
        {isVisibleFormActionFieldModal && (
          <Modal
            title="Update Field"
            className="screen_update"
            open={isVisibleFormActionFieldModal}
            onOk={onEditSaveFormActionField}
            onCancel={() => setIsVisibleFormActionFieldModal(false)}
            footer={[
              <Popconfirm
                key="pop-confirm"
                placement="topLeft"
                title="Are you sure delete this Field?"
                onConfirm={() => onDeleteFormActionField(formActionEditIndex)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ float: "left" }} color="danger">
                  Delete
                </Button>
              </Popconfirm>,
              <Button
                key="back"
                onClick={() => setIsVisibleFormActionFieldModal(false)}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={onEditSaveFormActionField}
              >
                Submit
              </Button>,
            ]}
          >
            <Row>
              <Col md={4} style={{ marginBottom: 10 }}>
                ButtonTitle
              </Col>
              <Col md={12} style={{ marginBottom: 10 }}>
                <Input
                  name="buttonTitle"
                  // @ts-ignore
                  onChange={formActionHandleChange}
                  value={formAction?.buttonTitle}
                  placeholder="Button Name"
                />
              </Col>
            </Row>

            <Row>
              <Col md={4} style={{ marginBottom: 10 }} />
              <Col md={8} style={{ marginBottom: 10 }}>
                Operation Names
              </Col>
            </Row>
            {formAction &&
              (formAction.operations || []).map((s: any, dIndex: any) => (
                <Row key={uniqueId()}>
                  <Col md={4} style={{ marginBottom: 10 }} />
                  <Col md={12} style={{ marginBottom: 10, marginRight: 5 }}>
                    <Select
                      defaultValue={s.name || ""}
                      value={s.name || ""}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        formActionHandleChange(
                          { target: { name: "name", value: e } },
                          true,
                          dIndex
                        )
                      }
                    >
                      <Option value="">Select Action</Option>
                      {actionType.map((ats: any) => (
                        <Option key={uniqueId()} value={ats}>
                          {filterXSS(ats)}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col md={2} style={{ marginBottom: 10, marginRight: 5 }}>
                    <DeleteOutlined
                      style={{
                        cursor: "pointer",
                        fontSize: 18,
                        color: "#f5222d",
                        marginRight: 10,
                      }}
                      type="delete"
                      onClick={() => onFormDeleteActionFromModal(dIndex)}
                    />
                  </Col>
                </Row>
              ))}

            {
              <Row style={{ marginTop: 10 }}>
                <Col md={20} style={{ marginBottom: 10, marginRight: 15 }} />
                <Col md={2}>
                  <PlusCircleOutlined
                    style={{
                      cursor: "pointer",
                      fontSize: 24,
                      color: "#1890ff",
                      marginRight: 10,
                    }}
                    type="plus-circle"
                    onClick={onFormPlusActionFromModal}
                  />
                </Col>
              </Row>
            }
          </Modal>
        )}

        {isVisibleFormActionParamModal && (
          <Modal
            title="Update Param"
            className="update_para_s"
            open={isVisibleFormActionParamModal}
            onOk={() => onEditSaveFormActionParam(state.actionParam)}
            onCancel={() =>
              hideShowModal("isVisibleFormActionParamModal", false)
            }
            footer={[
              <Button
                key="back-BUTTON-UPDATE-PARAM-click"
                onClick={() =>
                  hideShowModal("isVisibleFormActionParamModal", false)
                }
              >
                Cancel
              </Button>,
              <Button
                key="submit-button-click-update-param"
                type="primary"
                onClick={() => onEditSaveFormActionParam(state.actionParam)}
              >
                Submit
              </Button>,
            ]}
          >
            <Row key="row-first-update-param">
              <Col
                key="col-first-update-param"
                md={8}
                style={{ marginBottom: 10 }}
              >
                Action Name:
              </Col>
              <Col
                key="col-second-update-param"
                md={14}
                style={{ marginBottom: 10 }}
              >
                <b>{filterXSS(actionParam.actionName)}</b>
              </Col>
            </Row>
            {renderParamsField(actionParam)}
          </Modal>
        )}

        {isNewOperation && (
          <AddParamModal
            key="AddOperation-newParam-key-unique-prop"
            isVisible={isNewOperation}
            onClose={() => setIsNewOperation(false)}
            popcodeTypes={popcodeTypes}
            screenList={screenList}
            spotList={spotList}
            onSubmit={(e) => {
              setIsNewOperation(false);
              onEditSaveFormActionParam(e);
            }}
          />
        )}
      </Row>
    );
  };

  const {
    type,
    buttonLoading,
    screenRow,
    rolesData,
    selectedRBAC = [],
    description,
    isMultiple,
    screenList,
  } = state;

  const columns = [
    {
      key: "key-column-1",
      width: "30%",
      render: (text: any, record: any) => {
        const items = record.name.split(":");
        return items[0] === "*" ? "All" : filterXSS(items[0]);
      },
    },
    {
      key: "key-column-2",
      render: (text: any, record: any) => {
        const items = record.name.split(":");
        return items[1] === "*" ? "All" : filterXSS(items[1]);
      },
    },
  ];

  const renderRBAC = () => (
    <Row className="screen_rbac">
      <Col sm={24} xs={24}>
        <Table
          size="small"
          bordered
          showHeader={false}
          rowSelection={{
            selectedRowKeys: selectedRBAC.map((s: any) =>
              rolesData.findIndex((p: any) => p === s)
            ),
            onChange: onSelectMulti,
          }}
          dataSource={(rolesData || []).map((r: any, i: any) => ({
            name: r,
            key: i,
          }))}
          columns={columns}
          pagination={false}
        />
      </Col>
    </Row>
  );

  return (
    <div style={{ marginLeft: 10 }}>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Row gutter={24} className="track_screen">
            <Col sm={24} md={5} xs={24}>
              <List
                key={"List-screenList-key"}
                size="small"
                bordered
                dataSource={screenList}
                renderItem={(item: any) => {
                  const styles: any = { cursor: "pointer" };
                  if (item.screen === screenRow) {
                    styles.background = "#1890ff";
                  }
                  return (
                    <List.Item
                      key={item.screen}
                      style={{ ...styles }}
                      onClick={() => onSelectedScreenRow(item.screen)}
                    >
                      {item.screen}
                    </List.Item>
                  );
                }}
              />
            </Col>
            <Col sm={24} md={19} xs={24}>
              {screenRow && (
                <React.Fragment>
                  <Col sm={24} md={24} xs={24} className="mb-10">
                    <Card
                      type="inner"
                      title={
                        <Select
                          defaultValue={type}
                          value={type}
                          className="screen_drop"
                          style={{ width: 200, float: "right", zIndex: 1000 }}
                          onChange={(e) => onChangeType(e)}
                        >
                          <Option key="FormScreen" value="FormScreen">
                            FormScreen
                          </Option>
                          <Option key="DescScreen" value="DescScreen">
                            DescScreen
                          </Option>
                          <Option key="ScanScreen" value="ScanScreen">
                            ScanScreen
                          </Option>
                          <Option key="TransferScreen" value="TransferScreen">
                            TransferScreen
                          </Option>
                          <Option
                            key="TransferScreenV2"
                            value="TransferScreenV2"
                          >
                            TransferScreenV2
                          </Option>
                          <Option
                            key="TransferBOMScreen"
                            value="TransferBOMScreen"
                          >
                            TransferBOMScreen
                          </Option>
                        </Select>
                      }
                    >
                      <Row
                        style={{ marginTop: -60 }}
                        className="screen_tabbing"
                      >
                        <Col xs={24}>
                          {screenRow && type === "FormScreen" && (
                            <Tabs
                              key="1"
                              defaultActiveKey="formField"
                              items={[
                                {
                                  label: "Field",
                                  key: "formField-formScreen",
                                  children: renderFormFields(),
                                },
                                {
                                  label: "Action",
                                  key: "formAction-formScreen",
                                  children: renderFormActions(),
                                },
                                {
                                  label: "RBAC",
                                  key: "rbac-formScreen",
                                  children: renderRBAC(),
                                },
                              ]}
                            />
                          )}
                          {screenRow && type === "DescScreen" && (
                            <Tabs
                              key="{2}"
                              className="DescScreen_tab_s"
                              defaultActiveKey="description"
                              items={[
                                {
                                  label: "Description",
                                  key: "description-fieldbox",
                                  children: (
                                    <Row
                                      gutter={24}
                                      style={{ marginBottom: 4 }}
                                    >
                                      <Col sm={24} xs={24}>
                                        <TextArea
                                          value={description}
                                          name="description"
                                          rows={4}
                                          onChange={handleChange}
                                        />
                                      </Col>
                                    </Row>
                                  ),
                                },
                                {
                                  label: "Action",
                                  key: "formAction-description",
                                  children: renderFormActions(),
                                },
                                {
                                  label: "RBAC",
                                  key: "rbac-description",
                                  children: renderRBAC(),
                                },
                              ]}
                            />
                          )}
                          {screenRow && type === "ScanScreen" && (
                            <Tabs
                              key="{3}"
                              defaultActiveKey="scanAction"
                              items={[
                                {
                                  label: "Action",
                                  key: "scanAction-scanAction",
                                  children: (
                                    <Col sm={24} xs={24}>
                                      <Row
                                        gutter={24}
                                        style={{ marginBottom: 24 }}
                                      >
                                        <Col sm={24} xs={24}>
                                          <Checkbox
                                            checked={isMultiple}
                                            name="isMultiple"
                                            onChange={handleChange}
                                          >
                                            Is Multiple
                                          </Checkbox>
                                        </Col>
                                      </Row>

                                      <Row gutter={24}>
                                        <Col
                                          sm={24}
                                          xs={24}
                                          style={{ marginBottom: 24 }}
                                        >
                                          {renderFormActions()}
                                        </Col>
                                      </Row>
                                    </Col>
                                  ),
                                },
                                {
                                  label: "RBAC",
                                  key: "rbac-scanAction",
                                  children: renderRBAC(),
                                },
                              ]}
                            />
                          )}
                          {screenRow &&
                            (type === "TransferScreen" ||
                              type === "TransferScreenV2") && (
                              <Tabs
                                key="{4}"
                                defaultActiveKey="transferField"
                                items={[
                                  {
                                    label: "Field",
                                    key: "transferField-transferScreen",
                                    children: (
                                      <Row gutter={24}>
                                        <Col
                                          sm={24}
                                          xs={24}
                                          style={{ marginBottom: 24 }}
                                        >
                                          {renderFormFields()}
                                        </Col>
                                      </Row>
                                    ),
                                  },
                                  {
                                    label: "Action",
                                    key: "transferAction-transferScreen",
                                    children: (
                                      <Col sm={24} xs={24}>
                                        <Row gutter={24}>
                                          <Col
                                            sm={24}
                                            xs={24}
                                            style={{ marginBottom: 24 }}
                                          >
                                            {renderFormActions()}
                                          </Col>
                                        </Row>
                                      </Col>
                                    ),
                                  },
                                  {
                                    label: "RBAC",
                                    key: "rbac-transferScreen",
                                    children: renderRBAC(),
                                  },
                                ]}
                              />
                            )}
                          {screenRow && type === "TransferBOMScreen" && (
                            <Tabs
                              key="{5}"
                              defaultActiveKey="transferBomField"
                              items={[
                                {
                                  label: "Field",
                                  key: "transferBomField-TransferBOMScreen",
                                  children: (
                                    <Row gutter={24}>
                                      <Col
                                        sm={24}
                                        xs={24}
                                        style={{ marginBottom: 24 }}
                                      >
                                        {renderFormFields()}
                                      </Col>
                                    </Row>
                                  ),
                                },
                                {
                                  label: "Action",
                                  key: "transferBomAction-TransferBOMScreen",
                                  children: (
                                    <Col sm={24} xs={24}>
                                      <Row gutter={24}>
                                        <Col
                                          sm={24}
                                          xs={24}
                                          style={{ marginBottom: 24 }}
                                        >
                                          {renderFormActions()}
                                        </Col>
                                      </Row>
                                    </Col>
                                  ),
                                },
                                {
                                  label: "RBAC",
                                  key: "rbac-TransferBOMScreen",
                                  children: renderRBAC(),
                                },
                              ]}
                            />
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col sm={24} md={24} xs={24} className="mt-10">
                    <Button
                      loading={buttonLoading}
                      color="primary"
                      onClick={onAddScreen}
                    >
                      Save Screen
                    </Button>
                  </Col>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
export default (): JSX.Element => (
  <App>
    <TrackScreens />
  </App>
);
