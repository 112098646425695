import { AutoComplete, Button, Col, Input, Modal, Row, Select } from "antd";
import React, { useState } from "react";

const { Option } = Select;

const AddParamModal = ({
  isVisible,
  onClose,
  popcodeTypes,
  screenList,
  spotList,
  onSubmit,
}: {
  isVisible: boolean;
  onClose: () => void;
  popcodeTypes: any;
  screenList: any[];
  spotList: any[];
  onSubmit: (e: Record<string, any>) => void;
}): JSX.Element => {
  const [actionParam, setActionParam] = useState<Record<string, any>>({});

  const renderParamsField = ({ actionName }: any) => {
    const popcodeTypeNames = (popcodeTypes || []).map((p: any) =>
      filterXSS(p.popcodeTypeName)
    );
    switch (actionName) {
      case "navigate":
        return (
          <Row key="Row1-addParamModal-key">
            <Col
              key="col11-addParamModal-key"
              md={8}
              style={{ marginBottom: 10 }}
            >
              Screen Name
            </Col>
            <Col
              key="col111-addParamModal-key"
              md={10}
              style={{ marginBottom: 10 }}
            >
              <Select
                key="sellect-navigate-unique-key"
                value={actionParam.screenName || ""}
                onChange={(e) => setActionParam({ actionName, screenName: e })}
                style={{ width: "100%" }}
              >
                <Option key="option-key-unique-navigate" value="">
                  Select Screen Name
                </Option>
                {screenList.map((s: any) => (
                  <Option key={`screen-${s.screen}`} value={s.screen}>
                    {filterXSS(s.screen)}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        );
      case "ec3:mintPopcode":
        return (
          <React.Fragment>
            <Row key="Row2-addParamModal-key">
              <Col
                key="col22-addParamModal-key"
                md={8}
                style={{ marginBottom: 10 }}
              >
                Step
              </Col>
              <Col
                key="col222-addParamModal-key"
                md={10}
                style={{ marginBottom: 10 }}
              >
                <Select
                  key="select-key-unique-ec3:mintPopcode"
                  value={actionParam.stepName || ""}
                  style={{ width: "100%" }}
                  onChange={(e) => setActionParam({ actionName, stepName: e })}
                >
                  <Option key="option-key-unique-ec3:mintPopcode" value="">
                    Select Spot Name
                  </Option>
                  {spotList.map((s: any) => (
                    <Option key={`spot-${s.spot}`} value={s.spot}>
                      {filterXSS(s.spot)}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col
                key="col-one-two-addparam-modal-key-unique"
                md={8}
                style={{ marginBottom: 10 }}
              >
                Popcode Type
              </Col>
              <Col
                key="col-two-three-addparammodal-unique-key"
                md={10}
                style={{ marginBottom: 10 }}
              >
                <AutoComplete
                  value={actionParam.popcodeType || ""}
                  onChange={(e) =>
                    setActionParam({ ...actionParam, popcodeType: e })
                  }
                  options={popcodeTypeNames}
                  placeholder="input here"
                />
              </Col>
            </Row>
          </React.Fragment>
        );
      case "ec3:updatePopcode":
        return (
          <Row key="Row3-addParamModal-key">
            <Col
              key="col33-addParamModal-key"
              md={8}
              style={{ marginBottom: 10 }}
            >
              Step
            </Col>
            <Col
              key="col333-addParamModal-key"
              md={10}
              style={{ marginBottom: 10 }}
            >
              <Select
                key="select-key-unique-ec3:updatePopcode"
                value={actionParam.stepName || ""}
                onChange={(e) =>
                  setActionParam({
                    actionName: actionParam.actionName,
                    stepName: e,
                  })
                }
                style={{ width: "100%" }}
              >
                <Option key="select-key-unique-ec3:updatetPopcode" value="">
                  Select Spot Name
                </Option>
                {spotList.map((s: any) => (
                  <Option key={`spot-${s.spot}`} value={s.spot}>
                    {filterXSS(s.spot)}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        );
      case "ec3:transfer":
        return (
          <React.Fragment>
            <Row key="row4-addParamModal-key">
              <Col
                key="col44-addParamModal-key"
                md={8}
                style={{ marginBottom: 10 }}
              >
                Source Popcode Step
              </Col>
              <Col
                key="col444-addParamModal-key"
                md={10}
                style={{ marginBottom: 10 }}
              >
                <Select
                  key="select-key-unique-ec3:transfer"
                  value={actionParam.sourceStep || ""}
                  onChange={(e) =>
                    setActionParam({
                      actionName: actionParam.actionName,
                      sourceStep: e,
                    })
                  }
                  style={{ width: "100%" }}
                >
                  <Option key="option-key-unique-ec3:transfer" value="">
                    Select Spot Name
                  </Option>
                  {spotList.map((s: any) => (
                    <Option key={`spot-${s.spot}`} value={s.spot}>
                      {filterXSS(s.spot)}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row key="row41-addParamModal-key">
              <Col
                key="col41-addParamModal-key"
                md={8}
                style={{ marginBottom: 10 }}
              >
                Destination Popcode Step
              </Col>
              <Col
                key="col411-addParamModal-key"
                md={10}
                style={{ marginBottom: 10 }}
              >
                <Select
                  key="select-key-unique-ec3:transfer2"
                  value={actionParam.destStep || ""}
                  onChange={(e) =>
                    setActionParam({ ...actionParam, destStep: e })
                  }
                  style={{ width: "100%" }}
                >
                  <Option key="option-key-unique-ec3:transfer3" value="">
                    Select Spot Name
                  </Option>
                  {spotList.map((s: any) => (
                    <Option key={`{spot-${s.spot}`} value={s.spot}>
                      {filterXSS(s.spot)}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row key="row42-addParamModal-key">
              <Col
                key="col42-addParamModal-key"
                md={8}
                style={{ marginBottom: 10 }}
              >
                Destinaton Popcode Type
              </Col>
              <Col
                key="col422-addParamModal-key"
                md={10}
                style={{ marginBottom: 10 }}
              >
                <AutoComplete
                  key="Autocomplete-key-unique-prop"
                  options={popcodeTypeNames}
                  value={actionParam.popcodeType || ""}
                  onChange={(e) =>
                    setActionParam({ ...actionParam, popcodeType: e })
                  }
                  placeholder="input here"
                />
              </Col>
            </Row>
          </React.Fragment>
        );
      case "executeSmartClause":
        return (
          <Row key="row5-addParamModal-key">
            <Col
              key="col51-addParamModal-key"
              md={8}
              style={{ marginBottom: 10 }}
            >
              Rule Name
            </Col>
            <Col
              key="col511-addParamModal-key"
              md={10}
              style={{ marginBottom: 10 }}
            >
              <Input
                key="input-field-unique-prop-key-addParam"
                onChange={(e) =>
                  setActionParam({
                    actionName: actionParam.actionName,
                    ruleName: e.target.value,
                  })
                }
                name="ruleName"
                value={actionParam.ruleName}
                placeholder="Rule Name"
              />
            </Col>
          </Row>
        );
    }
  };

  return (
    <Modal
      title="Add Param"
      className="screen_add_para"
      open={isVisible}
      key="id-modal-AddParam-key"
      onOk={() => onSubmit(actionParam)}
      onCancel={onClose}
      footer={[
        <Button key="back-button-click" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit-button-click"
          type="primary"
          onClick={() => onSubmit(actionParam)}
        >
          Submit
        </Button>,
      ]}
    >
      <Row key="row101-addParam-key">
        <Col key="col101-addParam-key" md={8} style={{ marginBottom: 10 }}>
          Action Name:
        </Col>
        <Col key="col2100-addParam-key" md={14} style={{ marginBottom: 10 }}>
          <Select
            key="select-main-return-key-unique-ec3:mintPopcode"
            value={actionParam.actionName || ""}
            onChange={(e) => setActionParam({ ...actionParam, actionName: e })}
            style={{ width: "100%" }}
          >
            <Option key="option-1-id" value="">
              Select Action Name
            </Option>
            <Option key="option-1-navigate" value="navigate">
              {filterXSS("navigate")}
            </Option>
            <Option key="option-1-ec3:mintPopcode" value="ec3:mintPopcode">
              {filterXSS("ec3:mintPopcode")}
            </Option>
            <Option key="option-1-ec3:updatePopcode" value="ec3:updatePopcode">
              {filterXSS("ec3:updatePopcode")}
            </Option>
            <Option key="option-1-ec3:transfer" value="ec3:transfer">
              {filterXSS("ec3:transfer")}
            </Option>
            <Option
              key="option-1-executeSmartClause"
              value="executeSmartClause"
            >
              {filterXSS("executeSmartClause")}
            </Option>
          </Select>
        </Col>
      </Row>
      {renderParamsField(actionParam)}
    </Modal>
  );
};

export default AddParamModal;
