/* eslint-disable quotes */
import { App } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import type { ProColumns } from "@ant-design/pro-table";
import ProTable from "@ant-design/pro-table";
import { filterXSS } from "xss";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
} from "antd";
import type { Project, Workflow } from "../../../types";
import {
  getOneProject,
  getPaginatedWorkflows,
  getProjects,
  getWorkflows,
} from "../../../services/projects";
import { useParams } from "react-router-dom";
import moment from "moment";
import uniqueId from "utils/unique-id";
import {
  copyExistingWorkflow,
  deleteWorkflows,
  getWorkflowListDropdown,
} from "services/api";
import { PageContainer } from "@ant-design/pro-layout";

const Option = Select.Option;
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

const Workflows: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const [data, setData] = useState<Project[]>([]);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [isOpenModal, setOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [workflowName, setName] = useState("");
  const [selectWorkflow, setSelectedWorkflow] = useState<string>();
  const [workflowListDropdown, setWorkflowListDropdown] = useState([]);
  const { message } = App.useApp();
  const [currentPage, setcurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageLimit] = useState(10);
  const [isLoading, setLoading] = useState(false);

  const getData = async (id : string) => {
    setLoading(true);
    const selectedProject = await getOneProject(id);
    const dropdownWorkflowist: any = await getWorkflowListDropdown({
      projectID: id as string,
    });
    setWorkflowListDropdown(dropdownWorkflowist);
    if (selectedProject) {
      setData([selectedProject]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData(id as string);
  }, [id]);

  useEffect(() => {
    wfData(currentPage);
  }, [currentPage, id]);

  const wfData = async (page: Number) => {
    setLoading(true);
    if (id) {
      await getPaginatedWorkflows(page, pageLimit, { projectID: id }).then(
        (res) => {
          setWorkflows(res?.result?.docs);
          setTotal(res?.result?.totalDocs);
        }
      );
    }
    setLoading(false);
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  const isNameExisted = (name: string) => {
    return (
      workflows &&
      workflows.find((p) => {
        const workflowValue = JSON.parse(p?.value);
        return workflowValue.name === name;
      })
    );
  };

  const onCreateNewWorkflow = async () => {
    try {
      if (!workflowName) {
        message.error("please enter name");
      }
      if (isNameExisted(filterXSS(workflowName))) {
        return message.error(`${workflowName} already exists.`);
      }
      const Id = uniqueId();
      const payload = {
        Id,
        id,
        iconSrc: "",
        scenarioId: null,
        workflowId: Id,
        name: filterXSS(workflowName),
        networkName: "sku", // TODO: it should be dynamic
        chainID: localStorage.getItem("chain") || CHAIN_ID,
        type: "",
        createdAt: moment(),
        rbac: {},
      };
      let value = payload;
      if (selectWorkflow) {
        const flow = workflows.find((s) => s.id === selectWorkflow);
        if (flow) {
          const workflowValue = JSON.parse(flow?.value);
          value = {
            Id,
            id,
            iconSrc: workflowValue?.iconSrc || "",
            scenarioId: null,
            workflowId: Id,
            name: filterXSS(workflowName),
            networkName: workflowValue.networkName || "",
            chainID: localStorage.getItem("chain") || CHAIN_ID,
            type: workflowValue?.type,
            createdAt: moment(),
            rbac: workflowValue?.rbac || [],
          };
        }
      }
      setButtonLoading(true);
      if (selectWorkflow || !selectWorkflow) {
        const flow1 = workflowListDropdown.find((s) => s === selectWorkflow);

        await copyExistingWorkflow({
          id: Id,
          value: JSON.stringify(value),
          projectID: id as string,
          copyFrom: flow1 || "",
        });
      }
     await getWorkflows({ projectID: id as string })
        // getPaginatedWorkflows(currentPage,pageLimit,{ projectID: id as string  })
        .then((res) => {
          setWorkflows(res.result.docs);
        })
        .catch();
      setOpen(false);
      setButtonLoading(false);
      resetFields();
      window.location.reload();
      message.success("workflow added successfully!");
    } catch (modalError) {
      message.error("Error in saving workflow!");
      setButtonLoading(false);
    }
    return true;
  };

  const getProjectName = () => {
    const selected = data.find((el: Project) => el.id === id);
    return selected ? selected.name : "N/A";
  };

  const deleteWorkflow = async (workflowId: string) => {
    try {
      await deleteWorkflows({ collName: "workflows", id: workflowId });
      const rows = (workflows || []).filter((p) => p.id !== workflowId);
      setWorkflows(rows);
      message.success("Workflow has been deleted successfully!");
    } catch (error) {
      message.error("Something went wrong. Please try again.");
    }
  };

  const workflowColumns: ProColumns<Workflow>[] = [
    {
      title: "Workflow",
      dataIndex: "name",
      render: (text: any, record: Workflow) => {
        const workflowValue = JSON.parse(record.value);

        return (
          <Link to={`/projects/${id}/workflows/${record.id}`}>
            {workflowValue?.name}
          </Link>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "Action",
      render: (text: any, record: Project) => {
        return (
          <Popconfirm
            title={"Are you sure?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteWorkflow(record?.id as string)}
          >
            <Button className="ml-2" type="default">
              Delete
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const breadcrumb = (
    <Breadcrumb
      items={[
        {
          title: "Track",
        },
        {
          title: <Link to={`/projects`}>Projects</Link>,
        },
        {
          title: `${getProjectName()}`,
        },
      ]}
    />
  );

  const resetFields = () => {
    setName("");
    setSelectedWorkflow("");
  };

  return (
    <>
      <PageContainer header={{ title: " ", breadcrumb: breadcrumb }} />
      <ProTable<Workflow>
        rowKey="id"
        className="track_sub_main"
        search={false}
        options={false}
        toolBarRender={() => [
          <Button
            key="new"
            type="primary"
            onClick={() => setOpen(true)}
            className="pull-right"
          >
            Create New Workflow
          </Button>,
        ]}
        dataSource={workflows}
        //request={() => getProjects()}
        columns={workflowColumns}
        pagination={{
          pageSize: 10,
          total: total,
          current: currentPage,
          onChange: (page) => {
            setcurrentPage(page);
            wfData(page);
          },
        }}
      />
      <Modal
        title="Workflow"
        open={isOpenModal}
        onCancel={() => setOpen(false)}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setOpen(false);
              resetFields();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="new-workflow"
            type="primary"
            loading={buttonLoading}
            onClick={onCreateNewWorkflow}
          >
            {`Create New Workflow`}
          </Button>,
        ]}
      >
        <Row>
          <Col xs={8} style={{ marginBottom: 10 }}>
            Name
          </Col>
          <Col xs={12} style={{ marginBottom: 10 }}>
            <Input
              placeholder="Workflow Name"
              name="workflowName"
              value={workflowName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8} style={{ marginBottom: 10 }}>
            Copy From Existing Workflow
          </Col>
          <Col xs={12} style={{ marginBottom: 10 }}>
            <Select
              style={{ width: "100%" }}
              value={selectWorkflow}
              onChange={(val) => setSelectedWorkflow(val)}
            >
              <Option value="">Select</Option>
              {workflowListDropdown.map((s) => (
                <Option value={s} key={s}>
                  {filterXSS(s)}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default (): JSX.Element => (
  <App>
    <Workflows />
  </App>
);
