import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { HomeOutlined, SmileOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth } from "hooks/auth-context";

const SideMenu = (): JSX.Element => {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    // Derive the "parent" URL paths from the current path e.g. from /trade-chain/nodes, derive /trade-chain and /trade-chain/nodes
    // Highlight all menu items corresponding to the path
    const pathSnippets = location.pathname.split("/").filter((i) => i);
    const paths = pathSnippets.map((_: any, index: number) => {
      return `/${pathSnippets.slice(0, index + 1).join("/")}`;
    });
    setSelectedKeys(paths);
    user?.roles?.forEach((item: any) => {
      if (item?.roleName == "Admin") {
        setIsAdmin(true);
      }
    });
  }, [location, user, setIsAdmin]);
  const navigate = useNavigate();

  const AdminSection = [
    {
      key: "/account/users",
      label: "Users",
      onClick: () => navigate("/account/users"),
    },
    {
      key: "/account/roles",
      label: "Roles",
      onClick: () => navigate("/account/roles"),
    },
    {
      key: "/account/wallet",
      label: "Wallet",
      onClick: () => navigate("/account/wallet"),
    },
  ];
  const NonAdminSection = [
    {
      key: "/account/wallet",
      label: "Wallet",
      onClick: () => navigate("/account/wallet"),
    },
  ];

  const menuItems = [
    {
      key: "/",
      icon: <HomeOutlined />,
      label: "Home",
      onClick: () => navigate("/recent-activity"),
    },
    {
      key: "/account",
      icon: <UserOutlined />,
      label: "Account",
      children: isAdmin ? AdminSection : NonAdminSection,
    },
    {
      key: "/track",
      icon: <SmileOutlined />,
      label: "Track",
      children: [
        {
          key: "/projects",
          label: "Projects",
          onClick: () => navigate("/projects"),
        },
        {
          key: "/popcodes",
          label: "Popcodes",
          onClick: () => navigate("/popcodes"),
        },
        {
          key: "/print-code",
          label: "Generate Popcodes",
          onClick: () => navigate("/print-code"),
        },
      ],
    },
  ];

  const allTopLevelKeys = menuItems.map((item) => item.key);

  // Below is logic to open only the part of a menu corresponding to the active page
  // const openTopLevelItems = menuItems.filter(item => {
  //   const subLevelKeys = item.children.map(subItem => subItem.key);
  //   return subLevelKeys.some(key => selectedKeys.includes(key));
  // });
  // const openTopLevelKeys = openTopLevelItems.map(item => item.key);

  return (
    <Menu
      theme="dark"
      mode="inline"
      items={menuItems}
      selectedKeys={selectedKeys}
      defaultOpenKeys={allTopLevelKeys}
    />
  );
};

export default SideMenu;
