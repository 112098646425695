/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Alert } from "antd";

const MAPKIT_TOKEN = process.env.REACT_APP_MAPKIT_TOKEN;

const loadAppleMaps = () => {
  return new Promise<void>((resolve) => {
    const script = document.createElement("script");
    script.src = `https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js`;
    script.id = "appleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      resolve();
    };
  });
};

const PopcodePathModal = (props: {
  transactions: any;
  isSingle: boolean;
  currentLocation: any;
  modalTitle: string;
  title: string;
  closeModal: any;
}): JSX.Element => {
  // const mapRef = React.createRef();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchMap = async () => {
      try {
        await loadAppleMaps();
        initAppleMap(props);
      } catch (e) {
        console.log("Map Error", (e as Error).message);
        setError(true);
      }
    };
    fetchMap();
  }, []);

  const initAppleMap = async (props: any) => {
    // @ts-ignore
    const { mapkit } = window;
    // window.mapkit
    const { currentLocation, isSingle, title, transactions } = props;
    const getCustomDecimalPoints = (decimalValue = "", point = 6) => {
      if (decimalValue) {
        const splitItems = decimalValue.toString().split(".");
        const lastIndex = splitItems[1];
        if (lastIndex && lastIndex.length && lastIndex.length > point) {
          return Number(`${splitItems[0]}.${lastIndex.substr(0, point)}`);
        }
      }
      return decimalValue;
    };
    mapkit.init({
      authorizationCallback: function (done: any) {
        const tokenID = MAPKIT_TOKEN;
        done(tokenID);
      },
    });
    const calloutDelegate = {
      calloutElementForAnnotation: function (annotation: any) {
        const element = document.createElement("div");
        const titleDiv = element.appendChild(document.createElement("h3"));
        titleDiv.textContent = annotation.title;
        const latSpan = element.appendChild(document.createElement("span"));
        latSpan.textContent = `Lat: ${annotation.data.lat}`;
        element.appendChild(document.createElement("br"));
        const lngSpan = element.appendChild(document.createElement("span"));
        lngSpan.textContent = `Lng: ${annotation.data.lng}`;
        element.style.width = "240px";
        element.style.height = "100px";
        element.style.background = "white";
        element.style.padding = "10px 10px";
        element.style.border = "1px solid #ccc";
        // @ts-ignore
        element.style["border-radius"] = "10px";
        return element;
      },
    };
    if (isSingle) {
      const center = new mapkit.Coordinate(
        getCustomDecimalPoints(currentLocation.latitude),
        currentLocation.longitude
      );
      const map = new mapkit.Map("mapRef", { center });
      // @ts-ignore
      const marker = new mapkit.MarkerAnnotation(map?.center, {
        draggable: false,
        selected: true,
        title,
        callout: calloutDelegate,
        data: {
          lat: getCustomDecimalPoints(currentLocation.latitude),
          lng: getCustomDecimalPoints(currentLocation.longitude),
        },
      });

      // @ts-ignore
      map?.showItems(marker);
    } else if (transactions && transactions.length) {
      const map = new mapkit.Map("mapRef");

      const anots = transactions
        .reverse()
        .map((transaction: any, index: number) => {
          const latlang = new mapkit.Coordinate(
            transaction.coords.lat,
            transaction.coords.lng
          );

          if (index === 0 || index === transactions.length - 1) {
            const marker = new mapkit.MarkerAnnotation(latlang, {
              title: transaction.stepName,
              color: index === 0 ? "gray" : "green",
              glyphColor: "white",
              glyphImage: { 1: "glyphImage.png" },
              selected: true,
              callout: calloutDelegate,
              data: {
                lat: getCustomDecimalPoints(transaction.coords.lat),
                lng: getCustomDecimalPoints(transaction.coords.lng),
              },
            });
            return marker;
          }

          const marker = new mapkit.MarkerAnnotation(latlang, {
            title: transaction.stepName,
            glyphColor: "white",
            glyphImage: { 1: "glyphImage.png" },
            selected: false,
            callout: calloutDelegate,
            data: {
              lat: getCustomDecimalPoints(transaction.coords.lat),
              lng: getCustomDecimalPoints(transaction.coords.lng),
            },
          });
          return marker;
        });
      const coords1 = transactions.map((transaction: any) => {
        const latlang = new mapkit.Coordinate(
          transaction.coords.lat,
          transaction.coords.lng
        );
        return latlang;
      });
      const style = new mapkit.Style({
        lineWidth: 2,
        lineJoin: "round",
        strokeColor: "green",
      });
      const polyline = new mapkit.PolylineOverlay(coords1, { style });
      // @ts-ignore
      map?.addOverlay(polyline);
      // @ts-ignore
      map?.showItems(anots);
    }
  };

  const { closeModal, modalTitle } = props;
  const divMap = <div id="mapRef" style={{ height: 500, width: "100%" }} />;
  return (
    <Modal
      width="80%"
      // @ts-ignore
      height="700"
      title={modalTitle}
      className="disabled-color"
      open
      onOk={closeModal}
      onCancel={closeModal}
    >
      {error ? (
        <Alert
          message="Unable to load map"
          description="There was an error trying to load the map for this Popcode"
          type="error"
        />
      ) : (
        <Row>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <div>{divMap}</div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default PopcodePathModal;
