import { App } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Col, Row, Space, Typography, Card } from "antd";
import TextArea from "antd/lib/input/TextArea";
import useDocumentTitle from "../../../../hooks/use-document-title";
import { getECPairPriv, getAddress } from "skud-client-base";
import { getTendermintPubKey } from "skud-client-base/lib/address";
import { BASE_HD_DLPC_PATH } from "constants/global";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Mnemonic = require("bitcore-mnemonic");
const { Paragraph } = Typography;

const { REACT_APP_CHAIN_PREFIX: chainPrefix = "sku" } = process.env;

const stringifyKey = (key: Buffer): string => key.toString("base64");

const AccessWallet = (): any => {
  useDocumentTitle("Access Wallet");
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const mnemonic = localStorage.getItem("mnemonic");

  if (!mnemonic) {
    notification.error({ message: "Mnemonic not found" });
    return <Navigate to="/account/wallet" replace />;
  }

  // const hashToHdPathId = (value: string) => {
  //   const valueBuffer = Buffer.from(value);
  //   // Hash the value (an alphanumeric string) to a number between 0 and 2**31 - 1 (the maximum value for producing an unhardened path)
  //   const hashBuffer = crypto.Hash.sha256(valueBuffer);
  //   const hashHex = '0x' + hashBuffer.toString('hex');

  //   const hash = Number(BigInt(hashHex) % BigInt(2**31));
  //   return hash;
  // };

  const bitcoreMnemonic = new Mnemonic(mnemonic);
  const hdPrivateKey = bitcoreMnemonic.toHDPrivateKey();
  console.log("hdPrivateKey =>", hdPrivateKey);

  // const counterpartyOrgHdId = hashToHdPathId(dlpcId);
  const hdPath = `${BASE_HD_DLPC_PATH}`;

  const dlpcCounterpartyHdPrivateKey = hdPrivateKey.deriveChild(hdPath);
  const extendedPublicKey = dlpcCounterpartyHdPrivateKey.hdPublicKey;
  console.log("extendedPublicKey =>", extendedPublicKey);

  const privateKey = getECPairPriv(mnemonic);
  const tendermintPubkey = getTendermintPubKey(privateKey);
  console.log(tendermintPubkey);
  const address = getAddress(mnemonic, chainPrefix);

  return (
    <div className="main">
      <Card>
        <Row>
          <Col span={12}>
            <div className="preview">
              <div className="keyContainer">
                <Row>
                  {mnemonic.split(" ").map((word, index) => {
                    const key = `${word}-${index}`;
                    return (
                      <Col span={8} key={key}>
                        <Typography.Title level={5} className="key">
                          {index + 1}. {word}
                        </Typography.Title>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <Paragraph>
                <pre>{mnemonic}</pre>
              </Paragraph>
            </div>
          </Col>
          <Col span={12}>
            <div className="detailContainer">
              <Space size={"small"} direction={"vertical"}>
                <Typography.Title level={4}>
                  This is your key phrase.
                </Typography.Title>
                <Typography.Text>
                  Use these twenty four words in a sequential order to access
                  your wallet.
                </Typography.Text>
                <Space
                  direction={"vertical"}
                  style={{ width: "100%", marginTop: 30 }}
                >
                  <div>
                    <span className="skc-label">Public Key</span>
                    <TextArea rows={2} value={tendermintPubkey.value} />
                  </div>
                  <div>
                    <span className="skc-label">Private Key</span>
                    <TextArea rows={2} value={stringifyKey(privateKey)} />
                  </div>
                  <div>
                    <span className="skc-label">Account Address</span>
                    <TextArea rows={2} value={address} />
                  </div>
                  <div>
                    <span className="skc-label">Extend Pub Key</span>
                    <TextArea rows={2} value={extendedPublicKey} />
                  </div>
                </Space>
                <Typography.Text />
                <Typography.Title
                  level={3}
                  type="warning"
                  style={{ marginTop: 40 }}
                >
                  Attention
                </Typography.Title>
                <Typography>
                  Store this key phrase in a secure location. Anyone with this
                  key phrase can access your wallet. There is no way to recover
                  lost key phrases.
                </Typography>
                <Button
                  onClick={() => navigate("/account/wallet")}
                  type="primary"
                  className="mar"
                >
                  Done
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default (): JSX.Element => (
  <App>
    <AccessWallet />
  </App>
);
