import { App } from "antd";
import React, { useState, useEffect } from "react";
import "./index.css";
import { Button, Card, Col, Input, Row, Space, Typography, Table } from "antd";
import { useNavigate, Link } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";

import { useAuth } from "../../../hooks/auth-context";
import useDocumentTitle from "../../../hooks/use-document-title";

import { getECPairPriv, getAddress } from "skud-client-base";
import { getTendermintPubKey } from "skud-client-base/lib/address";
import { initChainAccount } from "services/chain";
import { BASE_HD_DLPC_PATH } from "constants/global";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Mnemonic = require("bitcore-mnemonic");

const { REACT_APP_CHAIN_PREFIX: chainPrefix = "sku" } = process.env;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stringifyKey = (key: Buffer): string => key.toString("base64");

interface WalletDataType {
  key: string;
  name: string;
  value: string;
}

const WalletHome: React.FC = () => {
  useDocumentTitle("Wallet");
  const navigate = useNavigate();
  const [mnemonic, setMnemonic] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user, setCurrentUser } = useAuth();
  const { notification } = App.useApp();
  const hasWallet = !!user?.wallet?.address;

  const [publicKey, setPublicKey] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const mnemonicLocal = localStorage.getItem("mnemonic");

  useEffect(() => {
    userdata();
    if (mnemonicLocal) {
      const privateKey = getECPairPriv(mnemonicLocal);
      const tendermintPubkey = getTendermintPubKey(privateKey);
      const address = getAddress(mnemonicLocal, chainPrefix);

      setPublicKey(tendermintPubkey.value);
      setAddress(address);
    }
  }, [mnemonicLocal]);

  const handleAccessWallet = async () => {
    try {
      setLoading(true);
      const address = getAddress(mnemonic, chainPrefix);
      const privateKey = getECPairPriv(mnemonic);
      const tendermintPubkey = getTendermintPubKey(privateKey);
      const bitcoreMnemonic = new Mnemonic(mnemonic);
      const hdPrivateKey = bitcoreMnemonic.toHDPrivateKey();
      const hdPath = `${BASE_HD_DLPC_PATH}`;
      const dlpcCounterpartyHdPrivateKey = hdPrivateKey.deriveChild(hdPath);
      const extendedPublicKey = dlpcCounterpartyHdPrivateKey.hdPublicKey;

      //      assert(user.wallet.address === address)
      await initChainAccount({
        address: address,
        publicKey: tendermintPubkey.value,
        save: false,
        extendedPublicKey: extendedPublicKey.xpubkey,
        device_type: "track_desktop",
      });
      localStorage.setItem("mnemonic", mnemonic);
      navigate("access");
      setLoading(false);
    } catch (err) {
      notification.error({
        message:
          "Invalid mnemonic- the mnemonic does not correspond to the public key associated with your account",
      });
      setLoading(false);
    }
  };
  const userdata = async () => {
    const res = await setCurrentUser();
  };

  const dataSource: WalletDataType[] = [
    {
      key: "1",
      name: "Wallet Address",
      value: address,
    },
    {
      key: "2",
      name: "Public key",
      value: publicKey,
    },
  ];

  const columns: ColumnsType<WalletDataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <Card bordered={false}>
      {!mnemonicLocal ? (
        <Row>
          <Col span={24}>
            <h2>Wallet</h2>
            <div>
              <span
                style={{
                  fontSize: 20,
                  marginTop: 20,
                  display: "block",
                  color: "#333",
                  fontWeight: 500,
                }}
              >
                Enter your mnemonic
              </span>
              <span style={{ fontSize: 12, color: "#555" }}>
                Enter a single &quot;SPACE&quot; between every word.
              </span>
              <Input.TextArea
                rows={5}
                onChange={(e) => setMnemonic(e.target.value)}
                style={{ marginTop: "10px", width: 500, display: "block" }}
              />
              <Space direction="vertical" size="large">
                <Button
                  loading={loading}
                  onClick={handleAccessWallet}
                  type={"primary"}
                  className={"mar"}
                  disabled={mnemonic.split(" ").length !== 24}
                  style={{ marginTop: 20 }}
                >
                  Access Wallet
                </Button>
                {!hasWallet ? (
                  <Link to="/account/wallet/new">
                    Don’t have a wallet? Click here to set up a new wallet
                  </Link>
                ) : null}
              </Space>
            </div>
          </Col>
          <Col span={12}>
            {mnemonic !== "" && (
              <div className="preview">
                <Typography.Text>Preview</Typography.Text>
                <Row>
                  {mnemonic.split(" ").map((word, index) => {
                    return (
                      <Col span={8} key={index}>
                        <Typography.Title level={5} className="key">
                          {index + 1}. {word}
                        </Typography.Title>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <>
          <h3>Wallet Details</h3>
          <Table
            bordered
            loading={loading}
            showHeader={false}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
          />
        </>
      )}
    </Card>
  );
};

export default (): JSX.Element => (
  <App>
    <WalletHome />
  </App>
);
