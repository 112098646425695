import axios from "axios";
import { LOCAL_API_URL, NOTIFY_DURATION } from "constants/global";
import { logout } from "./authentication";
import jwt_decode from "jwt-decode";
import { notification } from "antd";
const baseURL = LOCAL_API_URL;

const checkJWTExpired = () => {
  const jwt = localStorage.getItem("token");

  if (!jwt) {
    return false;
  }

  const payload: any = jwt_decode(jwt);
  const expired = Date.now() >= payload.exp * 1000;
  return expired;
};

const AxiosInstance = axios.create({
  baseURL,
});

AxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if ([401, 403].includes(error.response.status) && checkJWTExpired()) {
      await logout();
      notification.error({
        message: "Session was expired, Please login again",
        duration: NOTIFY_DURATION,
      });
    window.localStorage.setItem("token", "");
      // window.location.href = "/login";
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
