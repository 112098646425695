/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import "./index.css";
import { Button, Col, Row, Space, Typography, Card } from "antd";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../../../../hooks/use-document-title";
const { Paragraph } = Typography;

const Done: React.FC = () => {
  const navigate = useNavigate();
  const mnemonic = localStorage.getItem("mnemonic");

  useDocumentTitle("Account");
  return (
    <div className="main">
      <Card>
        <Row>
          <Col span={12}>
            <div className="preview">
              <div className="keyContainer">
                <Row>
                  {mnemonic!.split(" ").map((word, index) => {
                    const key = `${word}-${index}`;
                    return (
                      <Col span={8} key={key}>
                        <Typography.Title level={5} className="key">
                          {index + 1}. {word}
                        </Typography.Title>
                      </Col>
                    );
                  })}
                </Row>
              </div>
              <Paragraph>
                <pre>{mnemonic}</pre>
              </Paragraph>
            </div>
          </Col>
          <Col span={12}>
            <div className="detailContainer">
              <Space size={"small"} direction={"vertical"}>
                <Typography.Title level={1}>Congratulations!</Typography.Title>
                <Typography.Text>
                  Your account is set up and ready to make trade transactions.
                </Typography.Text>
                <Typography.Text />
                <Button
                  onClick={() => {
                    navigate("/account/wallet");
                  }}
                  type={"primary"}
                  className={"mar"}
                >
                  Access Wallet
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Done;
