/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { App } from "antd";
import React, { useEffect, useState } from "react";
import {
  Card,
  Spin,
  Row,
  Col,
  Input,
  Checkbox,
  Button,
  Breadcrumb,
} from "antd";
import _ from "lodash";
import { filterXSS } from "xss";
import type { Project } from "../../../types";
import { getProjectbyId, updateDocument } from "../../../services/api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-layout";
import { PageHeader } from "@ant-design/pro-layout";
import { getProjects } from "services/projects";

const { TextArea } = Input;

const collName = "projects";
const preventXss = (items: string[]) => items.map((s) => filterXSS(s));

const CheckboxGroup = Checkbox.Group;
const OpsBuild = ["Trade steps", "Track flows"];

const About = (): JSX.Element => {
  const [isLoading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [project, setProject] = useState<Project>({ parties: [""] });
  const navigate = useNavigate();
  const { notification, message } = App.useApp();

  //const { projectId } = useParams<{ projectId: string }>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const getData = async () => {
      try {
        const projectsData = await getProjects();
        setProjects(projectsData?.docs);
        if (id) {
          const proj = await getProjectbyId({ collName, Id: id });

          if (proj.parties) {
            const PartiesObj: any = JSON.parse(proj.parties);
            const PartiesArr = Object.keys(PartiesObj).map(
              (item: any) => PartiesObj[item]
            );
            proj.parties = PartiesArr;
          }

          setProject(proj);
        }

        setLoading(false);
      } catch (er) {
        message.error("error in fetch projects");
        setLoading(false);
      }
    };
    getData();
  }, [id]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setProject({
      ...project,
      [name]: value,
    });
    if (name === "type") {
      const buildTypes = project?.buildTypes;
      if (value.includes("Ops build") && !buildTypes.length) {
        setProject({
          ...project,
          name: value[0],
        });
      }
    }
  };

  const handleParties = (name: string, value: string, i: number) => {
    const { parties = [] } = project;
    parties[i] = value;
    setProject({
      ...project,
      [name]: parties,
    });
  };

  const addNewParties = () => {
    const { parties = [] } = project;
    (parties || []).push("");
    setProject({
      ...project,
      parties,
    });
  };

  const deleteParties = (index: number) => {
    const { parties = [] } = project;
    (parties || []).splice(index, 1);
    setProject({
      ...project,
      parties,
    });
  };

  const renderParties = () => {
    const { parties } = project;
    return (
      <React.Fragment>
        {(parties || [])?.map((s: any, i: number) => {
          return (
            <Row key={i} style={{ marginBottom: 10, marginTop: 10 }}>
              <Col md={18} xs={18}>
                <Input
                  placeholder="Parties"
                  value={s || []}
                  onChange={(e) => handleParties("parties", e.target.value, i)}
                />
              </Col>
              <Col md={3} xs={3}>
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={() => deleteParties(i)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          );
        })}
        <Row style={{ marginTop: 20 }}>
          <Col md={6}>
            <Button onClick={addNewParties}>Add New Parties</Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  };
  const isNameExisted = (name: string, id: any) => {
    return (
      projects &&
      projects.find((p: Project) => p?.name === name && p?.id !== id)
    );
  };

  const addAbout = async () => {
    const projId = project && project.id;
    if (isNameExisted(filterXSS(project?.name || ""), projId)) {
      return message.error(`${project.name} already exists.`);
    }
    if (!project.type || project.type.length === 0) {
      return notification.error({
        message: "please select at least one menu",
      });
    }
    if (project && (!project.buildTypes || project.buildTypes.length === 0)) {
      console.log("sdcavd");
      return message.error(`please select at least one item from ops build.`);
    }
    const parties = project?.parties || [];

    if (parties.length) {
      if (parties.some((p: any) => !p)) {
        return message.error("Please enter party name.");
      }

      if (parties.length !== _.uniq(parties).length) {
        return message.error("Party name should be unique!");
      }
    }

    const partiesObj: any = {};
    parties.forEach((party: any, index: number) => {
      partiesObj[index] = party;
    });
    const partyObjStr = JSON.stringify(partiesObj);
    const payload = {
      ...project,
      name: filterXSS(project?.name || ""),
      summary: filterXSS(project?.summary || ""),
      parties: partyObjStr,
      type: project?.type || [],
      buildTypes: preventXss(project?.buildTypes || []),
    };

    setButtonLoading(true);
    payload.id = projId;
    try {
      await updateDocument({ collName, id: payload.id as string, payload });
      // notification.success({ message: res.message });
      setProject(payload);
      setButtonLoading(false);
      notification.success({ message: "Successfully updated." });
      navigate("/projects");
    } catch (errors) {
      message.error((errors as Error).message);
      setButtonLoading(false);
    }
    return true;
  };

  const onRenderBuildType = () => {
    const { buildTypes = [] } = project;
    return (
      <CheckboxGroup
        value={buildTypes}
        onChange={(v) =>
          // @ts-ignore
          handleChange({ target: { name: "buildTypes", value: v } })
        }
      >
        {OpsBuild.map((s, i) => (
          <React.Fragment key={`build-types-${i}`}>
            <Checkbox value={s}>{filterXSS(s)}</Checkbox>
            <br />
          </React.Fragment>
        ))}
      </CheckboxGroup>
    );
  };

  const onRenderProjectType = () => {
    const { type } = project;
    return (
      <CheckboxGroup
        value={type}
        // @ts-ignore
        onChange={(v) => handleChange({ target: { name: "type", value: v } })}
      >
        <Checkbox value="Ops build">Ops Build</Checkbox>
        {(type || []).includes("Ops build") && (
          <Row style={{ marginLeft: 20, paddingTop: 30 }}>
            <Col xs={24} style={{ marginBottom: 10 }}>
              {onRenderBuildType()}
            </Col>
          </Row>
        )}
      </CheckboxGroup>
    );
  };

  const breadcrumb = (
    <Breadcrumb
      items={[
        {
          title: "Track",
        },
        {
          title: <Link to={`/projects`}>Projects</Link>,
        },
        {
          title: (
            <Link to={`/projects/` + id + "/workflows"}>{project.name}</Link>
          ),
        },
        {
          title: "About",
        },
      ]}
    />
  );

  return (
    <>
      <PageContainer header={{ title: " ", breadcrumb: breadcrumb }} />
      <Card style={{ marginTop: 0 }}>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Row>
            <Col md={24}>
              <PageHeader onBack={() => navigate(-1)} title="About" />
            </Col>
            <Col md={12}>
              <Row style={{ marginBottom: 10 }}>
                <Col md={4} style={{ textAlign: "center" }}>
                  Name
                </Col>
                <Col md={12}>
                  <Input
                    placeholder="Project Name"
                    name="name"
                    value={(project && project.name) || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col md={4} style={{ textAlign: "center", marginTop: 20 }}>
                  Parties
                </Col>
                <Col md={12}>{renderParties()}</Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col md={4} style={{ textAlign: "center" }}>
                  Summary
                </Col>
                <Col md={12}>
                  <TextArea
                    placeholder="Project Summary"
                    name="summary"
                    value={(project && project.summary) || ""}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4} style={{ marginBottom: 10 }}>
                  &nbsp;
                </Col>
                <Col md={12} style={{ marginBottom: 10 }}>
                  {onRenderProjectType()}
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col md={4} style={{ textAlign: "center" }} />
                <Col md={10}>
                  <Button loading={buttonLoading} onClick={addAbout}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
};

export default (): JSX.Element => (
  <App>
    <About />
  </App>
);
