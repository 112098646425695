/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import { getAuthorizationHeader } from "../utils/utils";
import { LOCAL_API_URL } from "../constants/global";
import qs from "qs";
import AxiosInstance from "./axiosinstance";

type RoleAddArgs = {
  roleName: string;
  // accessControl: string[];
};

type RoleEditArgs = {
  id: string;
  roleName: string;
  // accessControl: string[];
};

const getRandomPass = () => {
  const chars =
    "0123456789~!@#$%^&*()_+}{[]|abcdefghikjlmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let pass = "";
  const passLength = 16;
  for (let i = 0; i < passLength; i++) {
    const randPass = Math.floor(Math.random() * chars.length);
    pass += chars.substring(randPass, randPass + 1);
  }
  return pass;
};

export const createRole = async ({ roleName }: RoleAddArgs, userId: string) => {
  const password = getRandomPass();
  const data = await AxiosInstance.post(
    `/track-admin/create-roles`,
    { roleName, userId },
    { headers: getAuthorizationHeader() }
  );
  return data;
};




export const updateRole = async ({
  id,
  roleName,
}: // accessControl,
  RoleEditArgs, userId:any) => {
  const data = await AxiosInstance.post(
    `${LOCAL_API_URL}/track-admin/update-roles/`,
    { id, roleName, userId },
    { headers: getAuthorizationHeader() }
  );

  return data;
};

// export const getRole = async (id: string) => {
//   await AxiosInstance.get(`/roles`, { headers: getAuthorizationHeader() });
// };

export const getAllRoles = async (page: Number, limit: Number) => {
  const stringifiedQuery = qs.stringify(
    {
      page: page,
      limit: limit,
    },
    { addQueryPrefix: true }
  );

  const data = await AxiosInstance.get(`/track-admin/get-all-roles${stringifiedQuery}`, {
    headers: getAuthorizationHeader(),
  });

  return data;
};

export const deleteRole = async (id: string, userId:any) => {
  const data = await AxiosInstance.post(`${LOCAL_API_URL}/track-admin/delete-roles/`, { id, userId }, {
    headers: getAuthorizationHeader(),
  });
  return data;
};
