import _ from "lodash";

function codes2edges(fromid: any, toid: any, event?: any) {
  const edge = {
    from: fromid,
    to: toid,
    color: "#0086c3",
    selectionWidth: 2,
    hoverWidth: 0,
    id: `${fromid}-${toid}`,
    arrows: "to",
    event,
  };
  return edge;
}

export function getSkuid(popcode: { skuid: any }): any {
  const skuid: any[] = (popcode.skuid || []) as any[];
  return skuid[0];
  //   return R.find((s: any) => s.domain.includes(window.location.host), skuid) || skuid[0];
}

function code2node(code: any, nodeargs: any = {}, level: any = 1) {
  let parsecode;
  try {
    parsecode = JSON.parse(code.value);
  } catch {
    parsecode = code.value;
  }
  const asset = parsecode.asset;
  let assetLabel = "";
  if (asset) {
    assetLabel = `${Number(asset.Amount).toFixed(2)} ${
      asset.UnitOfMeasure
    } of ${asset.Name}`;
  }
  const skuid = getSkuid(code) || {};
  const skuidLabel =
    !_.isEmpty(skuid.label) &&
    skuid.label !== "Human readable name" &&
    skuid.label;
  const skuidId = !_.isEmpty(skuid.id) && skuid.id;

  const popcodeLabel = skuidLabel || skuidId || parsecode.address;
  const label = `${popcodeLabel} \n${assetLabel}`;

  const xargs = Object.assign(
    {
      level,
      popcode: code,
      shape: "dot",
      id: `${parsecode.address}`,
      label,
      x: 0,
      y: 0,
    },
    nodeargs
  );

  return xargs;
}

function getParents(provenanceTrail: any, self: any) {
  const parents: any[] = [];
  const trails = provenanceTrail;
  trails.reverse();

  trails.forEach((el: any) => {
    if (el.edges.length > 0) {
      el.edges.forEach((edge: any) => {
        if (edge.from !== self && edge.to === self) {
          const parent = el.vertices.find(
            (vertice: any) => vertice._id === edge.from
          );
          const grandParentEdge = el.edges.find((e: any) => e.to === edge.from);
          if (grandParentEdge) {
            const grandParents = el.vertices.find(
              (vertice: any) => vertice._id === grandParentEdge.from
            );
            parent.parents = [grandParents];
          }
          if (!parents.find((c) => c._id === parent._id)) {
            parents.push(parent);
          }
        }
      });
    }
  });
  return parents;
}

export function getChildren(provenanceTrail: any[], self: string): any[] {
  const children: any[] = [];
  const trails = provenanceTrail;
  trails.reverse();

  trails.forEach((el: any) => {
    if (el.edges.length > 0) {
      el.edges.forEach((edge: any) => {
        if (edge.to !== self && edge.from === self) {
          const child = el.vertices.find(
            (vertice: any) => vertice._id === edge.to
          );
          const grandEdge = el.edges.find(
            (e: any) => e.from === edge.to && e.to !== edge.to
          );
          if (grandEdge) {
            const grandChildren = el.vertices.find(
              (vertice: any) => vertice._id === grandEdge.to
            );
            child.children = [grandChildren];
            children.push(child);
          }
          if (!children.find((c) => c._id === child._id)) {
            children.push(child);
          }
        }
      });
    }
  });

  return children;
}

export async function buildGraphFromProvenance({
  popcode,
  provenanceTrail,
}: {
  popcode: any;
  provenanceTrail: any[];
}): Promise<{ edges: any; nodes: any }> {
  const nodes: any = [];
  const edges: any = [];
  const parents = getParents(provenanceTrail, popcode?.id);
  const children = getChildren(provenanceTrail, popcode?.id);

  const pcNodeargs = {
    color: "#800000",
  };
  nodes.push(code2node(popcode, pcNodeargs));

  const addChildren = (ch: any, linkAddress: any) => {
    ch.filter((c: any) => c._id).forEach((c: any) => {
      nodes.push(code2node(c));
      edges.push(codes2edges(linkAddress, c._id));

      if (c.children) {
        addChildren(c.children, c._id);
      }
    });
  };

  const addParents = (ps: any, linkAddress: any) => {
    ps.filter((p: any) => p._id).forEach((p: any) => {
      nodes.push(code2node(p));
      edges.push(codes2edges(p._id, linkAddress));

      if (p.parents) {
        addParents(p.parents, p._id);
      }
    });
  };

  addChildren(children, popcode.id);
  addParents(parents, popcode.id);
  return {
    edges,
    nodes,
  };
}
