import React, { useEffect, useState } from "react";
import { Divider, Button, Card, Row, Col, List, Spin } from "antd";
import { Link, useParams } from "react-router-dom";
import ReactJson from "react-json-view";
import {
  FileDownload,
  Filesdisplay,
  PopcodeGraph,
  fetchPopcodeByAddress,
} from "services/popcodes";
import { DownloadOutlined } from "@ant-design/icons";
import Graph from "./graph";
import { buildGraphFromProvenance } from "./build-graph";
import "./popcode-event-graph.css";

const PopcodeEventGraph = (): JSX.Element => {
  const nodesRef = React.createRef();
  const edgesRef = React.createRef();
  const networkRef = React.createRef<any>();

  const { address } = useParams<{ address: string }>();
  const originAddress = address;
  const [popCode, setPopCode] = useState<any>();
  const [graph, setGraph] = useState<any>();
  const [isLoading, setLoading] = useState(true);
  const [, setError] = useState<Error>();
  const [Files, setFiles] = useState<any>();

  const fetchPopcode = async () => {
    setLoading(true);
    try {
      const response = await fetchPopcodeByAddress({ address: address || "" });
      const parse_response = JSON.parse(response.value);
      const eventdata = JSON.parse(parse_response.eventData);

      const filesdata= await Filesdisplay({ address: address || "" });
      if(filesdata?.value){
        const filesD= JSON.parse(filesdata?.value);
        setFiles(filesD);
      }
    
      if (response) {
        setPopCode({ ...response, eventdata });
        setLoading(false);
      }
    } catch (err) {
      setError(err as Error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPopcode();
    // eslint-disable-next-line
  }, [address]);

  const eventGraph = async () => {
    setLoading(true);
    if (!popCode) {
      return;
    }
    try {
      const data = { popcodeAddress: address };
      const res = await PopcodeGraph({ payload: data });

      if (res) {
        setGraph({
          popcode: popCode || {},
          provenanceTrail: res.provenanceTrail,
        });
        setLoading(false);
      }
    } catch (err) {
      setError(err as Error);
    }
    setLoading(false);
  };

  useEffect(() => {
    eventGraph();
    // eslint-disable-next-line
  }, [popCode, address]);

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [, setExpanded] = useState<any>();
  const [selectedPopcode, setSelectedPopcode] = useState<any>(null);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [selectedPop, setSelectedPop] = useState<any>(null);
  const [graphLoading, setgraphLoading] = useState(true);

  const provenance = async () => {
    setLoading(true);
    setgraphLoading(true);
    try {
      const results = await buildGraphFromProvenance(graph);
      if (results) {
        setEdges(results.edges);
        setNodes(results.nodes);
        setLoading(false);
        setExpanded([originAddress]);
        setSelectedPopcode(graph.popcode);
      }
    } catch (err) {
      setLoading(false);
      setError(err as Error);
    }
    setLoading(false);
    setgraphLoading(false);
  };

  useEffect(() => {
    provenance();
    // eslint-disable-next-line
  }, [graph, address]);

  if (!graph) {
    return <Spin />;
  }

  const handleOnClick = async (params: any, nodes: any) => {
    if (params.nodes.length >= 1) {
      const id = params.nodes[0];

      if (nodes.get(id).popcode) {
        const pop = nodes.get(id).popcode;
        if (pop) {
          setSelectedPop(pop);
          let value;
          try {
            value = JSON.parse(pop.value);
          } catch {
            value = pop.value;
          }
          const eventd = JSON.parse(value.eventData);
          setSelectedNode(eventd);
        }
      }
    }
  };
  // console.log("selectedNode",selectedNode)
  if (!selectedNode) {
    setSelectedNode(popCode?.eventdata);
  }

  const downloadFile = (bufferArray: any, fileName: string, mimeType: any) => {
    const blob = new Blob([bufferArray], { type: mimeType });
    //const blob = new Blob([bufferArray], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownload = async (loc: any, file: any, mimeType: any) => {
    try {
      setLoading(true);
      const parsedval = JSON.parse(loc);
      const data = { cid: parsedval.path };
      const file_down = await FileDownload({ payload: data });
      downloadFile(file_down.data, file, mimeType);
    } catch (error) {
      setLoading(false);
      console.log("Err: Unable to download file", error);
    }
    setLoading(false);
  };

  function renderFiles() {
    if(Files!=="[]"){
      const fileObjects = Files?.map((obj:any) => Object.entries(obj))
      .flat()
      .filter(([, value]:any) => value?.type === "file")
      .map(([key, value]:any) => ({ name: key, value }));
      // console.log("fileObjects",fileObjects);

    const files = fileObjects || [];

    const uniqFiles: any[] = [];
    const keys: any[] = [];
    files.forEach((obj: any) => {
      const key = obj.name;
      if (!keys.includes(key)) {
        keys.push(key);
        uniqFiles.push([obj]);
      }
    });

    if (uniqFiles.length === 0) {
      return (
        <List size="small" header={<strong>Files</strong>}>
          <p>There are no files stored in this popcode.</p>
        </List>
      );
    }

    return (
      <List size="small" header={<strong>Files</strong>}>
        {uniqFiles.map(([key]) => (
          <List.Item
            actions={[
              <Button
                onClick={() => {
                  handleDownload(
                    key.value.location,
                    key.value.originalname,
                    key.value.mimetype
                  );
                }}
                icon={<DownloadOutlined />}
                size="small"
              ></Button>,
            ]}
          >
            <List.Item.Meta
              className="ant-list-item-meta-title"
              title={key.name}
            />
          </List.Item>
        ))}
      </List>
    );
   }
  }

  if (graphLoading) {
    return (
      <Card>
        <div style={{ textAlign: "center" }}>
          <p>Building Graph...</p>
          <Spin />
        </div>
      </Card>
    );
  }

  return (
    <Row>
      <Col xs={16}>
        <Card title="Graph">
          <Graph
            animated
            nodesRef={nodesRef}
            edgesRef={edgesRef}
            networkRef={networkRef}
            nodes={nodes}
            edges={edges}
            onClick={handleOnClick}
            options={{
              autoResize: true,
              edges: {
                smooth: true,
              },
              nodes: {
                shape: "dot",
                size: 25,
              },
            }}
            onNodeClick={undefined}
            onNodeDoubleClick={undefined}
            delayMs={0}
          />
        </Card>
      </Col>

      {selectedPopcode && (
        <Col xs={7} offset={1}>
          <Card
            loading={isLoading}
            size="small"
            style={{
              minHeight: 706,
            }}
          >
            <div style={{ maxHeight: 677, overflow: "auto" }}>
              <div style={{ textAlign: "right" }}>
                <Button style={{ marginRight: 8 }}>
                  <Link
                    to={`/popcodes/popcode/${
                      selectedPop?._id ? selectedPop?._id : popCode?.id
                    }`}
                  >
                    Open Popcode
                  </Link>
                </Button>
              </div>

              <Divider />
              {renderFiles()}
              <Divider />

              <strong>Data: </strong>
              <ReactJson
                name={false}
                displayDataTypes={false}
                src={selectedNode?.Data}
                collapsed
              />

              <strong>Event Data: </strong>
              <ReactJson
                name={false}
                displayDataTypes={false}
                src={selectedNode?.Metadata}
                // src={((selectedNode?.Metadata)|| popCode?.eventdata.Metadata)}
                collapsed
              />

              <strong>Raw Popcode: </strong>
              <ReactJson
                displayDataTypes={false}
                name={false}
                src={selectedPop ? selectedPop : popCode}
                collapsed
              />

              {/* <Divider />
                {this.renderTimeline(selectedPopcode)} */}
            </div>
          </Card>
        </Col>
      )}
    </Row>
  );
};
export default PopcodeEventGraph;
