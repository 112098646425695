export function getMainDomain(): string {
  const domain = getDomain();
  const subDomain = getSubDomain();
  return domain.replace(subDomain + ".", "");
}

export function getDomain(): string {
  return window.location.hostname;
}

export const getSubDomain = (): string => {
  const domain = getDomain();
  const parts = domain.split(".");
  return domain.includes("localhost") ? "gacme-01" : parts[0];
};

export const getOrganization = (): string => {
  return getSubDomain();
};

export const getXTenantHeader = (): any => {
  return {
    "x-tenant": getOrganization(),
  };
};

export const getAuthorizationHeader = (): any => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Could not find access token");
  }
  return {
    Authorization: `JWT ${token}`,
  };
};

export const truncateAddress = (
  address: string | null | undefined,
  length = 6
): string => {
  if (!address) return "No Account";
  const regex = new RegExp(
    `^([a-zA-Z0-9]{${length}})[a-zA-Z0-9]+([a-zA-Z0-9]{${length}})`
  );
  const match = address.match(regex);
  if (!match) return address;
  return `${match[1]}…`;
};
