import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Input, Row, Select, Table } from "antd";
import moment from "moment";
import useDocumentTitle from "../../../hooks/use-document-title";
import { useFormik } from "formik";
import {
  fetchEventsbycol,
  getAllPaginatedData,
  getAssets,
  getPaginatedpopcodelists,
} from "services/popcodes";
import { getMyOrgInfo } from "services/api";

export default function PopcodeIndex(): JSX.Element {
  const initialFilters = {
    status: "",
    address: "",
    uid: "",
    asset: "",
    workflow: "",
  };

  interface popcodeDataType {
    status: string;
    address: string;
    uid: string;
    asset: string;
    workflow: string;
    timestamp: any;
    updatedTimestamp: any;
  }

  const genericColumns = useMemo(
    () => [
      {
        key: "Address",
        title: "Address",
        render: (_: any, record: any) => (
          <span>
            <Link to={`/popcodes/popcode/${record?.address}`}>
              {record?.address?.length === 0
                ? ""
                : record?.address?.substring(
                    0,
                    Math.min(record.address.length, 6)
                  )}
            </Link>
          </span>
        ),
      },
      {
        key: "UserId",
        title: "User ID",
        render: (_: any, record: any) => record?.uid,
      },
      {
        key: "Asset",
        title: "Asset",
        render: (_: any, record: any) => record?.asset?.Name,
      },
      {
        key: "Amount",
        title: "Amount",
        render: (_: any, record: any) =>
          record.asset.Amount
            ? `${parseFloat(
                String(record?.asset?.Amount).replace(/[^\d.]/g, "")
              )} ${record?.asset?.UnitOfMeasure}`
            : record.asset.Amount == 0
            ? `0 ${record?.asset?.UnitOfMeasure}`
            : " ",
      },
      {
        key: "Status",
        title: "Status",
        render: (_: any, record: any) => record?.status,
      },
      {
        key: "Workflow",
        title: "Workflow",
        render: (_: any, record: any) => record.workflow,
      },
      {
        key: "Last Updated",
        title: "Last Updated",
        render: (_: any, record: any) =>
          record.updatedTimestamp !== undefined
            ? moment(record.updatedTimestamp).fromNow()
            : moment(record.timestamp).fromNow(),
      },
      {
        key: "Action",
        title: "Action",
        render: (_: any, record: any) => (
          <span>
            <Link to={`/popcodes/popcode/${record.address}`}>View Popcode</Link>
          </span>
        ),
      },
      // eslint-disable-next-line
    ],
    []
  );

  const [isLoading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [columns, setColumns] = useState(genericColumns);
  const [popcodeData, setpopcodeData] = useState<popcodeDataType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [wflowname, setWflowname] = useState<any>();
  const [asset, setAsset] = useState<any>();
  const [statuses, setStatuses] = useState<[]>();
  const [uid, setuid] = useState<any>();

  const [total, setTotal] = useState(0);
  const [pageLimit] = useState(10);

  const formik = useFormik({
    initialValues: {
      ...initialFilters,
    },
    onSubmit: async (values) => {
      setFilters(values);

      const popcodeslist = await getPaginatedpopcodelists(
        currentPage,
        pageLimit,
        {
          collName: "popcodelists",
          payload: {
            status: values?.status,
            address: values?.address,
            uid: values?.uid,
            asset: values?.asset,
            workflow: values?.workflow,
          },
        }
      );
      setTotal(popcodeslist.totalDocs);

      const arr_new: any = [];
      popcodeslist.paginatedPopcodes.forEach((doc: any) => {
        const popcodes = JSON.parse(doc.value);
        const status = JSON.parse(popcodes?.eventData);

        arr_new.push({
          workflow:
            status.Metadata.WorkflowName || status.Metadata.workflowName,
          uid: status.Data.uid || uid,
          status: status.Metadata.spot,
          asset: popcodes?.asset,
          address: popcodes?.address,
          timestamp: popcodes?.timestamp,
          updatedTimestamp: doc?.updatedAt,
        });
      });
      setpopcodeData(arr_new);
      return popcodeslist;
    },
  });

  useDocumentTitle("Popcodes");

  useEffect(() => {
    const filterscheck =
      !filters.status &&
      !filters.address &&
      !filters.uid &&
      !filters.workflow &&
      !filters.asset;
    if ((currentPage === 1 && filterscheck) || filterscheck) {
      getData(currentPage);
    }
    // eslint-disable-next-line
  }, [currentPage]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getData = async (page: Number) => {
    setLoading(true);

    const getassets = await getAssets();
    const popcodeResponse = await getAllPaginatedData(page, pageLimit, {
      collName: "popcodes",
    });
    setTotal(popcodeResponse.totalDocs);

    const arr: popcodeDataType[] = [];
    const wf: any = [];

    const dataOrg = await getMyOrgInfo();
    const orgname = dataOrg.legalName;
    setuid(orgname);

    popcodeResponse?.docs?.forEach((doc: any) => {
      const popcodes = JSON.parse(doc.value);
      const status = JSON.parse(popcodes?.eventData);
      const data = status.Metadata.WorkflowName || status.Metadata.workflowName;
      if (!wf.includes(data)) {
        wf.push(data);
      }
      setWflowname(wf);

      arr.push({
        workflow:
          status?.Metadata?.WorkflowName || status?.Metadata?.workflowName,
        uid: status?.Data?.uid || orgname,
        status: status?.Metadata?.spot,
        asset: popcodes?.asset,
        address: popcodes?.address,
        timestamp: popcodes?.timestamp,
        updatedTimestamp: doc?.updatedAt,
      });
    });
    arr.sort(
      (a: any, b: any) =>
        Date.parse(b.updatedTimestamp ?? b.timestamp) -
        Date.parse(a.updatedTimestamp ?? a.timestamp)
    );
    setpopcodeData(arr);
    setColumns(genericColumns);
    setLoading(false);
    setAsset(getassets);
  };

  return (
    <Card bordered={false} className="gen_pass">
      <Card>
        <Row gutter={12} style={{ marginTop: 5 }} justify="start">
          <Col lg={4} md={6} sm={12} xs={24}>
            <Row>
              <Col md={24}>Asset</Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={24}>
                <Select
                  onChange={(v) => formik.setFieldValue("asset", v)}
                  value={formik.values.asset}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="">All</Select.Option>
                  {(asset ?? []).map((ast: any) => (
                    <Select.Option key={ast} value={ast}>
                      {ast}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>

          <Col lg={8} md={12} sm={24} xs={48}>
            <Row>
              <Col md={24}>Workflow</Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={24}>
                <Select
                  onChange={async (v) => {
                    setLoading(true);
                    formik.setFieldValue("workflow", v);
                    const workflowlist = await fetchEventsbycol({
                      collName: "workflowlists",
                      payload: { workflowName: v },
                    });
                    setStatuses(workflowlist.spots);
                    setLoading(false);
                  }}
                  value={formik.values.workflow}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="">All</Select.Option>
                  {(wflowname ?? []).map((ast: any) => (
                    <Select.Option key={ast}>{ast}</Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>

          <Col lg={4} md={6} sm={12} xs={24}>
            <Row>
              <Col md={24}>Status</Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={24}>
                <Select
                  onChange={(v) => formik.setFieldValue("status", v)}
                  value={formik.values.status}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="">All</Select.Option>
                  {(statuses ?? []).map((status: any) => (
                    <Select.Option key={status} value={status}>
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>

          <Col lg={4} md={6} sm={12} xs={24}>
            <Row>
              <Col md={24}>Address</Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={24}>
                <Input
                  value={formik.values.address}
                  name="address"
                  onChange={formik.handleChange}
                  placeholder="Popcode Address"
                />
              </Col>
            </Row>
          </Col>

          <Col lg={4} md={6} sm={12} xs={24}>
            <Row>
              <Col md={24}>User ID</Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={24}>
                <Input
                  value={formik.values.uid}
                  name="uid"
                  onChange={formik.handleChange}
                  placeholder="User ID"
                />
              </Col>
            </Row>
          </Col>

          <Col lg={10} md={18} sm={12} xs={24}>
            <Row>
              <Col md={24}>&nbsp;</Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col md={24} className="pop_btns">
                <Button
                  type="primary"
                  onClick={() => formik.handleSubmit()}
                  style={{ marginRight: 20 }}
                >
                  Search
                </Button>
                <Button
                  type="primary"
                  onClick={(e) => {
                    setStatuses([]);
                    formik.handleReset(e);
                    //formik.handleSubmit();
                    window.location.reload();
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Table
        rowKey={(record) => record.address}
        loading={isLoading}
        dataSource={popcodeData}
        columns={columns}
        pagination={{
          pageSize: 10,
          total: total,
          showSizeChanger: false,
          onChange: async (page) => {
            setCurrentPage(page);
            await formik.handleSubmit();
            //getData(page);
          },
        }}
      />
    </Card>
  );
}
