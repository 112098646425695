import React, { useEffect } from "react";
import { Form, Input, Modal } from "antd";
// import type { SelectProps } from "antd";

interface DataType {
  key: string;
  id: string;
  roleName: string;
  // accessControl: string[];
}

interface RoleEditFormProps {
  open: boolean;
  onCreate: (values: DataType) => void;
  onCancel: () => void;
  selectedRole: DataType;
}

// const accessControls: string[] = ["create", "update", "read", "delete"];

// const options: SelectProps["options"] = [];

// for (let i = 0; i < accessControls.length; i++) {
//   options.push({
//     label: accessControls[i],
//     value: accessControls[i],
//   });
// }

const RoleEditForm: React.FC<RoleEditFormProps> = ({
  open,
  onCreate,
  onCancel,
  selectedRole,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      roleName: selectedRole?.roleName,
    });
  }, [selectedRole]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={{ modifier: "public" }}
      className="user-form"
    >
      <Modal
        open={open}
        title="Edit User"
        okText="Update"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              values = {
                ...values,
                id: selectedRole?.id,
              };
              onCreate(values);
              form.resetFields();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form.Item
          name="roleName"
          label="Role Name"
          rules={[{ required: true, message: "Please Enter role name!" }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="accessControl"
          label="Access Control"
          rules={[
            { required: true, message: "Please select the access control!" },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={options}
          />
        </Form.Item> */}
      </Modal>
    </Form>
  );
};

export default RoleEditForm;
