import { Route, Routes } from "react-router-dom";
import { RequireAuth, useAuth } from "./hooks/auth-context";
import { authRoutes, unAuthRoutes } from "./routes";
import PageLayout from "components/layout/page-layout";

import "App.css";
import { useEffect, useMemo } from "react";
import { notification } from "antd";

const App = (): JSX.Element => {
  const { setCurrentUser } = useAuth();
  const location = window.location.pathname;
  notification.config({ duration: 4 });
  useEffect(() => {
    setCurrentUser();
    // eslint-disable-next-line
  }, []);
  const isAuthRoute = useMemo(() => {
    const currentItem = authRoutes.find((el) => el.path === location);
    return currentItem ? true : false;
  }, [location]);
  const routeItems = authRoutes.map((route: any) => {
    return <Route {...route} key={route} />;
  });
  const aunAuthRouteItems = unAuthRoutes.map((route: any) => {
    return <Route {...route} key={route} />;
  });
  return (
    <div className="app">
       <div className={isAuthRoute ? "layout-unauth" : "layout-unauth"}>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <PageLayout />
              </RequireAuth>
            }
          >
            {routeItems}
          </Route>
          {aunAuthRouteItems}
        </Routes>
      </div>
    </div>
  );
};

export default App;
