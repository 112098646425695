/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import { Breadcrumb, Card } from "antd";
import { getProjectbyId, getDocumentbyId } from "../../../services/api";
import TrackComponent from "./track";

import { Project, Workflow } from "../../../types";
import "./workflow.css";
import { Link, useParams } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-layout";
import useDocumentTitle from "../../../hooks/use-document-title";

const WorkflowContainer = (): JSX.Element | null => {
  const [activeKey, setActiveKey] = useState("display");
  const [workflow, setWorkflow] = useState<Workflow>();
  const [screenList, setScreenList] = useState<any[]>([]);
  const [project, setProject] = useState<Project>();
  const [workflowName, setWorkflowName] = useState<any[]>([]);
  const [projectName, setProjectName] = useState<any[]>([]);
  const { id, workflowId } = useParams<{
    id: string;
    type: string;
    workflowId: string;
  }>();

  useDocumentTitle("Workflow detail");
  useEffect(() => {
    const getData = async () => {
      try {
        const selectedProjects = await getProjectbyId({
          collName: "projects",
          Id : id as string
        });

        if (selectedProjects){
         
          setProjectName(selectedProjects?.name);
          setProject(selectedProjects || {});
          const resWorkflow = await getDocumentbyId({
            collName: "workflows",
            Id : workflowId as string
          });
          console.log("resWorkflow => ",resWorkflow);
          if(resWorkflow){
            const workflowValue = JSON.parse(resWorkflow?.value);
            setWorkflowName(workflowValue?.name);
            setWorkflow(resWorkflow || {});
          }else{
            console.log("No workflows found");
          } 
        }
        else{
          console.log("No projects found");
        } 
      } catch (er) {
        setWorkflow({} as Workflow);
      }
    };

    getData();
  }, [id, workflowId]);

  const getProjectLink = () => {
    if (project) {
      return "/projects/" + id + "/workflows";
    } else {
      return "";
    }
  };

  const updateStateConfig = async (e: any) => {
    setScreenList(e.screenList);
  };

  if (!workflow) {
    return null;
  }

  const breadcrumb = (
    <Breadcrumb
      items={[
        {
          title: "Track",
        },
        {
          title: <Link to={`/projects`}>Projects</Link>,
        },
        {
          title: <Link to={getProjectLink()}>{projectName}</Link>,
        },
        {
          title: `${workflow ? workflowName : ""}`,
        },
      ]}
    />
  );

  return (
    <>
      <PageContainer header={{ title: " ", breadcrumb: breadcrumb }} />
      <Card
        bordered={false}
        className="author"
        style={{ marginTop: 0 }}
        activeTabKey={activeKey}
        onTabChange={(key) => setActiveKey(key)}
      >
        <TrackComponent
          screenList={screenList}
          onUpdateScreens={updateStateConfig}
        />
      </Card>
    </>
  );
};
export default WorkflowContainer;
