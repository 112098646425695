import { App } from "antd";
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  InputNumber,
  Input,
  List,
  Typography,
} from "antd";
import QRCode from "qrcode.react";
import { generatePopCode } from "../../../services/api";
import { filterXSS } from "xss";
import PrintableCode from "./printable-popcodes";
import { getMainDomain, getSubDomain } from "../../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import "./generate.css";
import { useAuth } from "../../../hooks/auth-context";
interface evt {
  target: {
    name: string;
    value: string | null;
  };
}

const GeneratePopcode = (): JSX.Element => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [printUrls, setPrintUrls] = useState<any>([]);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [printableCode, setPrintableCode] = useState(false);
  const querySearch = useLocation().search;
  const [formData, setFormData] = useState({
    network: new URLSearchParams(querySearch).get("network") || getSubDomain(),
    batch: new URLSearchParams(querySearch).get("batch") || "x01",
    from: new URLSearchParams(querySearch).get("from") || "",
    to: new URLSearchParams(querySearch).get("to") || "",
  });
  const navigate = useNavigate();
  const { Title } = Typography;
  const { notification } = App.useApp();
  const { setCurrentUser } = useAuth();
  const handleChange = (evt: evt) => {
    const name = evt.target.name;
    setFormData({ ...formData, [name]: evt.target.value });
  };

  const onLabelBack = () => {
    setPrintableCode(false);
  };
  useEffect(()=>{
    userdata();
  },[]);
  const onBack = () => {
    setPrintUrls([]);
  };
  const userdata = async () => {
    const res = await setCurrentUser();
  };
  const onLabelPrint = () => {
    setPrintableCode(true);
  };

  const onSelected = (url: string) => {
    setSelectedUrl(url);
  };

  const onSubmit = async () => {
    if (!formData.network) {
      return notification.error({ message: "Please enter network name" });
    }
    if (!formData.from) {
      return notification.error({ message: "Please enter from" });
    }
    if (!formData.to) {
      return notification.error({ message: "Please enter to" });
    }
    if (Number(formData.from) > Number(formData.to)) {
      return notification.error({
        message:
          "Please enter valid number from and to. From value can not be grater then or equal of To value.",
      });
    }

    const payload = {
      from: formData.from,
      to: Number(formData.to),
      network: formData.network,
      domainExt: getMainDomain(),
      batch: formData.batch,
    };

    try {
      setButtonLoading(true);
      const printUrls = await generatePopCode({ payload });

      if (!Array.isArray(printUrls)) {
        setButtonLoading(false);
        return notification.error({
          message:
            "Something is wrong. please try again. Or contact to administrator",
        });
      }

      if (printUrls && printUrls.length === 0) {
        setButtonLoading(false);
        return notification.warning({
          message: "url not found. please check from and to value.",
        });
      }
      notification.success({ message: "Popcodes have been generated." });
      navigate({
        search:
          "?network=" +
          formData.network +
          "&batch=" +
          formData.batch +
          "&from=" +
          formData.from +
          "&to=" +
          formData.to,
      });

      setButtonLoading(false);
      setSelectedUrl(printUrls[0]);
      setPrintUrls(printUrls);
    } catch (err) {
      notification.error({
        message:
          "Something is wrong. please try again. Or contact to administrator",
      });
      setButtonLoading(false);
    }
  };

  return (
    <>
      {printableCode && (
        <PrintableCode
          network={formData.network}
          onBack={onLabelBack}
          printUrls={printUrls}
        />
      )}
      {!printableCode && (
        <Card bordered={false}>
          <Row style={{ marginBottom: 20 }}>
            <Col>
              <Title level={5}>Generate Pre-Printed Popcodes</Title>
            </Col>
          </Row>
          {printUrls.length === 0 && (
            <div className="gen_popc">
              <Row style={{ marginBottom: 10 }}>
                <Col md={4} style={{ textAlign: "center" }}>
                  Network
                </Col>
                <Col md={10}>
                  <Input
                    value={formData.network}
                    placeholder="Network Name"
                    name="network"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col md={4} style={{ textAlign: "center" }}>
                  Batch
                </Col>
                <Col md={10}>
                  <Input
                    value={formData.batch}
                    placeholder="Batch"
                    name="batch"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col md={4} style={{ textAlign: "center" }}>
                  Sequence
                </Col>
                <Col md={5}>
                  <InputNumber
                    value={formData.from}
                    style={{ width: "90%" }}
                    placeholder="From"
                    name="from"
                    onChange={(val) =>
                      handleChange({ target: { name: "from", value: val } })
                    }
                  />
                </Col>
                <Col md={5}>
                  <InputNumber
                    value={formData.to}
                    style={{ width: "100%" }}
                    placeholder="To"
                    name="to"
                    onChange={(val) =>
                      handleChange({ target: { name: "to", value: val } })
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col md={4} style={{ textAlign: "center" }} />
                <Col md={10}>
                  <Button loading={buttonLoading} onClick={onSubmit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {printUrls.length > 0 && (
            <div>
              <Row style={{ marginBottom: 20 }}>
                <Col md={12} style={{ marginRight: 30 }}>
                  <List
                    size="small"
                    bordered
                    dataSource={printUrls}
                    renderItem={(item: string) => {
                      const styles: Record<string, string> = {
                        cursor: "pointer",
                      };
                      if (item === selectedUrl) {
                        styles.background = "#1890FF";
                        styles.color = "#fff";
                      }
                      return (
                        <List.Item
                          style={{ ...styles }}
                          onClick={() => onSelected(item)}
                        >
                          <List.Item.Meta
                            title={
                              <div
                                style={{
                                  wordBreak: "break-all",
                                  color: `${
                                    item === selectedUrl ? "#fff" : ""
                                  }`,
                                }}
                              >
                                {filterXSS(item)}
                              </div>
                            }
                          />
                        </List.Item>
                      );
                    }}
                  />
                </Col>
                <Col md={10}>
                  <Title level={5} style={{ marginBottom: 10 }}>
                    QR Code
                  </Title>
                  {selectedUrl && (
                    <Row>
                      <Col md={24}>
                        <QRCode size={200} value={`${selectedUrl}`} />
                      </Col>
                      <Col md={24} style={{ marginTop: 10 }}>
                        <div
                          style={{
                            width: "220px",
                            wordBreak: "break-all",
                            fontSize: 12,
                          }}
                        >
                          {filterXSS(selectedUrl)}
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col md={10}>
                  <Button onClick={onBack}>Back</Button> &nbsp;
                  <Button onClick={onLabelPrint}>
                    Popcode Printable Label
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Card>
      )}
    </>
  );
};

export default (): JSX.Element => (
  <App>
    <GeneratePopcode />
  </App>
);
