/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import {
  Timeline,
  Button,
  Card,
  Spin,
  Descriptions,
  Col,
  Row,
  Alert,
} from "antd";
import _ from "lodash";
import moment from "moment";
import { Tooltip, TooltipOptions } from "./tool-tip";
import { useParams } from "react-router-dom";
import mapIcon from "../../../../assets/apple-maps-icon.png";
import PopcodePathModal from "./popcode-path-modal";
import Icon from "@ant-design/icons";
import { fetchEvents } from "services/popcodes";

const timelineStyles = {
  harvestCorn: { color: "green", icon: "right-circle-o" },
  grainElevator: { color: "red", icon: "left-circle-o" },
  merchandiser: { color: "red", icon: "scan" },
  dairyFarmer: { color: "blue", icon: "dropbox" },
  milkProducder: { color: "green" },
  iceCreamFactory: { color: "green" },
};
const getColor = (state: any) =>
  // @ts-ignore
  timelineStyles[state] && timelineStyles[state].color;
const getTimelineItemIcon = (state: any) => {
  // @ts-ignore

  const type = timelineStyles[state] && timelineStyles[state].icon;
  if (!type) return null;

  return (
    <Tooltip
      placement={TooltipOptions.placement.RIGHT_TOP}
      triggerOn={TooltipOptions.triggerOn.CLICK}
      contents={<div>Timeline item info here!</div>}
    >
      <Icon type={type} style={{ fontSize: 16 }} />
    </Tooltip>
  );
};

const PopcodeTimeLine = (): JSX.Element => {
  const { address } = useParams<{ address: string }>();
  const [popCode, setPopCode] = useState<any>();
  const [popcodesFetched, setPopcodesFetched] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const LoadPopcode = async () => {
      const data: any = {
        popcodeAddress: address,
      };
      const Events = await fetchEvents({ payload: data });
      setPopCode(Events);
      setPopcodesFetched(true);
      setLoading(false);
    };
    LoadPopcode();
  }, [address]);

  // For fut. ref.

  // const _renderPopcodeBubble = () => {
  //     console.log('BUBBLE')
  //     const bubbleDiameter = "100px";

  //     // TODO: deduplicate with graph colors
  //     const bubbleColor = "#03A9F4";

  //     const renderParts: any = [];
  //     popCode?.map((p:any)=>{
  //         if (_.get(p, [ "spot"])) {
  //             console.log("render")
  //           renderParts.push(_.get(p, ["spot"]));
  //           renderParts.push(<br key="0" />);
  //         }
  //     })
  //     return (
  //       <Tooltip
  //         placement={TooltipOptions.placement.RIGHT_TOP}
  //         triggerOn={TooltipOptions.triggerOn.CLICK}
  //         contents={null}
  //       >
  //         <div>
  //           <div
  //             style={{
  //               background: bubbleColor,
  //               width: bubbleDiameter,
  //               height: bubbleDiameter,
  //               borderRadius: "50%",
  //             }}
  //           />
  //           <div style={{ textAlign: "center" }}>{renderParts}</div>
  //         </div>
  //       </Tooltip>
  //     );
  //   }
  //   _renderPopcodeBubble();

  const [popcodePathModal, setPopcodePathModal] = useState(false);
  const [isSingle, setIsSingle] = useState(false);
  const [singleGpsLocation, setSingleGpsLocation] = useState(null);
  const [stepName, setStepName] = useState("");

  const onPopcodePathModal = (
    popcodePathModal: boolean,
    isSingle: boolean,
    singleGpsLocation: any,
    stepName: ""
  ) => {
    setPopcodePathModal(popcodePathModal);
    setIsSingle(isSingle);
    setSingleGpsLocation(singleGpsLocation);
    setStepName(stepName);
  };

  if (!popcodesFetched) {
    return <Spin />;
  }

  // if (!popCode) {
  //   return <div style={{ textAlign: "center" }}>Popcode not found!</div>;
  // }

  if (!popCode) {
    return (
      <Alert
        type="info"
        message="This popcode seems to handedoff from another chain"
      />
    );
  }

  const mapDetails: any[] = [];

  popCode?.sort((a: any, b: any) =>
    moment(b["timestamp"]).diff(moment(a["timestamp"]))
  );

  const timelineList = popCode?.map((transaction: any, index: number) => {
    const key = index + 1;
    const displayDate = new Date(transaction?.timestamp * 1).toLocaleString();
    let stepName: any = "";
    let displayAction = "";

    const tsEventData = transaction ? transaction : "";
    const gpsLocation = transaction?.location;

    if (_.has(tsEventData, ["spot"])) {
      stepName = tsEventData.spot;
      displayAction = tsEventData.spot;
    }

    if (gpsLocation) {
      const { latitude, longitude } = gpsLocation;
      mapDetails.push({
        coords: { lat: latitude, lng: longitude },
        stepName,
        displayAction,
      });
    }

    return (
      <Timeline
        key={key}
        items={[
          {
            dot: getTimelineItemIcon(stepName),
            color: getColor(stepName),
            children: (
              <Card title={displayAction} size="small">
                <Descriptions size="small" column={1}>
                  {displayDate !== "Invalid Date" && (
                    <Descriptions.Item span={1} label="Submitted On">
                      {displayDate}
                    </Descriptions.Item>
                  )}
                  {gpsLocation && (
                    <Descriptions.Item span={1} label="Location">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          onPopcodePathModal(true, true, gpsLocation, stepName)
                        }
                      >
                        <img height="25" src={mapIcon} alt="Map" />
                      </span>
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </Card>
            ),
          },
        ]}
      />
    );
  });
  const modalTitle = isSingle ? "Popcode Scan Location" : "Popcode Provenance";

  return (
    <Card loading={isLoading}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div>
          {/* @ts-ignore */}
          <Button onClick={onPopcodePathModal}>View Full Map</Button>

          {popcodePathModal && (
            <PopcodePathModal
              transactions={mapDetails}
              isSingle={isSingle}
              currentLocation={singleGpsLocation}
              modalTitle={modalTitle}
              title={stepName}
              closeModal={() => {
                onPopcodePathModal(false, false, null, "");
              }}
            />
          )}

          <Row>
            <Col md={12} style={{ marginTop: 35 }}>
              {timelineList}
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};
export default PopcodeTimeLine;
