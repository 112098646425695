import { useEffect, useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import "./index.css";
import { DataType, EditDataType } from "../ListUser";
import { Role } from "types";
import { getMyOrgInfo } from "services/api";
// import {
//   ATTESTER_DB_ROLE_NAME,
//   DLPC_SIGNATORY_DB_ROLE_NAME,
// } from "constants/global";
interface UserEditFormProps {
  open: boolean;
  roles: Role[];
  onCreate: (values: EditDataType) => void;
  onCancel: () => void;
  selectedUser?: DataType;
  // dlpcSignatories: DataType[];
  // AttesterSignatories: DataType[];
}
const UserEditForm = ({
  open,
  roles,
  onCreate,
  onCancel,
  selectedUser,
}: UserEditFormProps): any => {
  const [form] = Form.useForm();
  const [isProcessingSubmit, setIsProcessingSubmit] = useState(false);
  const [orgInfo, setOrgInfo] = useState<{ legalName?: string }>({});

  useEffect(() => {
    async function fetchOrgInfo() {
      const dataOrg1 = await getMyOrgInfo();
      const dataOrg = {
        ...dataOrg1,
        key: 1,
      };
      setOrgInfo(dataOrg);
    }
    fetchOrgInfo();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      const selectedUserMap = selectedUser.roles.filter(
        (item: any) => typeof item === "object"
      );
      form.setFieldsValue({
        name: selectedUser.name,
        email: selectedUser.email,
        roles: selectedUserMap.map((item) => item.id),
        userOrgId: selectedUser.userOrgId,
      });
    }
  }, [selectedUser]);
  if (!selectedUser) return null;
  return (
    <Modal
      key="Modal-key-unique-user-1"
      open={open}
      title="Edit User"
      okText="Update"
      cancelText="Cancel"
      onCancel={onCancel}
      maskClosable={false}
      onOk={() => {
        setIsProcessingSubmit(true);
        form
          .validateFields()
          .then((values) => {
            values = {
              ...values,
              id: selectedUser?.id,
              key: values.id,
            };
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          })
          .finally(() => {
            setIsProcessingSubmit(false);
          });
      }}
      confirmLoading={isProcessingSubmit}
    >
      <Form
        form={form}
        key="Form-user-key"
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          name: selectedUser.name,
          email: selectedUser.email,
          roles: selectedUser.roles.map((item) => item.id),
        }}
        className="user-form"
      >
        <Form.Item
          key="form-item-1-user-key"
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input key="input-field-key-prop" />
        </Form.Item>
        <Form.Item
          key="form-item-2-user-key"
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please input the email!" }]}
        >
          <Input key="Input-2-key-unique-prop" disabled />
        </Form.Item>
        <Form.Item
          key="form-item-3-user-key"
          name="userOrgId"
          label="User Org ID"
          rules={[{ message: "Please input the uid!" }]}
        >
          <Input key="Input-key-unique-prop" placeholder={orgInfo?.legalName} />
        </Form.Item>
        <Form.Item key="form-item-4-user-key" name="roles" label="Roles">
          <Select
            key="select-1-multiple-user-key"
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={roles.map(({ roleName, id }) => ({
              label: roleName,
              value: id,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default UserEditForm;
