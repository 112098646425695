/* eslint-disable @typescript-eslint/no-unused-vars */
import { App } from "antd";
import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import "./index.css";
import {
  Button,
  Col,
  Row,
  Space,
  Typography,
  Checkbox,
  Card,
  Tooltip,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useAuth } from "../../../../hooks/auth-context";
import useDocumentTitle from "../../../../hooks/use-document-title";
import { getECPairPriv, getAddress, generateMnemonic } from "skud-client-base";
import { initChainAccount } from "../../../../services/chain";
import { getTendermintPubKey } from "skud-client-base/lib/address";
import { BASE_HD_DLPC_PATH } from "constants/global";
import  { getCurrentUser} from "../../../../services/authentication";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Mnemonic = require("bitcore-mnemonic");
const { Paragraph } = Typography;

const { REACT_APP_CHAIN_PREFIX: chainPrefix = "sku" } = process.env;

const stringifyKey = (key: Buffer): string => key.toString("base64");

const New: React.FC = () => {
  useDocumentTitle("New Wallet");
  const navigate = useNavigate();
  const [mnemonic, setMnemonic] = useState<string>(generateMnemonic());
  const [checkbox, setCheckbox] = useState(false);
  // TO DO: this should ideally use useRef, not useState, but ref is likely not forwarded on the antd Button component
  const [isLoadingWallet, setIsLoadingWallet] = useState(false);
  const { notification } = App.useApp();
  const { setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userdatas,setuserdata] = useState(false);
  const privateKey = getECPairPriv(mnemonic);
  const tendermintPubkey = getTendermintPubKey(privateKey);
  const address = getAddress(mnemonic, chainPrefix);
  const bitcoreMnemonic = new Mnemonic(mnemonic);
  const hdPrivateKey = bitcoreMnemonic.toHDPrivateKey();
  const hdPath = `${BASE_HD_DLPC_PATH}`;
  const dlpcCounterpartyHdPrivateKey = hdPrivateKey.deriveChild(hdPath);
  const extendedPublicKey = dlpcCounterpartyHdPrivateKey.hdPublicKey;

  const handleCreateWallet = () => {
    if (!checkbox) {
      return notification.error({
        message:
          "Please check the checkbox 'I wrote down my key phrase in a secure location.'",
        duration: 4,
      });
    }
    (async () => {
      setIsLoadingWallet(true);
      await initChainAccount({
        address,
        publicKey: tendermintPubkey.value,
        save: true,
        extendedPublicKey: extendedPublicKey.xpubkey,
        device_type: "track-desktop",
      });
      await setCurrentUser();
      localStorage.setItem("mnemonic", mnemonic);
      setIsLoadingWallet(false);
      notification.success({
        message: "Your blockchain account has been created successfully",
      });
      navigate("/account/wallet");
    })();
  };
  const [copyText, setCopyText] = useState("");

  const handleCopyText = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCopyText(e.target.value);
  };

  const copyMnemonic = () => {
    copy(mnemonic);
    //  alert(`You have copied "${stringifyKey(publicKey.value)}"`);
  };

  const copyPublicKey = () => {
    copy(tendermintPubkey.value);
    //  alert(`You have copied "${stringifyKey(publicKey.value)}"`);
  };
  const copyPrivateKey = () => {
    copy(stringifyKey(privateKey));
    //  alert(`You have copied "${stringifyKey(publicKey.value)}"`);
  };

  const copyAddress = () => {
    copy(address);
    //  alert(`You have copied "${stringifyKey(publicKey.value)}"`);
  };
  const userdata = async () => {
    setLoading(false);
    const res = await setCurrentUser();
    setuserdata(true);
  };
  useEffect(()=>{
    userdata();
  },[]);


  return (
    <div className="main">
         {userdatas?
      <Card>
        <Row>
          <Col span={12}>
            <div className="preview">
              <div className="keyContainer">
                <Row>
                  {mnemonic.split(" ").map((word, index) => {
                    const key = `${word}-${index}`;
                    return (
                      <Col span={8} key={key}>
                        <Typography.Title level={5} className="key">
                          {index + 1}. {word}
                        </Typography.Title>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detailContainer">
              <Space size={"small"} direction={"vertical"}>
                <div style={{ display: "inline-flex" }}>
                  <Typography.Title level={4}>
                    This is your key phrase.
                  </Typography.Title>

                  <Button
                    onClick={() => setMnemonic(generateMnemonic())}
                    type={"default"}
                    className={"mar"}
                    disabled={mnemonic === ""}
                    style={{ marginTop: 25, marginLeft: 10 }}
                  >
                    Regenerate Menmonic
                  </Button>
                </div>
                <Typography.Text>
                  Use these twenty four words in a sequential order to access
                  your wallet.
                </Typography.Text>
                <div style={{ display: "inline-flex" }}>
                  <Paragraph style={{ width: "90%" }}>
                    <pre>{mnemonic}</pre>
                  </Paragraph>
                  <Tooltip title="Copy Mnemonic">
                    <Button
                      icon={<CopyOutlined />}
                      onClick={copyMnemonic}
                      style={{ marginTop: 15 }}
                    />
                  </Tooltip>
                </div>

                <Space
                  direction={"vertical"}
                  style={{ width: "100%", marginTop: 10 }}
                >
                  <div>
                    <span className="skc-label">Public Key</span>
                    <br />
                    <div style={{ display: "inline" }}>
                      <TextArea
                        rows={2}
                        value={tendermintPubkey.value}
                        style={{ width: "90%" }}
                        readOnly
                      />
                      <Tooltip title="Copy Public key">
                        <Button
                          icon={<CopyOutlined />}
                          onClick={copyPublicKey}
                          style={{ marginBottom: 20 }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div>
                    <span className="skc-label">Private Key</span>
                    <br />
                    <div style={{ display: "inline" }}>
                      <TextArea
                        rows={2}
                        value={stringifyKey(privateKey)}
                        style={{ width: "90%" }}
                        readOnly
                      />
                      <Tooltip title="Copy Private key">
                        <Button
                          icon={<CopyOutlined />}
                          onClick={copyPrivateKey}
                          style={{ marginBottom: 20 }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div>
                    <span className="skc-label">Extended Public Key</span>
                    <br />
                    <div style={{ display: "inline" }}>
                      <TextArea
                        rows={2}
                        value={extendedPublicKey.xpubkey}
                        style={{ width: "90%" }}
                        readOnly
                      />
                      <Tooltip title="Copy Public key">
                        <Button
                          icon={<CopyOutlined />}
                          onClick={copyPublicKey}
                          style={{ marginBottom: 20 }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div>
                    <span className="skc-label">Account Address</span>
                    <TextArea
                      rows={2}
                      value={address}
                      style={{ width: "90%" }}
                      readOnly
                    />
                    <Tooltip title="Copy Address">
                      <Button
                        icon={<CopyOutlined />}
                        onClick={copyAddress}
                        style={{ marginBottom: 20 }}
                      />
                    </Tooltip>
                  </div>
                </Space>
                <Typography.Text />
                <Typography.Title
                  level={3}
                  type="warning"
                  style={{ marginTop: 40 }}
                >
                  Attention
                </Typography.Title>
                <Typography>
                  Store this key phrase in a secure location. Anyone with this
                  key phrase can access your wallet. There is no way to recover
                  lost key phrases.
                </Typography>
                <Checkbox onChange={() => setCheckbox(!checkbox)}>
                  I wrote down my key phrase in a secure location.
                </Checkbox>
                <Button
                  onClick={handleCreateWallet}
                  type="primary"
                  className="mar"
                  disabled={mnemonic === ""}
                  loading={isLoadingWallet}
                >
                  Submit
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Card>
          :null}
    </div>
  );
};

export default (): JSX.Element => (
  <App>
    <New />
  </App>
);
