import { Alert, Card, Col, Descriptions, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";

import { Link, useParams } from "react-router-dom";
import { PageContainer } from "@ant-design/pro-layout";
import { Breadcrumb } from "antd";
import { fetchPopcodeByAddress } from "services/popcodes";
import PopcodeEventGraph from "./popcode-event-graph";
import PopcodeTimeLine from "./events/popcode-timeline";
import useDocumentTitle from "hooks/use-document-title";
import { getMyOrgInfo } from "services/api";

const Viewer = (): JSX.Element => {
  const { address } = useParams<{ address: string }>();
  const [popCode, setPopCode] = useState<any>();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [uid, setuid] = useState<any>();

  useDocumentTitle("Popcodes");

  useEffect(() => {
    const fetchPopcode = async () => {
      setLoading(true);
      try {
        const response = await fetchPopcodeByAddress({
          address: address || "",
        });
        const parse_response = JSON.parse(response.value);
        const timestamp=parse_response.timestamp;
        const asset = parse_response.asset;
        const eventdata = JSON.parse(parse_response.eventData);
        const dataOrg = await getMyOrgInfo(); 
        setuid(dataOrg.legalName);

        if (response) {
          setPopCode({ ...response, eventdata, asset,timestamp });
          setLoading(false);
        }
      } catch (err) {
        setError(err as Error);
      }
      setLoading(false);
    };

    fetchPopcode();
  }, [address]);

  const [tab, setTab] = useState("provenance");

  if (error) {
    return (
      <Card title="Something went wrong">
        <Alert message={(error as Error).message} />
      </Card>
    );
  }

  const breadcrumb = (
    <Breadcrumb
      items={[
        {
          title: "Track",
        },
        {
          title: <Link to="/popcodes">Popcodes</Link>,
        },
        {
          title: "Popcode",
        },
        {
          title: `${address}`,
        },
      ]}
    />
  );

  return (
    <>
      <PageContainer header={{ title: " ", breadcrumb: breadcrumb }} />
      <Card title="Popcode" loading={isLoading} className="pop-qr">
        <Card>
          <Row className="pop_can">
            <Col md={4}>
              <QRCode
                value={popCode?.eventdata?.Metadata?.popcodeName}
                size={128}
              />
            </Col>

            <Col md={18}>
              <Descriptions size="small">
                <Descriptions.Item label="URI">
                  {popCode?.eventdata?.Metadata?.popcodeName}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions size="small">
                <Descriptions.Item label="Address">
                  {popCode?.id}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions size="small">
                <Descriptions.Item label="Asset">
                  {popCode?.asset?.Name}
                  {/* {popCode?.eventdata?.Data?.Asset?.Name} */}
                </Descriptions.Item>
                <Descriptions.Item label="Amount">
                  {popCode?.asset?.Amount
                    ? `${parseFloat(String(popCode?.asset?.Amount).replace(/[^\d.]/g, ""))} ${
                      popCode?.asset?.UnitOfMeasure
                    }`
                    : ""}
                </Descriptions.Item>
                <Descriptions.Item label="Unit Of Measure">
                  {popCode?.asset?.UnitOfMeasure}
                </Descriptions.Item>

                <Descriptions.Item label="Status">
                  {popCode?.eventdata?.Metadata?.spot}
                </Descriptions.Item>

                <Descriptions.Item label="Last Updated">
                {moment(
                    popCode?.updatedAt || popCode?.timestamp
                  ).fromNow()}
                </Descriptions.Item>
                <Descriptions.Item label="User ID">{popCode?.eventdata?.Data?.uid || uid}</Descriptions.Item>
                {/* uid is Organization's legal Name */}
              </Descriptions>
            </Col>
          </Row>
        </Card>

        <Card
        className="pop_tabbing"
          bordered={false}
          tabList={[
            {
              key: "provenance",
              tab: "Provenance",
            },
            {
              key: "events",
              tab: "Event Timeline",
            },
          ]}
          onTabChange={setTab}
          activeTabKey={tab}
        >
          {tab === "provenance" && <PopcodeEventGraph />}
          {tab === "events" && <PopcodeTimeLine />}
        </Card>
      </Card>
    </>
  );
};

export default Viewer;
