/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { App } from "antd";
import { Form, Input, Button } from "antd";
import "./index.css";
import { useAuth } from "../../hooks/auth-context";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"; //useParams
import useDocumentTitle from "../../hooks/use-document-title";
// import { IResetPasswordInfo } from "../../types";
import logoIcon from "../../assets/skublu.png";

const UserVerify = () => {
  const { notification } = App.useApp();
  const auth = useAuth();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // const [loginAvailable, setLoginAvailable] = useState(true);
  // const recaptchaRef = useRef<any>(null);

  useDocumentTitle("Initialize Your Account");

  const resetToken = searchParams.get("resetToken");
  const verifyToken = searchParams.get("verifyToken");
  if (auth.user && auth.user.email) {
    return <Navigate to="/" />;
  }
  if (!resetToken || !verifyToken) {
    notification.error({
      message:
        "The link was invalid. You have been redirected to the home page.",
    });
    return <Navigate to="/login" replace />;
  }

  const handleInitAccount = async (values: any) => {
    try {
      await auth.verifyAccount(verifyToken);
      await auth.changeUserPassword({
        token: resetToken,
        password: values.password,
      });
      notification.success({
        message: "Your account has been initialized successfully",
      });
    } catch (err) {
      notification.error({
        message: "An error occurred when initializing your account.",
      });
    } finally {
      navigate("/login");
    }
  };

  return (
    <Form
      name="normal_login"
      form={form}
      className="change-password-form"
      initialValues={{
        remember: true,
      }}
      onFinish={handleInitAccount}
      autoComplete="off"
      layout="vertical"
    >
      <img
        id="logo"
        alt="logo"
        src={logoIcon}
        onClick={() => navigate("/", { replace: true })}
      />
      <span id="title">Initialize your Account</span>
      <Form.Item
        name="password"
        label="New Password"
        rules={[
          {
            required: true,
            message: "Please input your password",
          },
        ]}
      >
        <Input type="password" placeholder="Password" />
      </Form.Item>

      <Form.Item
        name="confirm_password"
        label="Confirm Password"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: "Please confirm your password",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match.")
              );
            },
          }),
        ]}
      >
        <Input type="password" placeholder="Confirm" />
      </Form.Item>

      {/* <div style={{ marginTop: "20px" }}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={handleRecaptchaChange}
          onExpired={handleRecaptchaExpired}
        />
      </div> */}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="change-password-form-button"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default (): JSX.Element => (
  <App>
    <UserVerify />
  </App>
);
