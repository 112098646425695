import { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { Layout, Dropdown, Space, MenuProps } from "antd";
import PageBreadcrumb from "../page-breadcrumb";
import SideMenu from "../side-menu";
import useMetaVersion from "hooks/use-meta-version";
import { useAuth } from "hooks/auth-context";
import "./index.css";

const { Header, Content, Sider, Footer } = Layout;

const PageLayout = (): JSX.Element => {
  useMetaVersion("1.0");
  const { user, logout } = useAuth();
  const [collapsed, setCollapsed] = useState(false);

  const handleLogout = async () => {
    await logout();
    window.location.replace("/login");
  };
  // const trackPageRedriect = () => {
  //   window.location.href =
  //     window.location.protocol + "//" + window.location.hostname;
  //   console.log(window.location.protocol);
  // };

  const userActionMenuProps: MenuProps = {
    items: [
      {
        key: "logout",
        label: "Log out",
        icon: <LogoutOutlined />,
        onClick: handleLogout,
      },
    ],
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width="210px"
      >
        <div className="logo">
          <Link to="/">
            <img
              src={
                collapsed
                  ? `${process.env.PUBLIC_URL}/icon-white.png`
                  : `${process.env.PUBLIC_URL}/logo-white.png`
              }
              alt="Logo"
              height="50"
              style={
                collapsed
                  ? { margin: "23px" }
                  : { margin: "23px 0px 23px 27px" }
              }
              onClick={() => <Link to="/" />}
            />
          </Link>
        </div>
        <SideMenu />
      </Sider>

      <Layout className="site-layout">
        <Header className="site-layout-header">
          <Dropdown menu={userActionMenuProps}>
            <Space>
              {user?.email}
              <DownOutlined />
            </Space>
          </Dropdown>
        </Header>
        <Content style={{ padding: 8, margin: "0 16px" }}>
          <PageBreadcrumb />
          <div style={{ padding: 24, minHeight: 360 }}>
            <Outlet />
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default PageLayout;
