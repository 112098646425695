/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from "react";
import { Row, Col, Card } from "antd";
import TrackSteps from "./track-steps";
import TrackScreens from "./track-screens";
import DisplayTab from "./display-tab";
interface StepsComponentProps {
  screenList: any[];
  onUpdateScreens: (screens: any[]) => void;
}
const StepsComponent: React.FC<StepsComponentProps> = ({
  screenList,
  onUpdateScreens,
}) => {
  const [activeKey, setActiveKey] = useState<string>("display");
  const [, setSelectedMobileScreen] = useState<string>("");
  const onTabChange = (key: string, type: string) => {
    if (type === "activeKey") {
      setActiveKey(key);
    }
  };
  const onMobileScreenClick = (screenName: any) => {
    onTabChange("screens", "activeKey");
    setSelectedMobileScreen(screenName);
  };
  const tabMenu = [
    {
      key: "display",
      tab: "Preferences",
    },
    {
      key: "steps",
      tab: "Steps",
    },
    {
      key: "screens",
      tab: "Screens",
    },
  ];
  const tabs: { [key: string]: JSX.Element } = {
    display: <DisplayTab />,
    steps: (
      <Row>
        <Col md={24} sm={24} xs={24} style={{ borderRight: `${1}px` }}>
          <TrackSteps
            // @ts-ignore
            onUpdateScreens={onUpdateScreens}
            onMobileScreen={onMobileScreenClick}
          />
        </Col>
      </Row>
    ),
    // @ts-ignore
    screens: <TrackScreens screenList={screenList || []} defaultScreen={0} />,
  };
  return (
    <Card
      className="track_main_wf"
      bordered={false}
      style={{ marginTop: 0 }}
      tabList={tabMenu}
      activeTabKey={activeKey}
      onTabChange={(key) => {
        onTabChange(key, "activeKey");
      }}
    >
     {tabs[activeKey]}
    </Card>
  );
};
export default StepsComponent;
