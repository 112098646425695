/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Spin, Button, message, Card } from "antd";
import { HotTable } from "@handsontable/react";
import _ from "lodash";
import {
  UpdateTracksteps,
  getMyOrgInfo,
  saveTrackSteps,
} from "../../../services/api";
//import RoleScreenModal from "./components/role-screen-modal";
import uniqueId from "../../../utils/unique-id";
import mobileImage from "../../../assets/frame.png";
import "./track-steps.css";
import { filterXSS } from "xss";
import "handsontable/dist/handsontable.full.min.css";
import { registerAllModules } from "handsontable/registry";
import { getTrackSteps } from "services/projects";
// register Handsontable's modules
registerAllModules();

const { Meta } = Card;

class TrackSteps extends Component<any, any> {
  hotTable: any = null;
  constructor(props: any) {
    super(props);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: any = this;

    this.state = {
      selectedRowScreen: null,
      isLoading: true,
      buttonLoading: false,
      settings: {
        data: [],
        height: 400,
        rowHeaders: true,
        manualRowMove: true,
        minRows: 5,
        manualColumnMove: true,
        contextMenu: true,
        stretchH: "all",
        outsideClickDeselects: false,
        currentRowClassName: "currentRowTrack",
        columns: [
          {
            data: "spot",
          },
          {
            data: "title",
          },
          {
            data: "screen",
          },
        ],
        colWidths: [90, 90, 120],
        colHeaders: ["Spot", "Title", "Screen"],
        afterOnCellMouseDown(_ht: any, coords: { row: any; col: any }) {
          const { row, col } = coords;
          const data = this.getDataAtRow(row);
          if (row === -1 || !data[0] || col !== 2) {
            return false;
          }
          const hdScreenData = data;
          self.setState({
            hdScreenData,
            hdSelectedScreen: true,
          });
          return true;
        },
      },
    };
  }

  async UNSAFE_componentWillMount() {
    const { settings } = this.state;
    const { router } = this.props;
    const { params } = router;
    const workflowID = params && params.workflowId;
    try {
      let config: any = { isLoading: false };
      const [trackStep, systemOrg] = await Promise.all([
        getTrackSteps({ workflowID: workflowID }),
        getMyOrgInfo(),
      ]);
      const stepsData =
        (trackStep && trackStep?.docs.length && trackStep.docs[0]) || {};
      const track_value = stepsData.value ? JSON.parse(stepsData.value) : {};
      const steps =
        (track_value && track_value.track && track_value.track.steps) || [];
      const systemData = systemOrg || {};
      config = {
        ...stepsData,
        org: systemData.legalName,
      };

      config.settings = {
        ...settings,
        data: steps || [],
      };
      config.isLoading = false;
      this.setState(config);
    } catch (errors) {
      this.setState({
        isLoading: false,
      });
    }
  }

  getData = () => {
    if (this.hotTable && this.hotTable.hotInstance) {
      const keyName = ["spot", "title", "screen"];
      const data = this.hotTable.hotInstance
        .getData()
        .filter((p: any[]) => p[0] || p[1]);
      const newData = data.map((step: any[]) => {
        const newConfig = {};
        step.forEach((p: string, i: string | number) => {
          // @ts-ignore
          newConfig[keyName[i]] = filterXSS(p);
        });
        return newConfig;
      });
      return newData;
    }
    return [];
  };

  updateScreenData = (data: any) => {
    const { selectedRowScreen, settings } = this.state;

    const settingData = this.getData();
    if (settingData[selectedRowScreen]) {
      settingData[selectedRowScreen].screens = data;
      this.setState({
        isRoleModal: false,
        settings: {
          ...settings,
          data: settingData,
        },
      });
    }
  };

  onAddSteps = async () => {
    try {
      const { onUpdateScreens, router } = this.props;
      const { params } = router;
      const projectId = params && params.id;
      const workflowId = params && params.workflowId;

      if (this.hotTable && this.hotTable.hotInstance) {
        const steps = this.getData();
        this.setState({ buttonLoading: true });
        let trackStep;
        try {
          trackStep = await getTrackSteps({
            workflowID: workflowId,
          });
          const trackStepResult = trackStep?.docs.find(
            (s: any) => s.workflowID.id === workflowId
          );
          const track_value = JSON.parse(trackStepResult.value);
          const screens =
            (track_value && track_value.track && track_value.track.screens) ||
            [];
          const payload = {
            id: trackStepResult && trackStepResult?.id,
            projectId,
            workflowId,
            track: {
              screens,
              steps,
            },
          };
          await UpdateTracksteps({
            id: payload?.id,
            value: JSON.stringify(payload),
            workflowID: workflowId,
          });
        } catch (error) {
          const payload = {
            id: uniqueId(),
            projectId,
            workflowId,
            track: {
              screens: [],
              steps,
            },
          };
          await saveTrackSteps({
            id: payload?.id,
            value: JSON.stringify(payload),
            workflowID: workflowId,
          });
        }

        let screenList: any[] = [];

        (steps || []).forEach((p: { screen: any }) => {
          const screenItems = p.screen && { screen: p.screen };
          screenList = screenList.concat(screenItems);
        });
        screenList = _.uniqBy(
          screenList.filter((a) => a),
          "screen"
        );

        this.setState({
          buttonLoading: false,
        });
        onUpdateScreens({ screenList });
        message.success("updated successfully");
      }
    } catch (errors) {
      this.setState({
        buttonLoading: false,
      });
    }
  };

  onHandleModal = (props: any) => {
    this.setState({
      isRoleModal: props,
    });
  };

  onSelectMobileScreen = (screen: any) => {
    this.props.onMobileScreen(screen);
  };

  render() {
    const {
      hdScreenData,
      hdSelectedScreen,
      // isRoleModal,
      buttonLoading,
      isLoading,
      settings,
    } = this.state;

    return (
      <div>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            {/* eslint-disable-next-line no-return-assign */}
            <Row className="handsontable track_steps">
              <Col sm={24} xs={24} md={14}>
                {/* @ts-ignore */}
                <HotTable
                  licenseKey="non-commercial-and-evaluation"
                  ref={(table) => (this.hotTable = table)}
                  id="hot"
                  settings={settings}
                />
              </Col>
              <Col md={1} />

              {hdSelectedScreen && (
                <Col sm={24} xs={24} md={9}>
                  <Card
                    title={"Mobile Screen"}
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={mobileImage}
                        height={200}
                        onClick={() =>
                          this.onSelectMobileScreen(
                            hdScreenData && hdScreenData[2]
                          )
                        }
                      />
                    }
                  >
                    <Meta title={filterXSS(hdScreenData && hdScreenData[2])} />
                  </Card>
                </Col>
              )}
            </Row>
            <Row gutter={24}>
              <Col sm={24} xs={24} style={{ marginTop: 24 }}>
                <Button
                  loading={buttonLoading}
                  type="primary"
                  onClick={this.onAddSteps}
                >
                  Save Step
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {/* {isRoleModal && (
          <RoleScreenModal
            isRoleModal={isRoleModal}
            data={Object.assign([], screenData)}
            closeModal={this.onHandleModal}
            updateScreenData={this.updateScreenData}
          />
        )} */}
      </div>
    );
  }
}

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: JSX.IntrinsicAttributes) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
export default withRouter(TrackSteps);
