import { App } from "antd";
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
} from "antd";
import type { Project } from "../../../types";
import type { ProColumns } from "@ant-design/pro-table";
import ProTable from "@ant-design/pro-table";
import { filterXSS } from "xss";
import "./projects.css";
import { deleteProjects, getProjectListDropdown } from "services/api";
import uniqueId from "utils/unique-id";
import { Link } from "react-router-dom";
import {
  copyExistingProject,
  getPaginatedProjects,
} from "../../../services/projects";

const { TextArea } = Input;
const Option = Select.Option;
const OpsBuild = ["Trade steps", "Track flows"];
// const opsBuild = ['Trade steps', 'Track flows'];
const preventXss = (items = [] as string[]) => items.map((s) => filterXSS(s));

const Projects: React.FC = () => {
  const { notification } = App.useApp();
  const [isOpenModal, setOpen] = useState(false);
  const [list, setList] = useState<Project[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [name, setName] = useState<string>();
  const [summary, setSummary] = useState<string>();
  const [selectedProjectid, setSelectedProjectid] = useState<string>("");
  const [type, setType] = useState<string[]>([]);
  const [buildTypes, setBuildTypes] = useState<string[]>([]);
  const [projectListDropdown, setProjectListDropdown] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageLimit] = useState(10);
  const [isLoading, setLoading] = useState(false);

  const getData = async (page: Number) => {
    setLoading(true);
    const { data }= await getPaginatedProjects(page, pageLimit);
    const dropdownProjectList = await getProjectListDropdown();
    setProjectListDropdown(dropdownProjectList);
    if (data?.docs?.length > 0) {
      setTotal(data?.totalDocs);
      //const allProjects = await getProjects();
      setList(data?.docs);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  if (isLoading) {
    //console.log(isLoading);
    return (
      <div style={{ textAlign: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  const isNameExisted = (e: string) => {
    return list && list.find((p) => p.name === e);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteProject = async (projectId: string) => {
    try {
      await deleteProjects({ collName: "projects", id: projectId });
      const rows = (list || []).filter((p) => p.id !== projectId);
      setList(rows);
      notification.success({
        message: "Project has been deleted successfully!",
      });
      window.location.reload();
    } catch (error) {
      notification.error({
        message: "Something went wrong. Please try again.",
      });
    }
  };

  const onCreateNewProject = async () => {
    if (!name) {
      return notification.error({ message: "please enter name" });
    }

    if (isNameExisted(filterXSS(name))) {
      return notification.error({ message: `${name} already exists.` });
    }
    if (!selectedProjectid || selectedProjectid) {
      if (!type || type.length === 0) {
        return notification.error({
          message: "please select at least one menu",
        });
      }
      if (type.includes("Ops build") && !buildTypes.length) {
        return notification.error({
          message: "please select at least one ops build.",
        });
      }
    }
    setButtonLoading(true);

    // if (selectedProjectid) {
    //   const proj = list.find((p) => p.id === selectedProjectid);
    //   if (proj) {
    //     delete proj._id;
    //     delete proj._rev;
    //     delete proj._key;
    //     delete proj.parties;
    //   }
    // }
    try {
      const proj1 = projectListDropdown.find((p) => p === selectedProjectid);
      const id = uniqueId();
      await copyExistingProject({
        id,
        buildTypes: preventXss(buildTypes),
        name: filterXSS(name as string),
        copyFrom: proj1 || "",
        summary: filterXSS(summary || ""),
        type: type.toString(),
      });
      notification.success({ message: "Successfully created project" });
     await getData(currentPage);
      // window.location.reload();
    } catch (er) {
      setButtonLoading(false);
      return notification.error({ message: "Error in save project" });
    }

    resetFields();
    setButtonLoading(false);
    setOpen(false);
    //return notification.success({message:'project added successfully!'});
    return;
  };

  const projectColumns: ProColumns<Project>[] = [
    {
      title: "Project Name",
      dataIndex: "name",
      render: (text: any, record: Project) => {
        return (
          <span>
            <Link to={`/projects/${record.id}/workflows`}>{text}</Link>
          </span>
        );
      },
    },
    {
      title: "Summary",
      dataIndex: "summary",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "About",
      render: (_: any, record: Project) => {
        return (
          <span>
            <Link to={`/projects/${record.id}/about`}>About</Link>
          </span>
        );
      },
    },
    {
      title: "Action",
      render: (text: any, record: Project) => {
        return (
          <Popconfirm
            title={"Are you sure?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => deleteProject(record?.id as string)}
          >
            <Button className="ml-2" type="default">
              Delete
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const resetFields = () => {
    setName("");
    setSummary("");
    setSelectedProjectid("");
    setType([]);
    setBuildTypes([]);
  };

  return (
    <>
      <ProTable<Project>
        className="my-pagination track_pro"
        headerTitle={""}
        rowKey="id"
        options={false}
        search={false}
        toolBarRender={() => [
          <Button
            type="primary"
            key="new_project"
            onClick={() => setOpen(true)}
            className="pull-right"
          >
            New Project
          </Button>,
        ]}
        dataSource={list}
        //request={() => getProjects()}
        columns={projectColumns}
        pagination={{
          pageSize: 10,
          total: total,
          current: currentPage,
          onChange: (page) => {
            setcurrentPage(page);
            getData(page);
          },
        }}
      />
      <Modal
        title="Project"
        open={isOpenModal}
        onOk={onCreateNewProject}
        onCancel={() => setOpen(false)}
        footer={[
          <Button
            key="back"
            onClick={() => {
              resetFields();
              setOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="new_project"
            type="primary"
            loading={buttonLoading}
            onClick={onCreateNewProject}
          >
            Create New Project
          </Button>,
        ]}
      >
        <br />
        <Row>
          <Col xs={8} style={{ marginBottom: 10 }}>
            Name
          </Col>
          <Col xs={12} style={{ marginBottom: 10 }}>
            <Input
              placeholder="Project Name"
              name="name"
              value={name || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }} className="track_sum">
          <Col md={8} style={{ textAlign: "left" }}>
            Summary
          </Col>
          <Col md={12}>
            <TextArea
              placeholder="Project Summary"
              name="summary"
              value={summary || ""}
              onChange={(e) => setSummary(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          {list.length > 0 ? (
            <Col xs={8} style={{ marginBottom: 10 }}>
              Copy From Existing Project
            </Col>
          ) : null}
          {list.length > 0 ? (
            <Col xs={12} style={{ marginBottom: 10 }}>
              <Select
                style={{ width: "100%" }}
                value={selectedProjectid}
                onChange={(val) => {
                  setSelectedProjectid(val);
                }}
              >
                <Option value="">Select</Option>
                {projectListDropdown.map((p) => (
                  <Option value={p} key={p}>
                    {filterXSS(p || "")}
                  </Option>
                ))}
              </Select>
            </Col>
          ) : null}
        </Row>
        <Row>
          <Col xs={8} style={{ marginBottom: 10 }}>
            &nbsp;
          </Col>
          <Col xs={12} style={{ marginBottom: 10 }}>
            <Checkbox.Group
              value={type}
              onChange={(v) => setType(v as string[])}
            >
              <Checkbox value="Ops build">Ops Build</Checkbox>
              {(type || []).includes("Ops build") && (
                <Row style={{ marginLeft: 20, paddingTop: 30 }}>
                  <Col xs={24} style={{ marginBottom: 20 }}>
                    <Checkbox.Group
                      value={buildTypes}
                      onChange={(v) => setBuildTypes(v as string[])}
                    >
                      {OpsBuild.map((s) => (
                        <React.Fragment key={s}>
                          <Checkbox value={s}>{filterXSS(s)}</Checkbox>
                          <br />
                          <br />
                        </React.Fragment>
                      ))}
                    </Checkbox.Group>
                  </Col>
                </Row>
              )}
            </Checkbox.Group>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default (): JSX.Element => (
  <App>
    <Projects />
  </App>
);
