import axios from "axios";
import { getOrganization } from "../utils/utils";
import { LOCAL_API_URL } from "../constants/global";
import qs from "qs";
import AxiosInstance from "./axiosinstance";

export const fetchPopcodesByWorkflow = async ({
  workflowId,
  asset,
  status,
  userOrgId,
  address,
  offset = 0,
  limit = 10,
}: {
  workflowId: string;
  address: string;
  status: string;
  asset: string;
  userOrgId: string;
  offset?: number;
  limit?: number;
}): Promise<{ data: any[]; success: boolean; total: number }> => {
  const token = localStorage.getItem("token");
  const res = await axios.post<{
    data: any[];
    success: boolean;
    total: number;
  }>(
    `${LOCAL_API_URL}/popcodes`,
    {
      params: { workflowId: workflowId, status, address, asset, userOrgId },
      offset: offset * 10,
      limit,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};

export const getAllPaginatedData = async (
  page: Number,
  limit: Number,
  { collName }: { collName: string }
): Promise<any> => {
  const stringifiedQuery = qs.stringify(
    {
      page: page,
      limit: limit,
    },
    { addQueryPrefix: true }
  );
  const token = localStorage.getItem("token");
  const res = await axios.get(
    `${LOCAL_API_URL}/${collName}${stringifiedQuery}`,
    {
      headers: {
        "Content-Type": "application/json",
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );

  return res.data;
};

export const fetchPopcodeByAddress = async ({
  address,
}: {
  address: string;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await axios.get<any>(`${LOCAL_API_URL}/popcodes/${address}`, {
    headers: {
      "Content-Type": "application/json",
      "x-tenant": getOrganization(),
      Authorization: `JWT ${token}`,
    },
  });
  return res.data;
};

export const fetchPopcodeEvents = async (
  offset: number,
  limit: number
): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await axios.get<any>(
    `${LOCAL_API_URL}/track/reports/popcodes/events?offset=${offset}&limit=${limit}`,
    {
      headers: {
        "Content-Type": "application/json",
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};

export const fetchEvents = async ({
  payload,
}: {
  payload: any;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.post<any>(
    `${LOCAL_API_URL}/track/popcode-events`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};

export const getAssets = async (): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.get<any>(`${LOCAL_API_URL}/track/assetlists`, {
    headers: {
      "Content-Type": "application/json",
      "x-tenant": getOrganization(),
      Authorization: `JWT ${token}`,
    },
  });
  return res.data;
};

export const fetchEventsbycol = async ({
  collName,
  payload,
}: {
  collName: string;
  payload: any;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.post<any>(
    `${LOCAL_API_URL}/track/${collName}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};

export const getPaginatedpopcodelists = async (
  page: Number,
  limit: Number,
  { payload, collName }: { payload: any; collName: string }
): Promise<any> => {
  const stringifiedQuery = qs.stringify(
    {
      page: page,
      limit: limit,
    },
    { addQueryPrefix: true }
  );
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.post(
    `${LOCAL_API_URL}/track/${collName}${stringifiedQuery}`,
    payload,
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};

export const fetchRecentActivity = async (): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.get<any>(`${LOCAL_API_URL}/track/recent-popcodes`, {
    headers: {
      "x-tenant": getOrganization(),
      Authorization: `JWT ${token}`,
    },
  });
  return res.data;
};
export const PopcodeGraph = async ({
  payload,
}: {
  payload: any;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.post<any>(
    `${LOCAL_API_URL}/track/popcode-address/graph`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};

export const Filesdisplay = async ({
  address,
}: {
  address: string;
}): Promise<any> => {
  try{
    const token = localStorage.getItem("token");
    const res = await axios.get<any>(`${LOCAL_API_URL}/files/${address}`, {
      headers: {
        "Content-Type": "application/json",
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    });
    return res.data;
  }catch(error){
    return([]);
  }
 
};

export const FileDownload = async ({
  payload,
}: {
  payload: any;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.post<any>(
    `${LOCAL_API_URL}/track/file/download`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
      responseType: "arraybuffer",
    }
  );
  return res;
};
