import { createContext, useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import authService, {
  IUserAuthInfo,
  getCurrentUser,
} from "../services/authentication";
import {
  CurrentUser,
  IChangePasswordInfo,
  IResetPasswordInfo,
} from "../types";
import { notification } from "antd";


interface AuthContextType {
  user: CurrentUser;
  // dlpc: CurrentDlpc;
  ethWalletAddress: string;
  ChangeEthWalletAddress: (address: string) => Promise<void>;
  setCurrentUser: () => Promise<void>;
  login: (email: string, password: string) => Promise<IUserAuthInfo>;
  sendResetRequest: (email: string) => any;
  verifyResetToken: (token: string) => any;
  // setCurrentDlpc: (data: CurrentDlpc) => any;
  resetPassword: (data: IChangePasswordInfo) => any;
  logout: () => void;
  verifyAccount: (token: string) => any;
  refreshToken: () => void;
  sendRequestAccount: (data: any) => any;
  getUserById: (id: string) => any;
  changeUserPassword: (data: IResetPasswordInfo) => any;
}

/**
 * Utilizing `Context` to pass values related to user auth status
 * through the components tree
 */
export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

/**
 * Provider function that'll pass the user’s auth 'status' to the tree below
 * `children` - refers to the children components
 */
export function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  // const [isGettingUser, setIsGettingUser] = useState(true);
  const [user, setUser] = useState<CurrentUser>({} as CurrentUser);
  // const [dlpc, setDlpc] = useState<CurrentDlpc>({} as CurrentDlpc);
  const [ethWalletAddress, setEthWalletAddress] = useState<string>("");

  const refreshToken = async () => {
    const res = await authService.refreshToken();
    if (res.token) {
      authService.setAccessToken(res.token);
    }
  };

  const login = async (email: string, password: string) => {
    const res = await authService.login(email, password);
    if (res.token) {
      authService.setAccessToken(res.token);
      await setCurrentUser();
      localStorage.setItem("refreshToken", res.refreshToken || "");
    }
    return res;
  };

  const sendResetRequest = async (email: string) => {
    const res = await authService.sendResetRequest({ email: email });
    return res;
  };

  const verifyResetToken = async (token: string) => {
    const res = await authService.verifyResetToken(token);
    return res;
  };

  const resetPassword = async (data: IChangePasswordInfo) => {
    const res = await authService.resetPassword(data);
    return res;
  };

  const logout = async () => {
    const res = await authService.logout();
    window.localStorage.clear();
    return res;
  };

  const setCurrentUser = async () => {
    const userData = await getCurrentUser();
    if(userData?.user == null){
      notification.error({
        message:
          "Session was expired, Please login again",
      });
      localStorage.removeItem("token");
    }
    setUser(userData?.user);
  };

  // const setCurrentDlpc = async (dlpc: any) => {
  //   setDlpc(dlpc);
  // };

  const sendRequestAccount = async (data: any) => {
    const res = await authService.sendRequestAccount(data);
    return res;
  };

  const getUserById = async (id: string) => {
    const res = await authService.getUserById(id);
    return res;
  };

  const verifyAccount = async (token: string) => {
    const res = await authService.verifyAccount(token);
    return res;
  };

  const changeUserPassword = async (data: IResetPasswordInfo) => {
    const res = await authService.changeUserPassword(data);
    return res;
  };

  const ChangeEthWalletAddress = async (address: string) => {
    console.log(address);
    setEthWalletAddress(address);
  };

  const value = {
    user,
    ethWalletAddress,
    ChangeEthWalletAddress,
    setCurrentUser,
    login,
    logout,
    sendResetRequest,
    verifyResetToken,
    resetPassword,
    refreshToken,
    sendRequestAccount,
    getUserById,
    verifyAccount,
    changeUserPassword,
  };

  return (
    <>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </>
  );
}

/**
 * This allows any function component that is the child of the Provider
 * consumes the user's auth context object.
 */
export function useAuth(): AuthContextType {
  return useContext(AuthContext);
}

/**
 * Wrapper to protected routes.
 */
export function RequireAuth({ children }: { children: JSX.Element }): any {
  const accessToken = authService.getAccessToken();
  if (!accessToken) {
    return <Navigate to="/login" replace />;
  }

  return children;
}
