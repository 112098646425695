import { useEffect } from "react";

function useMetaVersion(version: string): void {
  useEffect(() => {
    const el = document.querySelector("meta[name='version']");
    el?.setAttribute("content", version);
  }, [version]);
}

export default useMetaVersion;
