import { LOCAL_API_URL } from "constants/global";
import qs from "qs";
import { getOrganization } from "utils/utils";
import AxiosInstance from "./axiosinstance";

export async function getProjects(): Promise<any> {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.get(`${LOCAL_API_URL}/projects`, {
    headers: {
      "x-tenant": getOrganization(),
      Authorization: `JWT ${token}`,
    },
  });
  return res.data;
}

export async function getOneProject(id: string): Promise<any> {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.get(`${LOCAL_API_URL}/projects/${id}`, {
    headers: {
      "x-tenant": getOrganization(),
      Authorization: `JWT ${token}`,
    },
  });
  return res.data;
}

export const getWorkflows = async ({
  projectID,
}: {
  projectID: string;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.post(
    `${LOCAL_API_URL}/track/workflows`,
    { projectID },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};

export const getPaginatedProjects = async (
  page: Number,
  limit: Number
): Promise<any> => {
  const stringifiedQuery = qs.stringify(
    {
      page: page,
      limit: limit,
    },
    { addQueryPrefix: true }
  );
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.post(
    `${LOCAL_API_URL}/track/projects${stringifiedQuery}`,
    {},
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};

export const getPaginatedWorkflows = async (
  page: Number,
  limit: Number,
  { projectID }: { projectID: string }
): Promise<any> => {
  const stringifiedQuery = qs.stringify(
    {
      page: page,
      limit: limit,
    },
    { addQueryPrefix: true }
  );
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.post(
    `${LOCAL_API_URL}/track/workflows${stringifiedQuery}`,
    { projectID },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};

export const getTrackSteps = async ({
  workflowID,
}: {
  workflowID: string;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.post(
    `${LOCAL_API_URL}/track/tracksteps`,
    { workflowID },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};

export const copyExistingProject = async ({
  id,
  buildTypes,
  name,
  summary,
  type,
  copyFrom,
}: {
  id: string;
  buildTypes: string[];
  name: string;
  summary: string;
  type: string;
  copyFrom: string;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await AxiosInstance.post(
    `${LOCAL_API_URL}/track/copy-existing-project`,
    { id, buildTypes, name, summary, type, copyFrom },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
};
