/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import { App } from "antd";
import { Form, Input, Button, notification } from "antd";
import { LockOutlined } from "@ant-design/icons";
import "./index.css";
// import ReCAPTCHA from "react-google-recaptcha";
import { useAuth } from "../../hooks/auth-context";
import { useEffect, useState } from "react";
// import { RECAPTCHA_SITE_KEY } from "../../constants";
import logoIcon from "../../assets/skublu.png";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useDocumentTitle from "../../hooks/use-document-title";
import { IChangePasswordInfo } from "../../types";
const ResetPassword = () => {
  // const { notification } = App.useApp();
  const auth = useAuth();
  const params = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loginAvailable, setLoginAvailable] = useState(true);
  // const recaptchaRef = useRef<any>(null);
  useDocumentTitle("Reset Password");
  useEffect(() => {
    if (params && params.id) {
      auth
        .verifyResetToken(params.id)
        .then(function () {
          notification.success({ message: "You are verified successfully." });
        })
        .catch(function () {
          navigate("/login");
        });
    } else {
      // navigate("/login");
    }
  }, [auth, navigate, params]);
  if (auth.user && auth.user.email) {
    return <Navigate to="/" />;
  }
  const onFinish = async (values: any) => {
    if (loginAvailable && searchParams && searchParams.get("token")) {
      try {
        const data: IChangePasswordInfo = {
          token: searchParams.get("token") as string,
          password: values.password as string,
        };
        await auth.changeUserPassword(data);
         notification.success({ message: "Password changed successfully!" });
      } catch (err) {
        const errMsg: any = err;
        notification.error({
          message:
            "Reset Password Token was expired. Please request “Reset Password” again.",
        });
       navigate("/login");
      }
      setLoginAvailable(true);
      navigate("/login");
      // recaptchaRef.current?.reset();
    }
  };

  const handleRecaptchaChange = () => {
    setLoginAvailable(true);
  };

  const handleRecaptchaExpired = () => {
    setLoginAvailable(false);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <img id="logo" alt="logo" src={logoIcon} />
      <p id="subtitle">Reset password</p>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        rules={[
          {
            required: true,
            message: "Please input your Confirm Password!",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Confirm"
        />
      </Form.Item>

      {/* <div style={{ marginTop: "20px" }}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={handleRecaptchaChange}
          onExpired={handleRecaptchaExpired}
        />
      </div> */}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          disabled={!loginAvailable}
        >
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ResetPassword;
