/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { App } from "antd";
import React, { useState, useEffect } from "react";
import { Space, Table, Tag, Button, Card } from "antd";
import type { ColumnsType } from "antd/es/table";
import UserCreateForm from "../create-user";
import UserEditForm from "../edit-user";
import useDocumentTitle from "hooks/use-document-title";
import { createUser, getAllUser, updateUser, deleteUser } from "services/user";
import { getAllRoles } from "services/role";
// import {
//   getOrgDlpcSignatoryKeysBase64,
//   getOrgroleKeysBase64,
//   prepareRegisterAttesterKeyTx,
//   prepareRegisterDlpcSignatoryKeyTx,
// } from "utils/dlpc";
import { User, Role } from "types";
// import {
//   DLPC_SIGNATORY_DB_ROLE_NAME,
//   ATTESTER_DB_ROLE_NAME,
// } from "constants/global";
// import { accessMnemonic } from "utils/utils";
// import { signAndBroadcastControlChainTx } from "utils/chain";
import { useAuth } from "hooks/auth-context";
import { useNavigate } from "react-router-dom";

export interface DataType {
  id: string;
  name: string;
  email: string;
  roles: Role[];
  userOrgId: string;
  wallet: {
    address?: string;
    publicKey?: string;
    extendedPublicKey?: string;
  };
}
export interface EditDataType {
  id: string;
  name: string;
  email: string;
  roles: string[];
  userOrgId: string;
  wallet: {
    address?: string;
    publicKey?: string;
    extendedPublicKey?: string;
  };
}

const ListUsers: React.FC = () => {
  useDocumentTitle("Users");
  const navigate = useNavigate();
  const { user, setCurrentUser } = useAuth();
  const [userLists, setUserLists] = useState<DataType[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [roles, setRoles] = useState<Role[]>([]);
  const { notification, modal } = App.useApp();
  const [isUserListLoading, setisUserListsLoading] = useState(true);
  const [isRolesLoading, setIsRolesLoading] = useState(true);

  const [isAddModalOpen, setAddIsModalOpen] = useState(false);
  const [isEditModalOpen, setEditIsModalOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState<DataType>();

  const defaultPage = 1;
  const pageLimit = 10;

  // const dlpcSignatories =
  //   userLists?.filter(({ roles }) => {
  //     return roles.find(
  //       ({ roleName }) => roleName === DLPC_SIGNATORY_DB_ROLE_NAME
  //     );
  //   }) ?? [];
  // const AttesterSignatories =
  //   userLists?.filter(({ roles }) => {
  //     return roles.find(({ roleName }) => roleName === ATTESTER_DB_ROLE_NAME);
  //   }) ?? [];
  useEffect(() => {
    const getAllUserRoles = async () => {
      try {
        const { data } = await getAllRoles(1, 100);
        if (data?.docs?.length > 0) {
          setRoles(data?.docs);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsRolesLoading(false);
      }
    };
    getAllUserRoles();
  }, []);

  useEffect(() => {
    getAllUserFunction(defaultPage);
  }, []);

  useEffect(() => {
    checkAdminRole(user);
  }, [user]);

  const checkAdminRole = (user: User) => {
    if (user.id) {
      if (user.roles) {
        let isAdmins = false;
        user?.roles?.forEach((item: any) => {
          if (item?.roleName === "Admin") {
            isAdmins = true;
          }
        });
        if (!isAdmins) {
          navigate("/account");
        }
      } else {
        navigate("/account");
      }
    } else {
      console.log("user not loaded");
    }
  };
  const getAllUserFunction = async (page: number) => {
    try {
      const userPaginatedDocs = await getAllUser(page, pageLimit);
      const userData = userPaginatedDocs.docs
        .filter((user) => user.id && user.name && user.email)
        .map(
          ({ roles, ...rest }) =>
            ({
              roles: roles ?? [],
              ...rest,
            } as DataType)
        );
      setTotalUsers(userPaginatedDocs.totalDocs);
      setUserLists(userData);
    } catch (error) {
      console.log(error);
    } finally {
      setisUserListsLoading(false);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name-key-unique-11",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email-key-unique-22",
    },
    {
      title: "Roles",
      key: "roles-key-unique-33",
      dataIndex: "roles",
      render: (_, { roles }) => (
        <>
          {roles?.map((role, index) => {
            return (role as Role)?.roleName ? (
              <Tag color={"blue"} key={index}>
                {(role as Role)?.roleName?.toUpperCase()}
              </Tag>
            ) : null;
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action-key-unique-44",
      render: (_, record: DataType) => (
        <Space size="middle">
          <a onClick={() => showEditModal(record)}>Edit</a>
          <a onClick={() => userDeleteModal(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  // const dlpcSignatoryRoleId = roles.find(
  //   ({ roleName }) => roleName === DLPC_SIGNATORY_DB_ROLE_NAME
  // )?.id;
  // const attesterRoleId = roles.find(
  //   ({ roleName }) => roleName === ATTESTER_DB_ROLE_NAME
  // )?.id;

  const handleAdd = async (values: User) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const userId = user?.id;
      const data = await createUser(values,userId);
      notification.success({ message: "User has been created successfully" });
      getAllUserFunction(defaultPage);
      setAddIsModalOpen(false);
    } catch (error) {
      notification.error({
        message: (
          <p>
            User already exists.
            <br />
            Please use a different user details.
          </p>
        ),
      });
      setAddIsModalOpen(true);
    }
  };

  const showModal = () => {
    setAddIsModalOpen(true);
  };

  const handleAddCancel = () => {
    setAddIsModalOpen(false);
  };

  const showEditModal = (record: DataType) => {
    setSelectedUser(record);
    setEditIsModalOpen(true);
  };

  // const registerDlpcSignatoryKeyIfNeeded = async (roleIds: string[]) => {
  //   const isDlpcSignatoryRoleSelected = roleIds.find(
  //     (roleId) => roleId === dlpcSignatoryRoleId
  //   );
  //   const hasDlpcSignatoryRoleAlready = dlpcSignatories.find(
  //     ({ id }) => id === selectedUser?.id
  //   );
  //   if (isDlpcSignatoryRoleSelected && !hasDlpcSignatoryRoleAlready) {
  //     const allRegisteredDlpcSignatoryKeys =
  //       await getOrgDlpcSignatoryKeysBase64({});
  //     const userDlpcSignatoryKey = selectedUser?.wallet.publicKey!;
  //     const extendedPublicKey = selectedUser?.wallet.extendedPublicKey!;
  //     const userName = selectedUser?.name!;

  //     const isRegisteredOnChain = allRegisteredDlpcSignatoryKeys.find(
  //       (item) => item.PublicKey == userDlpcSignatoryKey
  //     );
  //     if (!isRegisteredOnChain) {
  //       const mnemonic = accessMnemonic();
  //       const { messages } = await prepareRegisterDlpcSignatoryKeyTx({
  //         mnemonic,
  //         userId: userName,
  //         publicKeyBase64: userDlpcSignatoryKey,
  //         extendedPublicKey: extendedPublicKey,
  //       });
  //       const txData = await signAndBroadcastControlChainTx({
  //         mnemonic,
  //         messages,
  //       });
  //       console.log("Register DLPC Signatory Key Tx Data");
  //       console.log(txData);
  //     }
  //   }
  // };

  // const registerAttesterIfNeeded = async (roleIds: string[]) => {
  //   console.log("registerAttesterIfNeeded");
  //   const isAttesterRoleSelected = roleIds.find(
  //     (roleId) => roleId === attesterRoleId
  //   );
  //   const hasAttesterRoleAlready = AttesterSignatories.find(
  //     ({ id }) => id === selectedUser?.id
  //   );
  //   if (isAttesterRoleSelected && !hasAttesterRoleAlready) {
  //     const allRegisteredAttesterKeys = await getOrgroleKeysBase64({});
  //     const userattester = selectedUser?.wallet.publicKey!;
  //     const extendedPublicKey = selectedUser?.wallet.extendedPublicKey!;
  //     const isRegisteredOnChain = allRegisteredAttesterKeys.find(
  //       (item) => item.PublicKey == userattester
  //     );

  //     if (!isRegisteredOnChain) {
  //       const mnemonic = accessMnemonic();
  //       const { messages } = await prepareRegisterAttesterKeyTx({
  //         mnemonic,
  //         publicKeyBase64: userattester,
  //         extendedPublicKey: extendedPublicKey,
  //       });
  //       const txData = await signAndBroadcastControlChainTx({
  //         mnemonic,
  //         messages,
  //       });
  //       console.log("Register Attester Key Tx Data");
  //       console.log(txData);
  //     }
  //   }
  // };
  const handleEditOk = async (values: EditDataType) => {
    try {
      if (!selectedUser) {
        throw new Error("User is not selected.");
      }
      // await registerDlpcSignatoryKeyIfNeeded(values.roles);
      // await registerAttesterIfNeeded(values.roles);
      const userId = user?.id;
      await updateUser(values, userId);
      if (selectedUser.id === user?.id) {
        await setCurrentUser();
      }
      notification.success({ message: "User has been updated successfully" });
      getAllUserFunction(defaultPage);
      setEditIsModalOpen(false);
    } catch (error) {
      console.log(error);
      notification.error({ message: (error as Error).message });
      setEditIsModalOpen(false);
    }
  };

  const handleEditCancel = () => {
    setSelectedUser(undefined);
    setEditIsModalOpen(false);
  };

  const userDeleteModal = (record: DataType) => {
    modal.confirm({
      title: "Do you want to delete the user ?",
      content: record?.email,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          const id: string = record?.id;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars

          const userId = user?.id;
          const data = await deleteUser(id, userId);
          notification.success({
            message: "User has been deleted successfully",
          });
          getAllUserFunction(defaultPage);
        } catch (error) {
          notification.error({ message: (error as Error).message });
        }
      },
      onCancel() {
        console.log("Delete user canceled");
      },
    });
  };

  return (
    <>
      <Card title="Users">
        <Button
          onClick={showModal}
          type="primary"
          style={{ marginBottom: 16, float: "right" }}
        >
          Add User
        </Button>
        <Table
          loading={isUserListLoading || isRolesLoading}
          columns={columns}
          dataSource={userLists}
          pagination={{
            pageSize: pageLimit,
            total: totalUsers,
            onChange: (page) => {
              getAllUserFunction(page);
            },
          }}
          rowKey="id"
        />
      </Card>

      <UserCreateForm
        key="create-user-modal-unique-key-prop-1"
        open={isAddModalOpen}
        onCreate={handleAdd}
        onCancel={handleAddCancel}
        roles={roles}
      />
      <UserEditForm
        key="Edit-user-modal-unique-key-prop-2"
        open={isEditModalOpen}
        onCreate={handleEditOk}
        onCancel={handleEditCancel}
        selectedUser={selectedUser}
        roles={roles}
        // dlpcSignatories={dlpcSignatories}
        // AttesterSignatories={AttesterSignatories}
      />
    </>
  );
};

export default (): JSX.Element => (
  <App>
    <ListUsers />;
  </App>
);
