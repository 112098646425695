import React, { useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import "./index.css";
import { User, Role } from "types";
// import {
//   DLPC_SIGNATORY_DB_ROLE_NAME,
//   ATTESTER_DB_ROLE_NAME,
// } from "constants/global";

interface UserCreateFormProps {
  open: boolean;
  roles: Role[];
  onCreate: (values: User) => void;
  onCancel: () => void;
}

const UserCreateForm = ({
  open,
  roles,
  onCreate,
  onCancel,
}: UserCreateFormProps): any => {
  const [form] = Form.useForm();
  const [isProcessingSubmit, setIsProcessingSubmit] = useState(false);


  return (
    <Modal
      open={open}
      title="Create User"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      maskClosable={false}
      onOk={() => {
        setIsProcessingSubmit(true);
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          })
          .finally(() => {
            setIsProcessingSubmit(false);
          });
      }}
      confirmLoading={isProcessingSubmit}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
        className="user-form"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Please input the email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="userOrgId"
          label="User Org ID"
          rules={[{ message: "Please input the uid!" }]}
        >
          <Input placeholder="User Org ID"/>
        </Form.Item>
        <Form.Item name="roles" label="Roles">
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={roles.map(({ roleName, id }) => ({
              label: roleName,
              value: id,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserCreateForm;
