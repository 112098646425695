import React, { useEffect } from "react";
import vis from "vis";
import _ from "lodash";
import "vis/dist/vis-network.min.css";

const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));
interface Props {
  onNodeClick: any;
  onNodeDoubleClick: any;
  delayMs: number;
  animated: any;
  nodesRef: any;
  edgesRef: any;
  networkRef: any;
  nodes: any;
  edges: any;
  onClick: any;
  options: any;
}

const Graph = (props: Props): JSX.Element => {
  const graphRef: any = React.createRef();

  useEffect(() => {
    const loadGraph = async () => {
      const graph = props;
      const nodes = new vis.DataSet([] as any[]);
      const edges = new vis.DataSet(_.uniqBy(graph.edges, "id"));

      const { nodesRef, edgesRef, networkRef } = props;

      nodesRef.current = nodes;
      edgesRef.current = edges;

      const data = {
        nodes,
        edges,
      };
      const network = new vis.Network(graphRef.current, data, graph.options);
      networkRef.current = network;
      network.on("click", (params: any) => {
        if (params.nodes.length >= 1 && graph.onNodeClick) {
          graph.onNodeClick(params.nodes[0], params, nodes, edges);
        }

        if (graph.onClick) graph.onClick(params, nodes, edges);
      });
      network.on("doubleClick", (params: any) => {
        if (params.nodes.length >= 1 && graph.onNodeDoubleClick) {
          graph.onNodeDoubleClick(params.nodes[0], params, nodes, edges);
        }
      });

      const uniqueNodes: any[] = _.uniqBy(graph.nodes, "id");
      // eslint-disable-next-line no-restricted-syntax
      for (const node of uniqueNodes) {
        nodes.add(node);
        // eslint-disable-next-line no-await-in-loop
        if (graph.animated) await delay(graph.delayMs || 200);
      }

      network.fit();
    };

    loadGraph();
  }, [props, graphRef]);

  return <div style={{ height: 600 }} ref={graphRef} />;
};

export default Graph;
