import axios from "axios";
import qs from "qs";
import { POPCODE_SHORT_API } from "../constants";
import { getOrganization, getAuthorizationHeader } from "../utils/utils";
import { PaginatedDocs } from "../types";
import { LOCAL_API_URL } from "../constants/global";
import AxiosInstance from "./axiosinstance";

export const getAllDocuments = async ({
  collName,
}: {
  collName: string;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await axios.get(`${LOCAL_API_URL}/${collName}`, {
    headers: {
      "x-tenant": getOrganization(),
      Authorization: `JWT ${token}`,
    },
  });
  return res.data;
};

export const getProjectbyId = async ({
  collName,
  Id,
}: {
  collName: string;
  Id: string;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await axios.get(`${LOCAL_API_URL}/${collName}/${Id}`, {
    headers: {
      "x-tenant": getOrganization(),
      Authorization: `JWT ${token}`,
    },
  });
  return res.data;
};

export const getDocumentbyId = async ({
  collName,
  Id,
}: {
  collName: string;
  Id: string;
}): Promise<any> => {
  const token = localStorage.getItem("token");
  const res = await axios.get(`${LOCAL_API_URL}/${collName}/${Id}`, {
    headers: {
      "x-tenant": getOrganization(),
      Authorization: `JWT ${token}`,
    },
  });
  return res.data;
};

export async function saveDocument({
  collName,
  payload,
}: {
  collName: string;
  payload: any;
}): Promise<{ urls: any[] }> {
  const token = localStorage.getItem("token");
  const res = await axios.post<{ urls: any[] }>(
    `${LOCAL_API_URL}/${collName}`,
    payload,
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}

export async function updateDocument({
  payload,
}: {
  collName: string;
  id: string;
  payload: any;
}): Promise<{ urls: any[] }> {
  const token = localStorage.getItem("token");
  const res = await axios.post<{ urls: any[] }>(
    `${LOCAL_API_URL}/track/update-projects`,
    payload,
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}

export const getRequestByQuery = async <T = any>(
  collectionSlug: string,
  query: { page: number; limit: number }
): Promise<PaginatedDocs<T>> => {
  const stringifiedQuery = qs.stringify(query, { addQueryPrefix: true });
  const { data } = await AxiosInstance.get<PaginatedDocs<T>>(
    `/track-admin/get-all-users/${stringifiedQuery}`,
    { headers: getAuthorizationHeader() }
  );
  return data;
};

export async function deleteProjects({
  id,
}: {
  collName: string;
  id: string;
}): Promise<any> {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${LOCAL_API_URL}/track/delete-projects`,
    { id },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}

export async function deleteWorkflows({
  id,
}: {
  collName: string;
  id: string;
}): Promise<any> {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${LOCAL_API_URL}/track/delete-workflows`,
    { id },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}

export async function generatePopCode({
  payload,
}: {
  payload: any;
}): Promise<any[]> {
  const token = localStorage.getItem("token");
  const response = await axios.post<{ urls: any[] }>(
    `${LOCAL_API_URL}${POPCODE_SHORT_API}/generate`,
    payload,
    {
      headers: {
        Authorization: `JWT ${token}`,
        "x-tenant": getOrganization(),
      },
    }
  );
  return response.data.urls;
}

export async function getAllDocumentsById({
  collName,
  id,
  key,
}: {
  collName: string;
  id: string;
  key: string;
}): Promise<any> {
  const token = localStorage.getItem("token");
  const res = await axios.get(
    `${LOCAL_API_URL}/db/collection/${collName}/document/${key}/${id}`,
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}

export const getMyOrgInfo = async (): Promise<any> => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    `${LOCAL_API_URL}/organizations/my-org-info`,
    {
      device_type: "track-desktop",
    },
    {
      headers: {
        Authorization: `JWT ${token}`,
        "x-tenant": getOrganization(),
      },
    }
  );
  return data;
};

export async function getAllEC3Documents({
  collName,
}: {
  collName: string;
}): Promise<any> {
  const token = localStorage.getItem("token");
  const res = await axios.get(`${LOCAL_API_URL}/${collName}`, {
    headers: {
      Authorization: `JWT ${token}`,
      "x-tenant": getOrganization(),
    },
  });
  return res.data;
}

export async function saveWorkflow({
  id,
  value,
  projectID,
}: {
  id: string;
  value: any;
  projectID: string;
}): Promise<{ urls: any[] }> {
  const token = localStorage.getItem("token");
  const res = await axios.post<{ urls: any[] }>(
    `${LOCAL_API_URL}/workflows`,
    { id, value, projectID },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}

export async function saveTrackSteps({
  id,
  value,
  workflowID,
}: {
  id: string;
  value: any;
  workflowID: string;
}): Promise<{ urls: any[] }> {
  const token = localStorage.getItem("token");
  const res = await axios.post<{ urls: any[] }>(
    `${LOCAL_API_URL}/tracksteps`,
    { id, value, workflowID },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}
export async function UpdateWorkflow({
  id,
  value,
  projectID,
}: {
  id: string;
  value: any;
  projectID: string;
}): Promise<{ urls: any[] }> {
  const token = localStorage.getItem("token");
  const res = await axios.post<{ urls: any[] }>(
    `${LOCAL_API_URL}/track/update-workflows`,
    { id, value, projectID },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}

export async function UpdateTracksteps({
  id,
  value,
  workflowID,
}: {
  id: string;
  value: any;
  workflowID: string;
}): Promise<{ urls: any[] }> {
  const token = localStorage.getItem("token");
  const res = await axios.post<{ urls: any[] }>(
    `${LOCAL_API_URL}/track/update-tracksteps`,
    { id, value, workflowID },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}

export async function copyExistingWorkflow({
  id,
  value,
  projectID,
  copyFrom,
}: {
  id: string;
  value: any;
  projectID: string;
  copyFrom: string;
}): Promise<{ urls: any[] }> {
  const token = localStorage.getItem("token");
  const res = await axios.post<{ urls: any[] }>(
    `${LOCAL_API_URL}/track/copy-existing-workflow
    `,
    { id, value, projectID, copyFrom },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}

export async function getProjectListDropdown(): Promise<any> {
  const token = localStorage.getItem("token");
  const res = await axios.get(`${LOCAL_API_URL}/track/list-projects`, {
    headers: {
      Authorization: `JWT ${token}`,
      "x-tenant": getOrganization(),
    },
  });
  return res.data;
}

export async function getWorkflowListDropdown({
  projectID,
}: {
  projectID: string;
}): Promise<{ urls: any[] }> {
  const token = localStorage.getItem("token");
  const res = await axios.post<{ urls: any[] }>(
    `${LOCAL_API_URL}/track/list-workflows
    `,
    { projectID },
    {
      headers: {
        "x-tenant": getOrganization(),
        Authorization: `JWT ${token}`,
      },
    }
  );
  return res.data;
}
