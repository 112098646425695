/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getAuthorizationHeader } from "../utils/utils";
import { User } from "../types";
import AxiosInstance from "./axiosinstance";
import { getRequestByQuery } from "./api";
import { v4 as uuidv4 } from "uuid";


// type UserAddArgs = {
//   name: string;
//   email: string;
//   roles: string[];
// };

type UserEditArgs = {
  id: string;
  name: string;
  email: string;
  roles: string[];
  userOrgId: string;
};

const getRandomPass = () => {
  const chars =
    "0123456789~!@#$%^&*()_+}{[]|abcdefghikjlmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let pass = "";
  const passLength = 16;
  for (let i = 0; i < passLength; i++) {
    const randPass = Math.floor(Math.random() * chars.length);
    pass += chars.substring(randPass, randPass + 1);
  }
  return pass;
};

export const createUser = async ({ name, email, roles, userOrgId }: User, userId: string) => {
  const password = getRandomPass();
  const ApiKey = uuidv4();
  const data = await AxiosInstance.post(
    `/track-admin/create-users`,
    { name, email, roles, password, userOrgId, enableAPIKey: true, apiKey: ApiKey, userId },
    { headers: getAuthorizationHeader() }
  );
  return data;
};

export const updateUser = async ({ id, name, email, roles, userOrgId }: UserEditArgs,  userId: string) => {
  const data = await AxiosInstance.post(
    `track-admin/update-users/`,
    { id, name, email, roles, userOrgId, userId },
    { headers: getAuthorizationHeader() }
  );

  return data;
};

// export const getUser = async () => {
//   const res = await AxiosInstance.get(`/users`, { headers: getAuthorizationHeader() });
//   return res.data;
// };

export const getAllUser = async (page: number, limit: number) => {
  const query = { page, limit };
  const usersQueryResult = await getRequestByQuery<User>("users", query);
  return usersQueryResult;
};

export const deleteUser = async (id: string, userId : string) => {
  const data = await AxiosInstance.post(`track-admin/delete-users`, { id, userId }, {
    headers: getAuthorizationHeader(),
  });
  return data;
};
