import React, { useRef } from "react";
import QRCode from "qrcode.react";
import ReactToPrint from "react-to-print";
import { Button, Typography } from "antd";
import _ from "lodash";
import "./pre-print-code.css";

const PrePrintCode = (props: any) => {
  const renderPage = (pageItems = []) => {
    const { Text } = Typography;

    return (
      <div
        key="div-main-class"
        className="main-page"
        style={{ pageBreakAfter: "auto", pageBreakInside: "avoid" }}
      >
        {pageItems.map((s: string) => {
          const code = `${props.network} / ${s.split("/")[4]}`;
          return (
            <div key={s} className="main-card">
              <div className="printable-section left-section">
                <div className="section-1">
                  <Text className="text-center">Popcode Label</Text>
                  <div className="box" />
                  <Text className="network-code">{code}</Text>
                </div>
                <div className="section-2">
                  <QRCode size={100} value={s} renderAs="svg" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  // const onBack = () => {
  //   props.onBack();
  // };

  const urls = props.printUrls || [];
  const pagePerItem = 10;
  const totalPages = Math.ceil(urls.length / pagePerItem);
  const totalPageContent = [];
  for (let i = 0; i < totalPages; i++) {
    const items = _.cloneDeep(
      urls.slice(i * pagePerItem, i * pagePerItem + pagePerItem)
    );
    totalPageContent.push(renderPage(items));
  }
  return <div>{totalPageContent.map((page) => page)}</div>;
};

const Example = ({
  network,
  printUrls,
  onBack,
}: {
  network: any;
  printUrls: any[];
  onBack: () => void;
}): JSX.Element => {
  const componentRef = useRef(null);
  return (
    <div className="PrePrintCode">
      <Button
        style={{ float: "left", marginRight: 10 }}
        onClick={() => onBack()}
      >
        Back
      </Button>
      <ReactToPrint
        trigger={() => (
          <div style={{ marginBottom: 10 }}>
            <Button>Print</Button> &nbsp;
          </div>
        )}
        content={() => componentRef?.current}
      />

      <div className="PrePrintCode" ref={componentRef}>
        <PrePrintCode network={network} onBack={onBack} printUrls={printUrls} />
      </div>
    </div>
  );
};
export default Example;
