import { App } from "antd";
import React, { useState, useEffect } from "react";
import { Space, Table, Button, Card } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  createRole,
  getAllRoles,
  updateRole,
  deleteRole,
} from "../../../../services/role";
import RoleCreateForm from "../create-role";
import RoleEditForm from "../edit-role";
import useDocumentTitle from "hooks/use-document-title";
import { useAuth } from "hooks/auth-context";
import { useNavigate } from "react-router-dom";
import { User } from "types";

interface DataType {
  key: string;
  id: string;
  roleName: string;
  // accessControl: string[];
}

const ListRoles = (): any => {
  useDocumentTitle("Roles");
  const navigate = useNavigate();
  const { user } = useAuth();
  const [roleLists, setRoleLists] = useState([]);
  const [totalRole, setTotalrole] = useState(0);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { notification, modal } = App.useApp();
  const [isAddModalOpen, setAddIsModalOpen] = useState(false);
  const [isEditModalOpen, setEditIsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<DataType>({
    key: "",
    id: "",
    roleName: "",
    // accessControl: [],
  });

   const totalRoles = roleLists.length;
   const pageLimit = 10;
  const columns: ColumnsType<DataType> = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "40%",
    },
    // {
    //   title: "Access Control",
    //   key: "accessControl",
    //   dataIndex: "accessControl",
    //   render: (_, { accessControl }) => (
    //     <>
    //       {accessControl?.map((role, index) => {
    //         return (
    //           // <Tag color={"blue"} key={index}>
    //           //   {role.toUpperCase()}
    //           // </Tag>
    //           <p>hi</p>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (_, record: DataType) => (
        <Space size="middle">
          <a onClick={() => showEditModal(record)}>Edit</a>
          <a onClick={() => roleDeleteModal(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getAllRolesFunction(currentPage);
    checkAdminRole(user);
  }, [user]);

  const checkAdminRole = (user: User) => {
    if (user.id) {
      if (user.roles) {
        let isAdmins = false;
        user?.roles?.forEach((item: any) => {
          if (item?.roleName === "Admin") {
            isAdmins = true;
          }
        });
        if (!isAdmins) {
          navigate("/account");
        }
      } else {
        navigate("/account");
      }
    } else {
      console.log("user not loaded");
    }
  };

  const getAllRolesFunction = async (page: number) => {
    setLoading(true);
    try {
      const data = await getAllRoles(page, pageLimit);
      const data1 = data?.data?.docs;
      const dataWithId = data1.map((item: any, index: number) => ({
        ...item,
        key: index,
      }));
      // console.log(data.data.totalDocs, "data1");
      //  setRoleLists(data.data.totalDocs);
       setTotalrole(data.data.totalDocs);
       setRoleLists(dataWithId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleAdd = async (values: DataType) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const userId = user?.id;
      const data = await createRole(values, userId);
      notification.success({ message: "Role has been created successfully" });
      getAllRolesFunction(currentPage);
      setAddIsModalOpen(false);
    } catch (error) {
      notification.error({
        message: (
          <p>
            Role Name already exists.
            <br />
            Please use a different role name.
          </p>
        ),
      });
      setAddIsModalOpen(true);
    }
  };
  const showAddModal = () => {
    setAddIsModalOpen(true);
  };

  const handleAddCancel = () => {
    setAddIsModalOpen(false);
  };

  const showEditModal = (record: DataType) => {
    setSelectedRole(record);
    setEditIsModalOpen(true);
  };

  const handleEditOk = async (values: DataType) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const userId = user?.id;
      const data = await updateRole(values, userId);
      notification.success({ message: "Role has been updated successfully" });
      getAllRolesFunction(currentPage);
      setEditIsModalOpen(false);
    } catch (error) {
      notification.error({ message: (error as Error).message });
      setEditIsModalOpen(false);
    }
  };

  const handleEditCancel = () => {
    setEditIsModalOpen(false);
  };

  const roleDeleteModal = (record: DataType) => {
    modal.confirm({
      title: "Do you want to delete the role ?",
      content: record?.roleName,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          const id: string = record?.id;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const userId = user?.id;
          const data = await deleteRole(id, userId);
          notification.success({
            message: "Role has been deleted successfully",
          });
          getAllRolesFunction(currentPage);
        } catch (error) {
          notification.error({ message: (error as Error).message });
        }
      },
      onCancel() {
        console.log("Delete role Cancelled");
      },
    });
  };

  return (
    <>
      <Card title="Roles">
        <Button
          onClick={showAddModal}
          type="primary"
          style={{ marginBottom: 16, float: "right" }}
        >
          Add Role
        </Button>
        <Table
          loading={loading}
          columns={columns}
          dataSource={roleLists}
          pagination={{
            pageSize: pageLimit,
            total: totalRole,
            onChange: (page) => {
              getAllRolesFunction(page);
            },
          }}
        />
      </Card>

      <RoleCreateForm
        open={isAddModalOpen}
        onCreate={handleAdd}
        onCancel={handleAddCancel}
      />
      <RoleEditForm
        open={isEditModalOpen}
        onCreate={handleEditOk}
        onCancel={handleEditCancel}
        selectedRole={selectedRole}
      />
    </>
  );
};

export default (): JSX.Element => (
  <App>
    <ListRoles />
  </App>
);
