import { coins, StdFee } from "skud-client-base/lib/cosmjs/amino";
import { SelectProps } from "antd";

// ////////////
// UI Defaults
// ////////////

const NOTIFY_DURATION = 4;
const LOCAL_API_URL = process.env.REACT_APP_LOCAL_API_URL;

// //////////////////////////////
// Blockchain skud/skcd constants
// //////////////////////////////

const CHAIN_PREFIX = "sku";
const DEFAULT_FEE_OBJ: StdFee = {
  amount: coins(0, "umon"),
  gas: "400000",
};
const DEFAULT_MEMO = "";
const DEFAULT_TIMEOUT = 1692531200000000000;
const FI_PORT = "fi";

const BASE_HD_DLPC_PATH = "m/201409'/1";

// //////////////////////
// Application constants
// //////////////////////

const DLPC_SIGNATORY_DB_ROLE_NAME = "DLPC Signatory";
const DLPC_SIGNATORY_CHAIN_ROLE_NAME = "DlpcSignatory";

// ////////////////////
// Conditions constants
// ////////////////////

const ATTESTER_DB_ROLE_NAME = "Attester";
const ATTESTER_CHAIN_ROLE_NAME = "Attester";

const CONDITION_SHIP_DOCS = "shippingDocVerify";
const CONDITION_TYPE_OPTIONS: SelectProps["options"] = [
  {
    label: "Shipping Document Verification",
    value: CONDITION_SHIP_DOCS,
  },
];
const SERVICE_PROVIDER_ROLE = "ServiceProvider";
const COMMITTEE_ROLE = "Committee";
const COMMITTER_ROLE = "Committer";
const VERIFY_DOCUMENTS_METHOD = "verifyDocuments";

export {
  ATTESTER_CHAIN_ROLE_NAME,
  ATTESTER_DB_ROLE_NAME,
  CHAIN_PREFIX,
  COMMITTEE_ROLE,
  COMMITTER_ROLE,
  BASE_HD_DLPC_PATH,
  CONDITION_SHIP_DOCS,
  CONDITION_TYPE_OPTIONS,
  DEFAULT_FEE_OBJ,
  DEFAULT_MEMO,
  DEFAULT_TIMEOUT,
  DLPC_SIGNATORY_CHAIN_ROLE_NAME,
  DLPC_SIGNATORY_DB_ROLE_NAME,
  FI_PORT,
  LOCAL_API_URL,
  NOTIFY_DURATION,
  SERVICE_PROVIDER_ROLE,
  VERIFY_DOCUMENTS_METHOD,
};
