/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { App } from "antd";
import { useState } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useAuth } from "../../hooks/auth-context";
import useDocumentTitle from "../../hooks/use-document-title";
import useMetaVersion from "hooks/use-meta-version";
import logoIcon from "../../assets/skublu.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";

import "./index.css";

const Login = () => {
  useMetaVersion("1.0");
  const navigate = useNavigate();
  const auth = useAuth();
  const [loginAvailable, setLoginAvailable] = useState(true);
  const { notification } = App.useApp();
  const [loadings, setLoadings] = useState<boolean>(false);
  const [recaptchaChecked, setRecaptchaChecked] = useState(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  useDocumentTitle("Login");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const RecaptchaValue = searchParams.get("captcha") === "true";
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
  if (auth.user && auth.user.email) {
    return <Navigate to="/" />;
  }
  const onFinish = async (values: any) => {
    if (loginAvailable) {
      setLoginAvailable(false);
      setLoadings(true);
      try {
        await auth.login(values.email, values.password);
        notification.success({ message: "Log in successfully." });
        navigate("/", { replace: true });
      } catch (err) {
        notification.error({ message: (err as Error).message });
        setLoginAvailable(true);
      }
      setLoadings(false);
      // recaptchaRef.current?.reset();
    }
  };

  const handleRecaptchaChange = (value: string | null) => {
    if (value) setRecaptchaChecked(true);
  };

  const handleRememberMeChange = (e: any) => {
    setRememberMe(e.target.checked);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <img id="logo" src={logoIcon} alt="logo" />
      <p id="subtitle">Welcome to Skuchain</p>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your Email!",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked" noStyle>
        <Checkbox checked={rememberMe} onChange={handleRememberMeChange}>
          Remember me
        </Checkbox>
      </Form.Item>
      <Form.Item name="forgot-password">
        <Link className="login-form-forgot" to="/forgot">
          Forgot password
        </Link>
      </Form.Item>
      {RecaptchaValue && (
        <Form.Item className="recaptcha">
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={handleRecaptchaChange}
          />
        </Form.Item>
      )}
      <Form.Item>
        <Button
          disabled={RecaptchaValue && !recaptchaChecked}
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loadings}
        >
          Log in
        </Button>
      </Form.Item>

      {/* <Link className="login-form-link" to="/request">
      Don’t have an account? Click here to request access
    </Link> */}
    </Form>
  );
};

export default (): JSX.Element => (
  <App>
    <Login />
  </App>
);
