import { Breadcrumb, Layout } from "antd";
import { Link, useLocation } from "react-router-dom";
import { authRoutes } from "routes";
import React, { useMemo } from "react";
const PageBreadcrumb = (): JSX.Element => {
  const location = useLocation();
  const targetRoute = useMemo(() => {
    const routePath = location.pathname;
    return authRoutes.find((route) => route.path === routePath);
  }, [location.pathname]);
  return (
    <Layout className="site-layout">
      {targetRoute && targetRoute?.breadcrumbName.length > 0 && (
        <Breadcrumb
          separator="/"
          items={targetRoute.breadcrumbName.map((breadcrumb: string) => {
            const targetRoute = authRoutes.find((el) => el.name === breadcrumb);
            return {
              title: targetRoute?.path ? (
                <Link to={`${targetRoute.path}`}>{breadcrumb}</Link>
              ) : (
                <span>{breadcrumb}</span>
              ),
            };
          })}
        />
      )}
    </Layout>
  );
};
export default PageBreadcrumb;
