/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { App } from "antd";
import { Form, Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./index.css";
// import ReCAPTCHA from 'react-google-recaptcha';
import { useAuth } from "../../hooks/auth-context";
import { useRef, useState } from "react";
// import { RECAPTCHA_SITE_KEY } from "../../constants";
import useDocumentTitle from "../../hooks/use-document-title";
import { Link, Navigate } from "react-router-dom";
import logoIcon from "../../assets/skublu.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

const Forgot = () => {
  const auth = useAuth();
  const [loadings, setLoadings] = useState<boolean>(false);
  const [loginAvailable, setLoginAvailable] = useState(true);
  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const RecaptchaValue = searchParams.get("captcha") === "true";
  // const [rememberMe, setRememberMe] = useState<boolean>(false);
  const recaptchaRef = useRef<any>(null);
  const { notification } = App.useApp();
  useDocumentTitle("Forgot password");
  const onFinish = async (values: any) => {
    if (loginAvailable) {
      setLoginAvailable(false);
      setLoadings(true);
      try {
        await auth.sendResetRequest(values.email);
        notification.success({
          message: (
            <>
              Reset Password has been requested successfully.
              <br />
              Please check your email box
            </>
          ),
        });
      } catch (err) {
        notification.error({ message: (err as Error).message });
        setLoginAvailable(true);
      }
      setLoadings(false);
      recaptchaRef.current?.reset();
    }
  };
  if (auth.user && auth.user.email) {
    return <Navigate to="/" />;
  }
  // const handleRecaptchaChange = () => {
  //   setLoginAvailable(true);
  // };

  // const handleRecaptchaExpired = () => {
  //   setLoginAvailable(false);
  // };
  const handleRecaptchaChange = (value: string | null) => {
    if (value) {
      setRecaptchaValue(true);
    }
  };

  // const handleRememberMeChange = (e: any) => {
  //   setRememberMe(e.target.checked);
  // };
  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Link to="/">
        <img id="logo" alt="logo" src={logoIcon} />
      </Link>
      <p id="subtitle">Forgot password</p>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your Email!",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>

      {/* <div style={{ marginTop: "20px" }}>
        <ReCAPTCHA
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={handleRecaptchaChange}
          onExpired={handleRecaptchaExpired}
        />
      </div> */}

      {/* <Form.Item name="remember" valuePropName="checked" noStyle>
        <Checkbox checked={rememberMe} onChange={handleRememberMeChange}>
          Remember me
        </Checkbox>
      </Form.Item> */}
      {RecaptchaValue && (
        <Form.Item className="recaptcha">
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={handleRecaptchaChange}
          />
        </Form.Item>
      )}
      <Form.Item>
        <Button
          disabled={RecaptchaValue && !recaptchaValue}
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loadings}
        >
          Send Reset Request
        </Button>
      </Form.Item>

      <Form.Item>
        {" "}
        <a href="/login">Back to Login Page</a>
      </Form.Item>
    </Form>
  );
};

export default (): JSX.Element => (
  <App>
    <Forgot />
  </App>
);
