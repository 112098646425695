/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { App } from "antd";
import { useNavigate } from "react-router-dom";
import { Col, Row, Image, Card } from "antd";
import { useAuth } from "hooks/auth-context";
import "./index.css";
import { useState } from "react";
import { useEffect } from "react";
import { NOTIFY_DURATION } from "constants/global";
import logoIcon from "../../assets/skublu.png";
function Dashboard() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const { notification } = App.useApp();
  useEffect(() => {
    user?.roles?.forEach((item: any) => {
      if (item?.roleName == "Admin") {
        setIsAdmin(true);
      }
    });
  });

  const cards = [
    {
      title: "Wallet",
      description:
        "Access your wallet by entering your menmonic, or create a new wallet",
      icon: "fa-solid fa-wallet",
      route: "/account/wallet",
    },
    {
      title: "Users & Roles",
      description: "Create or modify your organization's users and roles",
      icon: "fa-solid fa-users",
      route: "/account/users",
    },
    {
      title: "Track Module",
      description: "Track your products",
      icon: "fa-solid fa-regular fa-location-dot",
      route: "/recent-activity",
    },
  ];

  const clickcard = (route: string) => {
    if (route === "/account/users") {
      if (!isAdmin) {
        return notification.error({
          message:
            "You’re not authorized to access this link. If you need that, please contact your system administrator.",
          duration: NOTIFY_DURATION,
        });
      }
    }
    navigate(route);
  };

  return (
    <Card>
      <div className="heroContent">
        <Image
          src={logoIcon}
          style={{ maxHeight: 100, width: "auto" }}
          preview={false}
        />
        <div className="home-hero-text">
          <span id="title">Welcome to the Gold Trade Chain Portal</span>
          <span id="description">
            Use this portal to Track and Trace the movement of gold <br />
            from the mine to the Refiner as it transforms from raw material to a
            Dore bar.
          </span>
        </div>
        <div className="landing-cards">
          <Row gutter={30}>
            {cards.map((card) => {
              return (
                <Col
                  span={8}
                  key={card.title}
                  onClick={() => {
                    clickcard(card.route);
                  }}
                >
                  <div className="dashboard-card">
                    <div id="icon-circle">
                      <i className={card.icon} />
                    </div>
                    <span id="title"> {card.title}</span>
                    <div id="desctiption-container">
                      <span id="description">{card.description}</span>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </Card>
  );
}

export default (): JSX.Element => (
  <App>
    <Dashboard />
  </App>
);
