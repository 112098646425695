/* eslint-disable no-undef */
import { useEffect, useRef } from "react";
/* eslint-disable */
function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitleRef = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    const defaultTitle = defaultTitleRef.current;
    return () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle;
      }
    };
  }, [prevailOnUnmount]);
}

export default useDocumentTitle;
